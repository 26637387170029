import { blueGrey, grey } from "@mui/material/colors";
import { LegendLayout, TooltipLayout, WeeklySavingsRunUp, WeeklySavingsRunUpRow } from "api-shared";
import { sortBy } from "lodash";
import { useTranslation } from "react-i18next";
import { Bar, CartesianGrid, ComposedChart, Legend, Line, ReferenceLine, Tooltip, XAxis, YAxis } from "recharts";
import ResponsiveContainer from "../../../components/ResponsiveContainer";
import useCurrency from "../../../hooks/useCurrency";
import { translationKeys } from "../../../translations/main-translations.ts";
import ShortNoCodeCurrencyLabelList from "../charts/ShortNoCodeCurrencyLabelList";
import TargetChip from "../charts/TargetChip";
import { useCurrencyYAxisProps, useLegendProps, useTooltipProps, useWeekXAxisProps } from "../charts/hooks";
import { getBarSegments } from "../charts/utils";
import { ExtendableCircleLegend } from "../reporting/ExtendableCircleLegend";
import { BarRectangleItemWithPayloads } from "../types";
import { CalculatedStackKeys } from "../utils";

interface WeeklySavingsRunUpChartProps {
    data: WeeklySavingsRunUp;
    xAxis: string;
    target: number | null;
    showSums?: boolean;
    onOpenDrilldown: (xValue: string, stackingValue: unknown) => void;
}

const addDifferenceToTarget = (week: WeeklySavingsRunUpRow, target: number) => {
    const { materializedCarryOver, materializedSavings, plannedCarryOver, overdueSavings, plannedSavings } = week;
    const sumValue =
        (materializedCarryOver ?? 0) + (materializedSavings ?? 0) + (plannedCarryOver ?? 0) + (overdueSavings ?? 0) + (plannedSavings ?? 0);
    return { ...week, [CalculatedStackKeys.DIFFERENCE_TO_TARGET]: Math.max(0, target - sumValue) };
};

const WeeklySavingsRunUpChart = ({ data, xAxis, target, showSums, onOpenDrilldown }: WeeklySavingsRunUpChartProps) => {
    const { t } = useTranslation();
    const { formatCurrency, formatCurrencyShort } = useCurrency();
    const currencyYAxisProps = useCurrencyYAxisProps(target ?? undefined);
    const weekXAxisProps = useWeekXAxisProps();

    // Widget specific colors
    const colors = [
        "rgb(220,220,220)", // plannedCarryOver
        "rgb(139,184,107)", // materializedCarryOver
        "rgb(0,180,70)", // materializedSavings
        "rgb(175,175,175)", // plannedSavings
        "rgb(246,142,132)", // overdueSavings
    ];
    const legendProps = useLegendProps();
    const tooltipProps = useTooltipProps({
        showDifferenceToTarget: target !== null,
        target: target ?? undefined,
        tooltipLayout: TooltipLayout.Reversed,
    });

    const dataWithDifferenceToTarget =
        target !== null
            ? data.map((week) => {
                  if (week.week < data.length) {
                      return week;
                  }
                  return addDifferenceToTarget(week, target);
              })
            : data;

    const sortedData = sortBy(dataWithDifferenceToTarget, xAxis);
    const barSegments = getBarSegments(
        [
            { value: "plannedCarryOver", label: t(translationKeys.VDLANG_DASHBOARDS_WEEKLY_SAVINGS_RUN_UP_PLANNED_CARRY_OVER) },
            { value: "materializedCarryOver", label: t(translationKeys.VDLANG_DASHBOARDS_WEEKLY_SAVINGS_RUN_UP_MATERIALIZED_CARRY_OVER) },
            { value: "materializedSavings", label: t(translationKeys.VDLANG_DASHBOARDS_WEEKLY_SAVINGS_RUN_UP_MATERIALIZED_SAVINGS) },
            { value: "plannedSavings", label: t(translationKeys.VDLANG_DASHBOARDS_WEEKLY_SAVINGS_RUN_UP_PLANNED_SAVINGS) },
            { value: "overdueSavings", label: t(translationKeys.VDLANG_DASHBOARDS_WEEKLY_SAVINGS_RUN_UP_OVERDUE_SAVINGS) },
        ],
        colors,
    );
    const barSegmentKeys = barSegments.map((segment) => segment.key);

    function labelFormatter(label: string) {
        if (label == null) {
            return "";
        }
        return t(translationKeys.VDLANG_CALENDAR_WEEK, { week: label });
    }

    const handleBarClick = (payload: any, tooltipPayload: any) => {
        const stackingValue = tooltipPayload?.[0]?.dataKey;
        const xValue = payload[xAxis];
        return onOpenDrilldown(xValue, stackingValue);
    };

    return (
        <ResponsiveContainer>
            <ComposedChart data={sortedData} stackOffset="sign">
                <CartesianGrid strokeDasharray="4" vertical={false} />
                <Legend
                    {...legendProps}
                    content={
                        target !== null ? (
                            <ExtendableCircleLegend legendLayout={LegendLayout.Reversed}>
                                <TargetChip
                                    size="small"
                                    label={t(translationKeys.VDLANG_DASHBOARDS_COMPLETED_EFFECTS_LEGEND_TARGET_SUMMARY, {
                                        value: formatCurrencyShort(target),
                                    })}
                                />
                            </ExtendableCircleLegend>
                        ) : (
                            <ExtendableCircleLegend legendLayout={LegendLayout.Reversed} />
                        )
                    }
                />
                <Tooltip
                    {...tooltipProps}
                    labelFormatter={labelFormatter}
                    formatter={(value: number | string) => formatCurrency(value) ?? value}
                />
                {barSegments.map(({ key, color, label }) => (
                    <Bar
                        key={key}
                        name={label}
                        dataKey={key}
                        stackId="week"
                        fill={color}
                        stroke="white"
                        strokeWidth={1}
                        onClick={({ payload, tooltipPayload }: BarRectangleItemWithPayloads) => handleBarClick(payload, tooltipPayload)}
                        legendType="circle"
                        cursor="pointer"
                    >
                        {showSums ? <ShortNoCodeCurrencyLabelList stackDataKeys={barSegmentKeys} hideEverySecondLabel /> : null}
                    </Bar>
                ))}
                {target !== null ? (
                    <Bar
                        name={t(translationKeys.VDLANG_DASHBOARDS_DIFFERENCE_TO_TARGET_LABEL)}
                        dataKey={CalculatedStackKeys.DIFFERENCE_TO_TARGET}
                        stackId="week"
                        fill="none"
                        stroke={blueGrey[800]}
                        strokeDasharray="4 2"
                        strokeWidth={1}
                        legendType="circle"
                    />
                ) : null}
                {target !== null && (
                    <>
                        <ReferenceLine y={target} stroke={blueGrey[800]} strokeDasharray="4" strokeWidth={2} />
                        {/* Dummy line to add a legend item for the target line */}
                        <Line name={t(translationKeys.VDLANG_DASHBOARDS_COMPLETED_EFFECTS_LEGEND_TARGET)} stroke={blueGrey[800]} />
                    </>
                )}
                <ReferenceLine y={0} stroke={grey[600]} />
                <XAxis {...weekXAxisProps} dataKey={xAxis} />
                <YAxis {...currencyYAxisProps} />
            </ComposedChart>
        </ResponsiveContainer>
    );
};

export default WeeklySavingsRunUpChart;
