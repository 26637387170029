import { Collapse, FormControlLabel, Grid, Stack, Switch, TextField, Typography, styled } from "@mui/material";
import { AggregationMethod, AttributeTable, FilterDefinition, PivotMetric, validateProcessWhiteSpotMatrixWidgetConfig } from "api-shared";
import { TFunction } from "i18next";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Fieldset from "../../../components/Fieldset";
import Form from "../../../components/Form";
import Tooltip from "../../../components/Tooltip";
import InfoIcon from "../../../components/icons/InfoIcon";
import IntegerInput from "../../../components/input/IntegerInput";
import { SimpleCurrencyInput } from "../../../components/input/SimpleCurrencyInput";
import Select from "../../../components/input/select/Select";
import FieldTreeInput from "../../../components/input/tree/FieldTreeInput";
import LoadingAnimation from "../../../components/loading/LoadingAnimation";
import { useClientFiscalYear, useCurrentClient } from "../../../domain/client";
import { useMeasureAttributes } from "../../../domain/endpoint";
import { useMeasureFieldDefinitionsQuery } from "../../../domain/filters";
import { useMeasureConfigs } from "../../../domain/measure-config";
import { usePivotFields } from "../../../domain/reporting";
import { useNonDeletedUsers } from "../../../domain/users";
import { Field, findField } from "../../../lib/fields";
import { translationKeys } from "../../../translations/main-translations";
import TreeProvider from "../../TreeProvider";
import ScopeSelect from "../../measures/preferences/ScopeSelect";
import FilterForm from "../../measures/preferences/filter-configuration/FilterForm";
import { IWidgetConfigFormProps } from "../WidgetConfigDialog";
import WidgetConfigTab from "../WidgetConfigTab";
import WidgetConfigTabs from "../WidgetConfigTabs";
import WidgetDescriptionField from "../WidgetDescriptionField";
import ProcessWhiteSpotMatrixWidgetConfigSortSelect from "./ProcessWhiteSpotMatrixWidgetConfigSortSelect";

const getAggregationOptions = (translate: TFunction) => [
    {
        label: translate(translationKeys.VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_CONFIG_AGGREGATION_SUM_OF_POTENTIAL),
        value: {
            aggregation: AggregationMethod.Sum,
            metric: PivotMetric.Effect,
        },
    },
    {
        label: translate(translationKeys.VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_CONFIG_AGGREGATION_COUNT_OF_PROCESSES),
        value: {
            aggregation: AggregationMethod.Count,
            metric: PivotMetric.MeasureId,
        },
    },
];

const WidgetConfigTabContainer = styled("div")(({ theme }) => ({
    padding: theme.spacing(3),
    minHeight: theme.spacing(25), // fixed height of the largest tab
}));

const TooltipInfoIcon = styled(InfoIcon)(({ theme }) => ({
    color: theme.palette.action.active,
}));

const ProcessWhiteSpotMatrixWidgetConfigForm = ({
    config,
    disabled,
    name,
    description,
    onConfigChange,
    onDescriptionChange,
    onNameChange,
    onSubmit,
}: IWidgetConfigFormProps) => {
    const [openTab, setOpenTab] = useState(0);
    const { t: translate } = useTranslation();
    const pivotFieldsQuery = usePivotFields();

    const fieldDefinitionsQuery = useMeasureFieldDefinitionsQuery();
    const measureAttributes = useMeasureAttributes();
    const users = useNonDeletedUsers();
    const client = useCurrentClient();
    const fiscalYear = useClientFiscalYear();
    const measureConfigs = useMeasureConfigs();
    const [isValidFilter, setIsValidFilter] = useState(true);

    if (!pivotFieldsQuery.isSuccess) {
        return <LoadingAnimation />;
    }

    if (!validateProcessWhiteSpotMatrixWidgetConfig(config)) {
        return <LoadingAnimation />;
    }
    const aggregationOptions = getAggregationOptions(translate);
    const selectedAggregationOption = aggregationOptions.find(({ value }) => value.aggregation === config.aggregation && value.metric);

    const fieldOptions = Object.values(pivotFieldsQuery.data ?? {}).map(({ name }) => ({
        label: translate(name),
        value: name,
    }));
    const selectedFirstPivotField = fieldOptions.find(({ value }) => value === config.rowPivotField);
    const selectedSecondPivotField = fieldOptions.find(({ value }) => value === config.columnPivotField);
    const filteredOptions = fieldOptions.filter(({ value }) => value !== config.rowPivotField && value !== config.columnPivotField);

    const rowPivotField = findField(measureAttributes, fieldDefinitionsQuery.data ?? {}, config.rowPivotField as string);
    const columnPivotField = findField(measureAttributes, fieldDefinitionsQuery.data ?? {}, config.columnPivotField as string);

    const getTreeNodeSelectProps = (fieldName: string, field: Field) => {
        const currentValue = config.defaultStartTreeNodeIds?.[fieldName];
        return {
            field,
            updateValue: (value: number[] | number | null) => {
                onConfigChange(
                    {
                        ...config,
                        defaultStartTreeNodeIds: {
                            ...config.defaultStartTreeNodeIds,
                            [fieldName]: Array.isArray(value) ? value[0] : value,
                        },
                    },
                    isValidFilter,
                );
            },
            value: currentValue != null ? [currentValue] : [],
            isMulti: false,
            isClearable: true,
            translate,
            disabled,
            menuPortalTarget: document.body,
            narrowSelection: false,
        };
    };

    return (
        <Form onSubmit={onSubmit}>
            <WidgetConfigTabs value={openTab} onChange={(event, newValue) => setOpenTab(newValue)}>
                <WidgetConfigTab label={translate(translationKeys.VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_CONFIG_TAB_GENERAL)} />
                <WidgetConfigTab label={translate(translationKeys.VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_CONFIG_TAB_DESCRIPTION)} />
                <WidgetConfigTab label={translate(translationKeys.VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_CONFIG_TAB_SCOPE)} />
                <WidgetConfigTab label={translate(translationKeys.VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_CONFIG_TAB_FILTER)} />
            </WidgetConfigTabs>
            {openTab === 0 ? (
                <WidgetConfigTabContainer>
                    <Grid container rowSpacing={2} columnSpacing={1}>
                        <Grid item xs={12}>
                            <TextField
                                value={name}
                                onChange={(event) => onNameChange(event.target.value)}
                                label={translate(translationKeys.VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_CONFIG_NAME)}
                                margin="none"
                                disabled={disabled}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Select
                                value={selectedAggregationOption}
                                options={aggregationOptions}
                                onChange={(option) => option != null && onConfigChange({ ...config, ...option.value }, isValidFilter)}
                                label={translate(translationKeys.VDLANG_DASHBOARDS_PROCESS_WHITESPOT_CHART_CONFIG_AGGREGATION)}
                                menuPortalTarget={document.body}
                                margin="none"
                                fullWidth
                                isDisabled={disabled}
                                isMulti={false} // help the compiler picking the correct generics
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Select
                                value={selectedSecondPivotField}
                                options={filteredOptions}
                                onChange={(option) => onConfigChange({ ...config, columnPivotField: option?.value ?? null }, isValidFilter)}
                                label={translate(translationKeys.VDLANG_DASHBOARDS_PROCESS_WHITESPOT_CHART_WIDGET_X_AXIS)}
                                menuPortalTarget={document.body}
                                margin="none"
                                fullWidth
                                isMulti={false} // help the compiler picking the correct generics
                                isDisabled={disabled}
                                isClearable
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <ProcessWhiteSpotMatrixWidgetConfigSortSelect
                                config={config}
                                direction="column"
                                disabled={disabled}
                                onConfigChange={(newConfig) => onConfigChange(newConfig, isValidFilter)}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Select
                                value={selectedFirstPivotField}
                                options={filteredOptions}
                                onChange={(option) =>
                                    option != null && onConfigChange({ ...config, rowPivotField: option.value }, isValidFilter)
                                }
                                label={translate(translationKeys.VDLANG_DASHBOARDS_PROCESS_WHITESPOT_CHART_WIDGET_Y_AXIS)}
                                menuPortalTarget={document.body}
                                margin="none"
                                fullWidth
                                isMulti={false} // help the compiler picking the correct generics
                                isDisabled={disabled}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <ProcessWhiteSpotMatrixWidgetConfigSortSelect
                                config={config}
                                direction="row"
                                disabled={disabled}
                                onConfigChange={(newConfig) => onConfigChange(newConfig, isValidFilter)}
                            />
                        </Grid>
                        {config.columnPivotField !== null && columnPivotField?.tableName === AttributeTable.TreeNodes ? (
                            <Grid item xs={12}>
                                <TreeProvider
                                    componentProps={{
                                        label: (
                                            <Tooltip
                                                title={translate(
                                                    translationKeys.VDLANG_DASHBOARDS_PROCESS_WHITESPOT_CHART_CONFIG_DEFAULT_TREE_FIELD_LEVEL_HINT,
                                                )}
                                            >
                                                <span>
                                                    {translate(
                                                        translationKeys.VDLANG_DASHBOARDS_PROCESS_WHITESPOT_CHART_CONFIG_DEFAULT_X_AXIS_TREE_FIELD_LEVEL,
                                                    )}{" "}
                                                    <TooltipInfoIcon />
                                                </span>
                                            </Tooltip>
                                        ),
                                        ...getTreeNodeSelectProps(config.columnPivotField, columnPivotField),
                                    }}
                                    field={columnPivotField}
                                    component={FieldTreeInput}
                                />
                            </Grid>
                        ) : null}
                        {config.rowPivotField !== null && rowPivotField?.tableName === AttributeTable.TreeNodes ? (
                            <Grid item xs={12}>
                                <TreeProvider
                                    componentProps={{
                                        label: (
                                            <Tooltip
                                                title={translate(
                                                    translationKeys.VDLANG_DASHBOARDS_PROCESS_WHITESPOT_CHART_CONFIG_DEFAULT_TREE_FIELD_LEVEL_HINT,
                                                )}
                                            >
                                                <span>
                                                    {translate(
                                                        translationKeys.VDLANG_DASHBOARDS_PROCESS_WHITESPOT_CHART_CONFIG_DEFAULT_Y_AXIS_TREE_FIELD_LEVEL,
                                                    )}{" "}
                                                    <TooltipInfoIcon />
                                                </span>
                                            </Tooltip>
                                        ),
                                        ...getTreeNodeSelectProps(config.rowPivotField, rowPivotField),
                                    }}
                                    field={rowPivotField}
                                    component={FieldTreeInput}
                                />
                            </Grid>
                        ) : null}
                        <Grid item xs={12}>
                            <Fieldset>
                                <FormControlLabel
                                    disabled={disabled}
                                    onChange={(_, checked) => onConfigChange({ ...config, useManualMaxValues: checked })}
                                    checked={config.useManualMaxValues}
                                    control={<Switch />}
                                    disableTypography
                                    label={
                                        <Typography variant="body2" fontWeight="medium">
                                            {translate(
                                                translationKeys.VDLANG_DASHBOARDS_PROCESS_WHITESPOT_CHART_CONFIG_USE_MAX_VALUES_HEADING,
                                            )}
                                            <InfoIcon
                                                title={translate(
                                                    translationKeys.VDLANG_DASHBOARDS_PROCESS_WHITESPOT_CHART_CONFIG_USE_MAX_VALUES_HINT,
                                                )}
                                                sx={{ color: "text.secondary", ml: 0.5 }}
                                            />
                                        </Typography>
                                    }
                                />
                                <Collapse in={config.useManualMaxValues}>
                                    <Stack direction="row" spacing={1}>
                                        <SimpleCurrencyInput
                                            value={config.maxDisplayPotential}
                                            onChange={(maxDisplayPotential) =>
                                                onConfigChange({ ...config, maxDisplayPotential: maxDisplayPotential ?? 0 }, isValidFilter)
                                            }
                                            label={translate(
                                                translationKeys.VDLANG_DASHBOARDS_PROCESS_WHITESPOT_CHART_CONFIG_MAX_VALUE_POTENTIAL,
                                            )}
                                            margin="none"
                                            disabled={disabled}
                                            fullWidth
                                        />
                                        <IntegerInput
                                            value={config.maxDisplayCount}
                                            onChange={(maxDisplayCount) => onConfigChange({ ...config, maxDisplayCount }, isValidFilter)}
                                            label={translate(
                                                translationKeys.VDLANG_DASHBOARDS_PROCESS_WHITESPOT_CHART_CONFIG_MAX_VALUE_COUNT,
                                            )}
                                            margin="none"
                                            disabled={disabled}
                                            fullWidth
                                        />
                                    </Stack>
                                </Collapse>
                            </Fieldset>
                        </Grid>
                    </Grid>
                </WidgetConfigTabContainer>
            ) : null}
            {openTab === 1 ? (
                <WidgetConfigTabContainer>
                    <WidgetDescriptionField
                        description={description}
                        onDescriptionChange={onDescriptionChange}
                        label={translate(translationKeys.VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_CONFIG_TAB_DESCRIPTION)}
                        disabled={disabled}
                    />
                </WidgetConfigTabContainer>
            ) : null}
            {openTab === 2 ? (
                <WidgetConfigTabContainer>
                    <Grid container>
                        <Grid item xs={6}>
                            <ScopeSelect
                                value={config.scope}
                                onChange={(scope) => onConfigChange({ ...config, scope }, isValidFilter)}
                                disabled={disabled}
                                measureConfigs={measureConfigs}
                                measureConfigIds={measureConfigs.map((measureConfig) => measureConfig.id)}
                                financialMonth={fiscalYear}
                                yearsBefore={client.fiscalYearRangePast}
                                yearsAfter={client.fiscalYearRangeFuture}
                                menuPortalTarget={document.body}
                                translate={translate}
                            />
                        </Grid>
                    </Grid>
                </WidgetConfigTabContainer>
            ) : null}
            {openTab === 3 && fieldDefinitionsQuery.isSuccess ? (
                <WidgetConfigTabContainer>
                    <FilterForm
                        filterDefinition={config.filter}
                        onChange={(newFilter: FilterDefinition, isValid: boolean) => {
                            onConfigChange({ ...config, filter: newFilter }, isValid);
                            setIsValidFilter(isValid);
                        }}
                        fieldDefinitions={fieldDefinitionsQuery.data}
                        measureAttributes={measureAttributes}
                        users={users}
                        translate={translate}
                        disabled={disabled}
                    />
                </WidgetConfigTabContainer>
            ) : null}
        </Form>
    );
};

export default ProcessWhiteSpotMatrixWidgetConfigForm;
