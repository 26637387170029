import { styled, Button, ButtonProps } from "@mui/material";
import CreateIcon from "@mui/icons-material/AddRounded";
import { translationKeys } from "../../../../translations/main-translations";
import { useTranslation } from "react-i18next";

const BlockButton = styled(Button)(({ theme }) => ({
    backgroundColor: theme.palette.background.default,
    borderRadius: theme.spacing(1),
    border: `1px dashed ${theme.palette.border.light}`,
    color: theme.palette.text.secondary,
    display: "flex",
    justifyContent: "flex-start",
    padding: theme.spacing(2),
    width: "100%",
    "&:hover": {
        border: `1px dashed ${theme.palette.primary.main}`,
        color: theme.palette.text.primary,
    },
}));

const WaterfallCreateBlockButton = (props: ButtonProps) => {
    const { t } = useTranslation();

    return (
        <BlockButton {...props}>
            <CreateIcon sx={{ marginRight: 1 }} width={20} height={20} />
            {t(translationKeys.VDLANG_DASHBOARDS_WATERFALL_CONFIG_CREATE_BLOCK)}
        </BlockButton>
    );
};

export default WaterfallCreateBlockButton;
