import { styled, TextField } from "@mui/material";
import { IdeaFilterDefintion, IdeaXAxis, validateIdeaMatrixWidgetConfig } from "api-shared";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Form from "../../components/Form";
import Select from "../../components/input/select/Select";
import { translationKeys } from "../../translations/main-translations";
import IdeaFilterForm from "../ideas/preferences/filter-configuration/IdeaFilterForm";
import { IWidgetConfigFormProps } from "./WidgetConfigDialog";
import WidgetConfigTab from "./WidgetConfigTab";
import WidgetConfigTabs from "./WidgetConfigTabs";

const WidgetConfigTabContainer = styled("div")(({ theme }) => ({
    padding: theme.spacing(3),
    minHeight: theme.spacing(66),
}));

const IdeaMatrixWidgetConfigForm = ({ config, name, onNameChange, onConfigChange, onSubmit, disabled }: IWidgetConfigFormProps) => {
    const [openTab, setOpenTab] = useState(0);
    const { t } = useTranslation();

    if (!validateIdeaMatrixWidgetConfig(config)) {
        return null;
    }

    const axisOptions = Object.values(IdeaXAxis).map((axis) => ({
        value: axis,
        label: t(
            axis === IdeaXAxis.TIME
                ? translationKeys.VDLANG_IDEAS_MATRIX_TIME_ESTIMATE
                : translationKeys.VDLANG_IDEAS_MATRIX_EFFORT_ESTIMATE,
        ),
    }));

    const handleFilterChange = (filter: IdeaFilterDefintion) => {
        onConfigChange({ ...config, filter });
    };

    const handleAxisChange = (option: IdeaXAxis | undefined) => {
        if (option == null) {
            return;
        }

        onConfigChange({
            ...config,
            xAxis: option,
        });
    };

    return (
        <Form onSubmit={onSubmit}>
            <WidgetConfigTabs value={openTab} onChange={(event, newValue) => setOpenTab(newValue)}>
                <WidgetConfigTab label={t(translationKeys.VDLANG_DASHBOARDS_IDEA_MATRIX_WIDGET_CONFIG_TAB_GENERAL)} />
                <WidgetConfigTab label={t(translationKeys.VDLANG_DASHBOARDS_IDEA_MATRIX_WIDGET_CONFIG_TAB_FILTER)} />
            </WidgetConfigTabs>
            {openTab === 0 ? (
                <WidgetConfigTabContainer>
                    <TextField fullWidth value={name} onChange={(e) => onNameChange(e.target.value)} label="Name" disabled={disabled} />
                    <Select
                        options={axisOptions}
                        value={axisOptions.find((o) => o.value === config.xAxis)}
                        label={t(translationKeys.VDLANG_IDEAS_MATRIX_X_AXIS)}
                        onChange={(option) => handleAxisChange(option?.value)}
                        menuPortalTarget={document.body}
                        isDisabled={disabled}
                    />
                </WidgetConfigTabContainer>
            ) : null}

            {openTab === 1 ? (
                <WidgetConfigTabContainer>
                    <IdeaFilterForm filter={config.filter} onChange={handleFilterChange} disabled={disabled} />
                </WidgetConfigTabContainer>
            ) : null}
        </Form>
    );
};

export default IdeaMatrixWidgetConfigForm;
