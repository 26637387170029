import { Typography } from "@mui/material";
import React from "react";
import { Text, TextProps } from "recharts";

type AxisTickProps = Pick<TextProps, "x" | "y" | "textAnchor" | "fontWeight" | "transform" | "dy"> &
    (
        | {
              tickFormatter: (value: any, index: number) => string;
              payload: any;
          }
        | React.PropsWithChildren<unknown>
    );

/**
 * Render an axis tick with correct typography and color.
 *
 * Accepts either
 * - tickFormatter + payload as props, so the component can be provided directly to rechart's *Axis components
 * - children for any custom text by parent component
 *
 * @export
 * @param {AxisTickProps} { x, y, textAnchor, fontWeight, transform, dy = 4, ...otherProps }
 * @returns
 */
export function AxisTick({ x, y, textAnchor, fontWeight, transform, dy = 4, ...otherProps }: AxisTickProps) {
    const children =
        "tickFormatter" in otherProps ? otherProps.tickFormatter(otherProps.payload.value, otherProps.payload.index) : otherProps.children;
    return (
        <Typography
            component={Text}
            color="textPrimary"
            fill="currentColor" // Inherit fill color from CSS "color"
            stroke="none"
            // pass through selected props computed by recharts
            x={x}
            y={y}
            dy={dy}
            textAnchor={textAnchor}
            fontWeight={fontWeight}
            transform={transform}
        >
            {children}
        </Typography>
    );
}
