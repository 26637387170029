import { Grid, styled, Switch, TextField, Typography } from "@mui/material";
import {
    FieldTypes,
    FilterDefinition,
    ICompareExpression,
    MeasureFieldNames,
    Operators,
    validateCompletedEffectsWidgetConfig,
} from "api-shared";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import Form from "../../../components/Form";
import SelectPlaceholder from "../../../components/input/select/components/SelectPlaceholder";
import { FiscalYearSelect } from "../../../components/input/select/FiscalYearSelect";
import Select from "../../../components/input/select/Select";
import { SimpleCurrencyInput } from "../../../components/input/SimpleCurrencyInput";
import LoadingAnimation from "../../../components/loading/LoadingAnimation";

import { useClientFiscalYear, useCurrentClient } from "../../../domain/client";
import { useMeasureAttributes } from "../../../domain/endpoint";
import { useCompletedEffectsFieldDefinitions } from "../../../domain/reporting";
import { useNonDeletedUsers } from "../../../domain/users";
import { translationKeys } from "../../../translations/main-translations";
import FilterForm from "../../measures/preferences/filter-configuration/FilterForm";
import { IWidgetConfigFormProps } from "../WidgetConfigDialog";
import WidgetConfigTab from "../WidgetConfigTab";
import WidgetConfigTabs from "../WidgetConfigTabs";
import WidgetDescriptionField from "../WidgetDescriptionField";
import ForecastForm from "./ForecastForm";

const WidgetConfigTabContainer = styled("div")(({ theme }) => ({
    padding: theme.spacing(3),
    minHeight: theme.spacing(25), // fixed height of the largest tab
}));

const CompletedEffectsWidgetConfigForm = ({
    config,
    name,
    description,
    onNameChange,
    onDescriptionChange,
    onConfigChange,
    onSubmit,
    disabled,
}: IWidgetConfigFormProps) => {
    const fiscalYearStartMonth = useClientFiscalYear();
    const { fiscalYearRangePast, fiscalYearRangeFuture } = useCurrentClient();
    const [openTab, setOpenTab] = useState(0);
    const { t: translate } = useTranslation();

    const measureAttributes = useMeasureAttributes();
    const fieldDefinitionsQuery = useCompletedEffectsFieldDefinitions();
    const users = useNonDeletedUsers();
    const [isValidFilter, setIsValidFilter] = useState(true);

    const newConditionBuilder = useCallback(() => {
        if (!fieldDefinitionsQuery.isSuccess) {
            // This cannot happen, as fields will be loaded when widget mounted
            // adding conditions can only happen after opening the config dialog
            throw new Error("Trying to add a filter condition but not field definitions are loaded");
        }
        return {
            field: Object.values(fieldDefinitionsQuery.data)[0].name,
            operator: Operators.In,
            values: [],
        } as ICompareExpression;
    }, [fieldDefinitionsQuery.isSuccess, fieldDefinitionsQuery.data]);

    if (!validateCompletedEffectsWidgetConfig(config)) {
        return null;
    }

    if (!fieldDefinitionsQuery.isSuccess) {
        return <LoadingAnimation />;
    }

    const groupOptions = Object.values(fieldDefinitionsQuery.data)
        .filter(({ type }) => type === FieldTypes.Single || type === FieldTypes.User)
        .map(({ name }) => ({
            label: translate(name),
            value: name,
        }));
    const selectedGroup = groupOptions.find(({ value }) => value === config.group);

    return (
        <Form onSubmit={onSubmit}>
            <WidgetConfigTabs value={openTab} onChange={(event, newValue) => setOpenTab(newValue)}>
                <WidgetConfigTab label={translate(translationKeys.VDLANG_DASHBOARDS_PROCESS_LIST_WIDGET_GENERAL_SETTINGS)} />
                <WidgetConfigTab label={translate(translationKeys.VDLANG_DASHBOARDS_PROCESS_LIST_WIDGET_CONFIG_TAB_DESCRIPTION)} />
                <WidgetConfigTab label={translate(translationKeys.VDLANG_DASHBOARDS_PROCESS_LIST_WIDGET_FILTER_CONFIGURATION)} />
                <WidgetConfigTab label={translate(translationKeys.VDLANG_DASHBOARDS_COMPLETED_EFFECTS_FORECAST_CONFIGURATION)} />
            </WidgetConfigTabs>
            {openTab === 0 ? (
                <WidgetConfigTabContainer>
                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            <TextField
                                value={name}
                                onChange={(event) => onNameChange(event.target.value)}
                                disabled={disabled}
                                label={translate(translationKeys.VDLANG_DASHBOARDS_COMPLETED_EFFECTS_CONFIG_NAME)}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Select
                                value={selectedGroup}
                                options={groupOptions}
                                onChange={(option) => onConfigChange({ ...config, group: option?.value ?? undefined }, isValidFilter)}
                                label={translate(translationKeys.VDLANG_DASHBOARDS_COMPLETED_EFFECTS_CONFIG_GROUP)}
                                placeholder={translate(translationKeys.VDLANG_DASHBOARDS_COMPLETED_EFFECTS_CONFIG_NO_GROUP_SELECTED)}
                                menuPortalTarget={document.body}
                                margin="none"
                                fullWidth
                                isClearable
                                isDisabled={disabled}
                                isMulti={false} // help the compiler picking the correct generics
                                components={{ Placeholder: SelectPlaceholder }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <SimpleCurrencyInput
                                value={config.axisMinValue}
                                onChange={(axisMinValue) => onConfigChange({ ...config, axisMinValue })}
                                label={translate(translationKeys.VDLANG_DASHBOARDS_COMPLETED_EFFECTS_CONFIG_AXIS_MIN_VALUE)}
                                margin="none"
                                disabled={disabled}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <SimpleCurrencyInput
                                value={config.axisMaxValue}
                                onChange={(axisMaxValue) => onConfigChange({ ...config, axisMaxValue })}
                                label={translate(translationKeys.VDLANG_DASHBOARDS_COMPLETED_EFFECTS_CONFIG_AXIS_MAX_VALUE)}
                                margin="none"
                                disabled={disabled}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FiscalYearSelect
                                value={config.fiscalYear}
                                onChange={(fiscalYear) => {
                                    if (fiscalYear !== null) {
                                        onConfigChange({ ...config, fiscalYear }, isValidFilter);
                                    }
                                }}
                                fiscalYearStartMonth={fiscalYearStartMonth}
                                yearsBefore={fiscalYearRangePast}
                                yearsAfter={fiscalYearRangeFuture}
                                label={translate(translationKeys.VDLANG_DASHBOARDS_COMPLETED_EFFECTS_CONFIG_FISCAL_YEAR)}
                                menuPortalTarget={document.body}
                                margin="none"
                                fullWidth
                                isDisabled={disabled}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <SimpleCurrencyInput
                                value={config.target ?? null}
                                onChange={(target) => onConfigChange({ ...config, target: target ?? undefined }, isValidFilter)}
                                label={translate(translationKeys.VDLANG_DASHBOARDS_COMPLETED_EFFECTS_CONFIG_TARGET)}
                                disabled={disabled}
                                fullWidth
                            />
                        </Grid>
                        <Grid direction="row" flexWrap="nowrap" display="flex" alignItems="center" item xs={12}>
                            <Switch
                                size="small"
                                edge="start"
                                checked={config.showSums}
                                onChange={(e, checked) => onConfigChange({ ...config, showSums: checked }, isValidFilter)}
                                disabled={disabled}
                            />
                            <Typography>{translate(translationKeys.VDLANG_DASHBOARDS_COMPLETED_EFFECTS_CONFIG_SHOW_SUMS)}</Typography>
                        </Grid>
                    </Grid>
                </WidgetConfigTabContainer>
            ) : null}
            {openTab === 1 ? (
                <WidgetConfigTabContainer>
                    <WidgetDescriptionField
                        description={description}
                        onDescriptionChange={onDescriptionChange}
                        label={translate(translationKeys.VDLANG_DASHBOARDS_PROCESS_LIST_WIDGET_CONFIG_TAB_DESCRIPTION)}
                        disabled={disabled}
                    />
                </WidgetConfigTabContainer>
            ) : null}
            {openTab === 2 ? (
                <WidgetConfigTabContainer>
                    <FilterForm
                        filterDefinition={config.filter}
                        onChange={(newFilter: FilterDefinition, isValid: boolean) => {
                            onConfigChange({ ...config, filter: newFilter }, isValid);
                            setIsValidFilter(isValid);
                        }}
                        fieldDefinitions={fieldDefinitionsQuery.data}
                        measureAttributes={measureAttributes}
                        users={users}
                        translate={translate}
                        buildNewCondition={newConditionBuilder}
                        disabled={disabled}
                        ignoreConditionOperators={{ [MeasureFieldNames.MeasureConfigId]: [Operators.NotSet] }}
                    />
                </WidgetConfigTabContainer>
            ) : null}
            {openTab === 3 ? (
                <WidgetConfigTabContainer>
                    <ForecastForm
                        value={config.forecast}
                        onChange={(newForecast) => onConfigChange({ ...config, forecast: newForecast }, isValidFilter)}
                        disabled={disabled}
                    />
                </WidgetConfigTabContainer>
            ) : null}
        </Form>
    );
};

export default CompletedEffectsWidgetConfigForm;
