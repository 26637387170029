import { Skeleton, Stack } from "@mui/material";
import { times } from "lodash";

const BarWidths = ["80%", "100%", "40%", "55%", "35%", "20%"];

const HorizontalBarChartSkeleton = () => {
    return (
        <>
            <Stack direction="row" gap={2}>
                {times(3, (i) => (
                    <Skeleton key={`skeleton_legend_${i}`} width="20%" />
                ))}
            </Stack>
            <Stack height="90%" direction="row" flexWrap="nowrap" marginTop={2}>
                <Stack display="flex" height="100%" justifyContent="flex-end" alignItems="flex-end">
                    <Skeleton key="skeleton_axis" height="100%" width={2} variant="rectangular" />
                </Stack>

                <Stack width="100%" direction="column" display="flex" justifyContent="space-between" margin={1}>
                    {BarWidths.map((width, i) => (
                        <Skeleton key={`skeleton_${i}`} height="10%" width={width} variant="rectangular" />
                    ))}
                </Stack>
            </Stack>
        </>
    );
};
export default HorizontalBarChartSkeleton;
