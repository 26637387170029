import { styled } from "@mui/material";
import { SimpleCurrencyInput } from "./SimpleCurrencyInput";

export const SimpleCurrencyCellInput = styled(SimpleCurrencyInput)(() => ({
    width: "100%",
    "& fieldset": {
        border: "none",
        borderRadius: 0,
    },
}));
