import { Grid, TextField, styled } from "@mui/material";
import { FilterDefinition, FunnelChartWidgetConfig, MeasureFieldNames, Operators, validateFunnelChartWidgetConfig } from "api-shared";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Form from "../../../components/Form";
import Select from "../../../components/input/select/Select";
import { useClientFiscalYear, useCurrentClient } from "../../../domain/client";
import { useMeasureAttributes } from "../../../domain/endpoint";
import { useMeasureFieldDefinitionsQuery } from "../../../domain/filters";
import { useMeasureConfigs } from "../../../domain/measure-config";
import { useNonDeletedUsers } from "../../../domain/users";
import { translationKeys } from "../../../translations/main-translations";
import ScopeSelect from "../../measures/preferences/ScopeSelect";
import FilterForm from "../../measures/preferences/filter-configuration/FilterForm";
import { IWidgetConfigFormProps } from "../WidgetConfigDialog";
import WidgetConfigTab from "../WidgetConfigTab";
import WidgetConfigTabs from "../WidgetConfigTabs";
import WidgetDescriptionField from "../WidgetDescriptionField";

const WidgetConfigTabContainer = styled("div")(({ theme }) => ({
    padding: theme.spacing(3),
    minHeight: theme.spacing(15),
}));

const FunnelChartWidgetConfigForm = ({
    config,
    name,
    description,
    onConfigChange,
    onDescriptionChange,
    onNameChange,
    onSubmit,
    disabled,
}: IWidgetConfigFormProps) => {
    const { t: translate } = useTranslation();

    const [isValidFilter, setIsValidFilter] = useState(true);
    const [openTab, setOpenTab] = useState(0);

    const fieldDefinitionsQuery = useMeasureFieldDefinitionsQuery();
    const measureAttributes = useMeasureAttributes();
    const users = useNonDeletedUsers();

    const measureConfigs = useMeasureConfigs();
    const processTypeOption = measureConfigs.map((mc) => ({
        label: translate(mc.name),
        value: mc.name,
        id: mc.id,
    }));

    const processTypeValues = processTypeOption?.filter((processType) =>
        (config as FunnelChartWidgetConfig).filter
            .find((fc) => fc.field === MeasureFieldNames.MeasureConfigId)
            ?.values.includes(processType.id),
    );

    const client = useCurrentClient();
    const fiscalYear = useClientFiscalYear();

    if (!validateFunnelChartWidgetConfig(config)) {
        return null;
    }

    const onFilterChange = (newFilter: FilterDefinition, isValid: boolean) => {
        onConfigChange({ ...config, filter: newFilter }, isValid);
        setIsValidFilter(isValid);
    };

    return (
        <Form onSubmit={onSubmit}>
            <WidgetConfigTabs value={openTab} onChange={(event, newValue) => setOpenTab(newValue)}>
                <WidgetConfigTab label={translate(translationKeys.VDLANG_DASHBOARDS_FUNNEL_CHART_WIDGET_CONFIG_TAB_GENERAL)} />
                <WidgetConfigTab label={translate(translationKeys.VDLANG_DASHBOARDS_FUNNEL_CHART_WIDGET_CONFIG_TAB_DESCRIPTION)} />
                <WidgetConfigTab label={translate(translationKeys.VDLANG_DASHBOARDS_FUNNEL_CHART_WIDGET_CONFIG_TAB_SCOPE)} />
                <WidgetConfigTab label={translate(translationKeys.VDLANG_DASHBOARDS_FUNNEL_CHART_WIDGET_CONFIG_TAB_FILTER)} />
            </WidgetConfigTabs>
            {openTab === 0 ? (
                <WidgetConfigTabContainer>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <TextField
                                value={name}
                                onChange={(event) => onNameChange(event.target.value)}
                                label={translate(translationKeys.VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_CONFIG_NAME)}
                                margin="none"
                                disabled={disabled}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Select
                                value={processTypeValues}
                                options={processTypeOption}
                                onChange={(options) =>
                                    options != null &&
                                    onConfigChange(
                                        {
                                            ...config,
                                            filter: [
                                                ...config.filter.filter((f) => f.field !== MeasureFieldNames.MeasureConfigId),
                                                {
                                                    field: MeasureFieldNames.MeasureConfigId,
                                                    operator: Operators.In,
                                                    values: options.map((option) => option.id),
                                                },
                                            ],
                                        },
                                        isValidFilter,
                                    )
                                }
                                label={translate(translationKeys.VDLANG_DASHBOARDS_FUNNEL_CHART_WIDGET_PROCESS_TYPE)}
                                menuPortalTarget={document.body}
                                margin="none"
                                fullWidth
                                isDisabled={disabled}
                                isMulti={true} // help the compiler picking the correct generics
                            />
                        </Grid>
                    </Grid>
                </WidgetConfigTabContainer>
            ) : null}
            {openTab === 1 ? (
                <WidgetConfigTabContainer>
                    <WidgetDescriptionField
                        description={description}
                        onDescriptionChange={onDescriptionChange}
                        label={translate(translationKeys.VDLANG_DASHBOARDS_FUNNEL_CHART_WIDGET_CONFIG_TAB_DESCRIPTION)}
                        disabled={disabled}
                    />
                </WidgetConfigTabContainer>
            ) : null}
            {openTab === 2 ? (
                <WidgetConfigTabContainer>
                    <Grid container>
                        <Grid item xs={6}>
                            <ScopeSelect
                                value={config.scope}
                                onChange={(scope) => onConfigChange({ ...config, scope }, isValidFilter)}
                                measureConfigs={measureConfigs}
                                measureConfigIds={measureConfigs.map((mc: any) => mc.id)}
                                financialMonth={fiscalYear}
                                yearsBefore={client.fiscalYearRangePast}
                                yearsAfter={client.fiscalYearRangeFuture}
                                translate={translate}
                                disabled={disabled}
                                menuPortalTarget={document.body}
                            />
                        </Grid>
                    </Grid>
                </WidgetConfigTabContainer>
            ) : null}
            {openTab === 3 && fieldDefinitionsQuery.isSuccess ? (
                <WidgetConfigTabContainer>
                    <FilterForm
                        filterDefinition={config.filter}
                        onChange={onFilterChange}
                        fieldDefinitions={fieldDefinitionsQuery.data}
                        measureAttributes={measureAttributes}
                        users={users}
                        translate={translate}
                        disabled={disabled}
                        readonlyFields={[MeasureFieldNames.MeasureConfigId]}
                    />
                </WidgetConfigTabContainer>
            ) : null}
        </Form>
    );
};

export default FunnelChartWidgetConfigForm;
