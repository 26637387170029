import { Button, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { translationKeys } from "../translations/main-translations";
import DialogBackButton from "./dialogues/DialogBackButton";

interface ConfirmationPopupContentProps {
    title: string;
    text: string;
    onCancel: () => void;
    onConfirm: () => void;
}

const ConfirmationPopupContent = ({ title, text, onCancel, onConfirm }: ConfirmationPopupContentProps) => {
    const { t } = useTranslation();

    return (
        <Stack p={2} spacing={1}>
            <Typography variant="subtitle1">{title}</Typography>
            <Typography variant="body2">{text}</Typography>
            <Stack direction="row" spacing={1} justifyContent="flex-end">
                <DialogBackButton color="inherit" onClick={onCancel}>
                    {t("Cancel")}
                </DialogBackButton>
                <Button variant="contained" color="error" onClick={onConfirm}>
                    {t(translationKeys.VDLANG_CONFIRM)}
                </Button>
            </Stack>
        </Stack>
    );
};

export default ConfirmationPopupContent;
