import {
    EffectFilterDateField,
    MeasureFieldNames,
    WeeklySavingsRunUpRow,
    WeeklySavingsRunUpWidgetConfig,
    validateWeeklySavingsRunUpWidgetConfig,
} from "api-shared";
import moment from "moment";
import { useState } from "react";
import { useWeeklySavingsRunUpData } from "../../../domain/reporting.ts";
import { IWidgetContentProps } from "../Widget";
import WidgetConfigDialog from "../WidgetConfigDialog";
import ChartWidgetRoot from "../reporting/ChartWidgetRoot";
import DrilldownDialog from "../reporting/DrilldownDialog.tsx";
import { Drilldown } from "../reporting/DrilldownTable.tsx";
import WeeklySavingsRunUpChart from "./WeeklySavingsRunUpChart";
import WeeklySavingsRunUpWidgetConfigForm from "./WeeklySavingsRunUpWidgetConfigForm";

const fieldName = MeasureFieldNames.ClientIid;

type StackKey = "plannedCarryOver" | "materializedCarryOver" | "materializedSavings" | "plannedSavings" | "overdueSavings";

function getDrilldownMeasureIdFilter(type: StackKey, week: WeeklySavingsRunUpRow): number[] {
    return week[`${type}Measures`];
}

const WeeklySavingsRunUpWidget = ({
    widget,
    isConfigDialogOpen,
    onConfigDialogClose,
    onConfigSave,
    translate,
    disabled,
    readOnlyLabel,
    isInView,
}: IWidgetContentProps) => {
    const config = widget.config as WeeklySavingsRunUpWidgetConfig;

    const weeklySavingsRunUpQuery = useWeeklySavingsRunUpData(
        {
            filter: config.filter,
            year: config.year,
            referenceDate: config.referenceDate,
        },
        isInView,
    );
    const data = weeklySavingsRunUpQuery.data ?? [];

    const currentYear = moment.utc({ year: config.year });

    const [drilldown, setDrilldown] = useState<Drilldown>();

    function openDrilldown(xValue: string, stackingValue: unknown) {
        const week = data.find((week) => week.week === Number(xValue));
        if (week === undefined) {
            return;
        }
        const measureIds = getDrilldownMeasureIdFilter(stackingValue as StackKey, week);
        setDrilldown({ [fieldName]: measureIds });
    }

    return (
        <ChartWidgetRoot>
            {drilldown !== undefined && (
                <DrilldownDialog
                    open={drilldown !== undefined}
                    onClose={() => setDrilldown(undefined)}
                    dataKey={`widget${widget.id}`}
                    drilldown={drilldown}
                    filter={config.filter}
                    scope={{
                        startDate: currentYear.clone().startOf("year").format(moment.HTML5_FMT.DATE),
                        endDate: currentYear.clone().endOf("year").format(moment.HTML5_FMT.DATE),
                        attributes: {},
                    }}
                    columns={[
                        MeasureFieldNames.ClientIid,
                        MeasureFieldNames.Title,
                        MeasureFieldNames.CurrentGateTaskConfigId,
                        MeasureFieldNames.Status,
                        MeasureFieldNames.AssignedToId,
                        EffectFilterDateField.StartDate,
                    ]}
                />
            )}
            <WidgetConfigDialog
                open={isConfigDialogOpen}
                onClose={onConfigDialogClose}
                onSave={onConfigSave}
                translate={translate}
                widget={widget}
                validateConfig={validateWeeklySavingsRunUpWidgetConfig}
                FormComponent={WeeklySavingsRunUpWidgetConfigForm}
                noPadding
                disabled={disabled}
                readOnlyLabel={readOnlyLabel}
            />
            <WeeklySavingsRunUpChart
                data={data}
                xAxis="week"
                target={config.target}
                showSums={config.showSums}
                onOpenDrilldown={openDrilldown}
            />
        </ChartWidgetRoot>
    );
};

export default WeeklySavingsRunUpWidget;
