import { FieldDefinitionsDto, PivotRowDto, TreeNodeDto, nonNullable } from "api-shared";
import { useState } from "react";
import { useMeasureAttributes } from "../../../domain/endpoint";
import { useLanguage } from "../../../hooks/useLanguage";
import { translateTreeNodes, useDataForTableName } from "../../../lib/field-options";
import { findField } from "../../../lib/fields";
import { NamedTreeNode, findNode, findPath, getIds, traverseTree, TreeNode } from "../../../lib/tree";

function useTreeData(fieldDefinitions: FieldDefinitionsDto, fieldName: string) {
    const lang = useLanguage();
    const measureAttributes = useMeasureAttributes();
    const field = findField(measureAttributes, fieldDefinitions, fieldName);
    const fieldData = useDataForTableName(field?.tableName ?? null);

    if (field?.type !== "tree") {
        return null;
    }

    const attributeId = field?.options?.depends === "attribute_id" ? field.options?.dependsValue : undefined;
    const filteredFieldData = (fieldData as TreeNodeDto[]).filter((node) => node.attributeId === attributeId);

    return translateTreeNodes(filteredFieldData, lang) as NamedTreeNode[];
}

type UseTreeAggregationProps = {
    fieldDefinitions: FieldDefinitionsDto;
    fieldName: string;
    pivotData?: PivotRowDto[];
    defaultSelectedNodeId?: number | null;
};

export type TreeAggregation = {
    mappedData: PivotRowDto[];
    selectedPath?: NamedTreeNode[];
    setSelectedNode: (id: number | null) => void;
    isNavigatable: (id: number) => boolean;
    isSelectedNode: (id: number) => boolean;
    getSubtreeIds: (id: number) => number[];
    visibleChildren: NamedTreeNode[];
    mappedReferenceValues: PivotRowDto[];
    treeData: NamedTreeNode[];
};

function mapPivotDataToAncestors(
    treeData: NamedTreeNode[],
    pivotData: PivotRowDto[],
    fieldName: string,
    isSelectedNode: (id: number) => boolean,
    isTopLevelShown: boolean,
) {
    const ancestorClosures = treeData.flatMap((child) => {
        const ids = traverseTree(child, getIds) ?? [child.id];
        return ids.map((id) => [id, child.id] as const);
    });
    const ancestorMap = new Map(ancestorClosures);

    const mappedData = pivotData.map((row) => {
        const value = row.fields[fieldName];

        const numberValue = Number(value);

        // Do not touch data assigned directly to the currently selected level
        if (isSelectedNode(numberValue)) {
            return row;
        }

        // replace id with ancestorId at current level
        return {
            ...row,
            fields: {
                ...row.fields,
                [fieldName]: ancestorMap.get(numberValue) ?? null,
            },
        };
    });

    // Keep null/undefined field values if top-level is selected
    if (isTopLevelShown) {
        return mappedData;
    }

    return mappedData.filter((row) => row.fields[fieldName] != null);
}

/**
 * This function checks if the current node has an entry in the pivotData and if not, a new entry is added to the
 * pivotData. If the node has children, this is done recursively. Already existing nodes are basically skipped.
 *
 * Simplified example of what is happening here:
 * Example Tree: A | A-1 | A-2 | B | C
 * Input PivotData: [{A: 100}, { A-2: 50 }, {C: 100}]
 * Returned PivotData: [{A: 100}, {A-1: 0}, {A-2: 50}, {B: 0}, {C: 100}]
 */
function addNodeData(node: TreeNode | NamedTreeNode, pivotData: PivotRowDto[], fieldName: string) {
    // If the node doesn't exist, add it to the pivotData and set its value to 0
    const existingPivotEntry = pivotData.find((entry) => entry.fields[fieldName] === node.id);
    if (!existingPivotEntry) {
        pivotData.push({
            fields: {
                [fieldName]: node.id,
            },
            value: 0,
        });
    }

    // If the node has children, recursively add them to the pivotData as well
    if (node.children) {
        node.children.forEach((childNode) => addNodeData(childNode, pivotData, fieldName));
    }

    return pivotData;
}

/**
 * Processes a given tree structure, adding each node of the tree recursively to pivotData. Each node in the tree
 * structure then has a corresponding entry in the pivotData array.
 *
 * The resulting pivotData can then be used to store reference values.
 */
function mapTreeDataAndExtendPivotData(treeData: NamedTreeNode[], pivotData: PivotRowDto[], fieldName: string) {
    let updatedPivotData = [...pivotData];
    treeData.forEach((node) => {
        updatedPivotData = addNodeData(node, updatedPivotData, fieldName);
    });
    return updatedPivotData;
}

function useTreeAggregation({
    fieldDefinitions,
    fieldName,
    pivotData,
    defaultSelectedNodeId,
}: UseTreeAggregationProps): TreeAggregation | null {
    const treeData = useTreeData(fieldDefinitions, fieldName);

    const [selectedNodeId, setSelectedNodeId] = useState<number | null>(defaultSelectedNodeId ?? null);

    if (treeData == null || pivotData == null) {
        return null;
    }

    const isTopLevelShown = selectedNodeId == null;

    const selectedNode = !isTopLevelShown ? traverseTree(treeData, findNode(selectedNodeId)) : undefined;

    // compute path for breadcrumbs
    const path = !isTopLevelShown ? traverseTree(treeData, findPath(selectedNodeId)) : undefined;
    const selectedPath = path
        ?.map((id) => traverseTree(treeData, findNode(id)))
        .filter(nonNullable)
        .map((i) => i as NamedTreeNode);

    const visibleChildren = (selectedNode?.children ?? treeData) as NamedTreeNode[];

    function isSelectedNode(id: number): boolean {
        return id === selectedNodeId;
    }

    function isNavigatable(id: number) {
        // Only child nodes with children can be navigated
        const node = visibleChildren.find((x) => x.id === id);

        return node?.children != null && node.children.length > 0;
    }

    function getSubtreeIds(id: number): number[] {
        const node = visibleChildren.find((x) => x.id === id) ?? null;
        const subtreeIds = traverseTree(node, getIds);
        return subtreeIds ?? [id];
    }

    const mappedData = mapPivotDataToAncestors(visibleChildren, pivotData, fieldName, isSelectedNode, isTopLevelShown);
    const mappedReferenceValues = mapTreeDataAndExtendPivotData(treeData, pivotData, fieldName);

    return {
        mappedData,
        setSelectedNode: setSelectedNodeId,
        selectedPath,
        isNavigatable,
        isSelectedNode,
        getSubtreeIds,
        visibleChildren,
        mappedReferenceValues,
        treeData,
    };
}

export default useTreeAggregation;
