import { useTheme } from "@mui/material";
import { WaterfallData } from "api-shared";
import { Bar, CartesianGrid, ComposedChart, LabelList, Tooltip, XAxis, YAxis } from "recharts";
import ResponsiveContainer from "../../../components/ResponsiveContainer";
import useCurrency from "../../../hooks/useCurrency";
import { useAngledXAxisPropsWithMaxCharacters, useCurrencyYAxisProps, useDashboardColors, useTooltipProps } from "../charts/hooks";
import { BarRectangleItemWithPayloads } from "../types";

interface WaterfallChartProps {
    data: WaterfallData;
    onOpenDrilldown: (payload: any) => void;
    showSums: boolean;
    axisMinValue: number | null;
    axisMaxValue: number | null;
}

const WaterfallChart = ({ data, onOpenDrilldown, showSums, axisMinValue, axisMaxValue }: WaterfallChartProps) => {
    const theme = useTheme();
    const [color] = useDashboardColors();
    const { formatCurrency, formatCurrencyShortNoCode } = useCurrency();
    const xAxisProps = useAngledXAxisPropsWithMaxCharacters();
    const currencyYAxisProps = useCurrencyYAxisProps(undefined, axisMinValue, axisMaxValue);
    const tooltipProps = useTooltipProps({
        hideSummary: true,
        showName: false,
    });
    const handleBarClick = (payload: any) => {
        return onOpenDrilldown(payload);
    };

    return (
        <ResponsiveContainer>
            <ComposedChart data={data} stackOffset="none" margin={{ top: 30, left: 5, right: 5, bottom: 5 }}>
                <CartesianGrid strokeDasharray="4" vertical={false} />
                <Tooltip {...tooltipProps} formatter={(value: number | string) => formatCurrency(value) ?? value} />
                <Bar dataKey="startValue" stackId="a" fill="transparent" legendType="none" />
                <Bar
                    dataKey="value"
                    stackId="a"
                    fill={color}
                    cursor="pointer"
                    onClick={(data: BarRectangleItemWithPayloads) => {
                        // Do not open drilldown for result blocks (they have attribute set to `null`)
                        if (data.payload.content.attribute === null) {
                            return;
                        }
                        handleBarClick(data.payload);
                    }}
                >
                    {showSums ? (
                        <LabelList
                            style={{
                                ...theme.typography.body2,
                            }}
                            fill={theme.palette.text.primary}
                            dataKey="value"
                            position="top"
                            formatter={(value: number | string) => formatCurrencyShortNoCode(value) ?? value}
                        />
                    ) : null}
                </Bar>
                <XAxis {...xAxisProps} dataKey="name" />
                <YAxis {...currencyYAxisProps} />
            </ComposedChart>
        </ResponsiveContainer>
    );
};

export default WaterfallChart;
