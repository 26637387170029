import { LiveRunUpGranularity, LiveRunUpMonth, MeasureCalculationGranularity, generateFiscalMomentsFromInterval } from "api-shared";
import moment from "moment";

export const MeasureCalculationGranularityMap = {
    [LiveRunUpGranularity.Month]: MeasureCalculationGranularity.MONTH,
    [LiveRunUpGranularity.Quarter]: MeasureCalculationGranularity.FISCAL_QUARTER,
    [LiveRunUpGranularity.Year]: MeasureCalculationGranularity.FISCAL_YEAR,
    // This needs some more effort for the CalculationTimeRangeSelect component
    [LiveRunUpGranularity.HalfYear]: MeasureCalculationGranularity.FISCAL_YEAR,
};

export const MomentGranularityMap = {
    [LiveRunUpGranularity.Month]: "month" as const,
    [LiveRunUpGranularity.Quarter]: "quarter" as const,
    [LiveRunUpGranularity.Year]: "year" as const,
    // This needs some more effort for the CalculationTimeRangeSelect component
    [LiveRunUpGranularity.HalfYear]: "year" as const,
};

export function generateDomain(start: LiveRunUpMonth, end: LiveRunUpMonth, granularity: LiveRunUpGranularity): string[] {
    const fiscalMoments = generateFiscalMomentsFromInterval(
        moment.utc(start),
        moment.utc(end),
        MeasureCalculationGranularityMap[granularity],
        0,
    );
    return fiscalMoments.map(({ calendarMoment }) => calendarMoment.format(moment.HTML5_FMT.DATE));
}
