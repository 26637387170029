import DeleteIcon from "@mui/icons-material/DeleteRounded";
import { Grid, IconButton, TextField, styled } from "@mui/material";
import { WaterfallBlockData, WaterfallSign, nonNullable } from "api-shared";
import { useTranslation } from "react-i18next";
import Tooltip from "../../../../components/Tooltip";
import EditableText from "../../../../components/input/EditableText";
import Select from "../../../../components/input/select/Select";
import FieldTreeInput from "../../../../components/input/tree/FieldTreeInput.tsx";
import { Field } from "../../../../lib/fields";
import { translationKeys, translations } from "../../../../translations/main-translations";
import TreeProvider from "../../../TreeProvider";
import { FieldOption } from "../../reporting/useFieldOptions.ts";

const waterfallBlockClasses = {
    deleteButton: "VdWaterfallBlock-deleteButton",
    formField: "VdWaterfallBlock-FormField",
};

const BlockContainer = styled("div")(({ theme }) => ({
    backgroundColor: theme.palette.background.default,
    border: `1px dashed ${theme.palette.border.light}`,
    borderRadius: theme.spacing(1),
    padding: theme.spacing(2),
    [`& .${waterfallBlockClasses.deleteButton}`]: {
        opacity: 0,
        transition: theme.transitions.create("opacity"),
    },
    [`&:hover .${waterfallBlockClasses.deleteButton}`]: {
        opacity: 1,
    },
    [`& .${waterfallBlockClasses.formField} [type="text"]`]: {
        backgroundColor: "white",
    },
}));

interface WaterfallBlockItemProps {
    index: number;
    item: WaterfallBlockData;
    fieldOptionValues: FieldOption[];
    isTreeAttribute: boolean;
    treeField: Field;
    disabled: boolean;
    onItemUpdate: (index: number, item: WaterfallBlockData) => void;
    onDelete: (index: number) => void;
}

const WaterfallBlockItem = ({
    index,
    item,
    fieldOptionValues,
    isTreeAttribute,
    treeField,
    disabled,
    onItemUpdate,
    onDelete,
}: WaterfallBlockItemProps) => {
    const { t } = useTranslation();

    const signOptions = [
        { label: t(translationKeys.VDLANG_DASHBOARDS_WATERFALL_CONFIG_SIGN_PLUS), value: WaterfallSign.Plus },
        { label: t(translationKeys.VDLANG_DASHBOARDS_WATERFALL_CONFIG_SIGN_MINUS), value: WaterfallSign.Minus },
    ];

    const selectedSign = signOptions.find(({ value }) => value === item.sign) ?? {
        label: t(translationKeys.VDLANG_DASHBOARDS_WATERFALL_CONFIG_SIGN_PLUS),
        value: WaterfallSign.Plus,
    };

    const updateSelectedFieldValues = (newValue: number[] | number | null) => {
        onItemUpdate(index, {
            ...item,
            fieldValues: [newValue].flat().filter(nonNullable).map(String),
        });
    };

    const selectedFieldValues = item.fieldValues
        .map((value) => fieldOptionValues.find((v) => v.value == value) ?? null)
        .filter(nonNullable);

    let selectedFieldValuesAsArray: number[] = [];
    if (Array.isArray(selectedFieldValues)) {
        selectedFieldValuesAsArray = selectedFieldValues.map(({ value }) => Number(value));
    } else if (selectedFieldValues != null) {
        selectedFieldValuesAsArray = [+selectedFieldValues];
    }

    const resultNames = translations[translationKeys.VDLANG_DASHBOARDS_WATERFALL_CONFIG_RESULT_PLACEHOLDER];
    const resultNameIsPlaceholder = resultNames.includes(item.resultName);

    return (
        <BlockContainer>
            <Grid container spacing={1}>
                <Grid item xs={11}>
                    <EditableText
                        text={item.resultName}
                        onTextSave={(newName) => onItemUpdate(index, { ...item, resultName: newName })}
                        variant="subtitle1"
                        size="small"
                        disabled={disabled}
                        sx={{ color: (theme) => (resultNameIsPlaceholder ? theme.palette.text.secondary : "initial") }}
                    />
                </Grid>
                <Grid item xs={1} textAlign="right">
                    {!disabled ? (
                        <Tooltip title={t(translationKeys.VDLANG_DASHBOARDS_WATERFALL_CONFIG_DELETE_BLOCK)}>
                            <IconButton size="small" className={waterfallBlockClasses.deleteButton} onClick={() => onDelete(index)}>
                                <DeleteIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    ) : null}
                </Grid>
                <Grid item xs={12} sm={2}>
                    <Select
                        value={selectedSign}
                        options={signOptions}
                        onChange={(option) => {
                            if (option?.value) {
                                onItemUpdate(index, { ...item, sign: option.value });
                            }
                        }}
                        label={t(translationKeys.VDLANG_DASHBOARDS_WATERFALL_CONFIG_SIGN)}
                        menuPortalTarget={document.body}
                        margin="none"
                        fullWidth
                        isDisabled={disabled}
                        className={waterfallBlockClasses.formField}
                    />
                </Grid>
                <Grid item xs={12} sm={5}>
                    {isTreeAttribute ? (
                        <TreeProvider
                            field={treeField}
                            component={FieldTreeInput}
                            componentProps={{
                                field: treeField,
                                translate: t,
                                updateValue: updateSelectedFieldValues,
                                isMulti: true,
                                value: selectedFieldValuesAsArray,
                                label: treeField.title,
                                disabled,
                                className: waterfallBlockClasses.formField,
                                narrowSelection: false,
                            }}
                        />
                    ) : (
                        <Select
                            value={selectedFieldValues}
                            options={fieldOptionValues}
                            onChange={(option) =>
                                option != null && onItemUpdate(index, { ...item, fieldValues: option.map((v) => v.value) })
                            }
                            label={t(translationKeys.VDLANG_DASHBOARDS_WATERFALL_CONFIG_FIELD_VALUES)}
                            menuPortalTarget={document.body}
                            margin="none"
                            fullWidth
                            isMulti
                            isSearchable
                            isDisabled={disabled}
                            className={waterfallBlockClasses.formField}
                        />
                    )}
                </Grid>
                <Grid item xs={12} sm={5}>
                    <TextField
                        value={item.name}
                        onChange={(event) => onItemUpdate(index, { ...item, name: event.target.value })}
                        label={t(translationKeys.VDLANG_DASHBOARDS_WATERFALL_CONFIG_BLOCK_NAME)}
                        margin="none"
                        disabled={disabled}
                        fullWidth
                        inputProps={{ style: { backgroundColor: "white" } }}
                    />
                </Grid>
            </Grid>
        </BlockContainer>
    );
};

export default WaterfallBlockItem;
