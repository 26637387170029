import { InputAdornment, TextField, TextFieldProps } from "@mui/material";
import { ChangeEvent, FocusEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import useCurrency from "../../hooks/useCurrency";
import { isValidCurrencyInput } from "../../lib/currency";

/**
 * Check if the input is a simple currency string:
 * - A valid currency input
 * - Not a formula
 * @param input - A potential simple currency string
 */
const isValidSimpleCurrencyInput = (input: string): boolean => isValidCurrencyInput(input) && !input.startsWith("=");
interface ISimpleCurrencyInputProps extends Omit<TextFieldProps, "value" | "onChange"> {
    value: number | null;
    onChange?: (value: number | null) => void;
}

export const SimpleCurrencyInput = (props: ISimpleCurrencyInputProps) => {
    const { value, onChange, onFocus, onBlur, InputProps, ...otherProps } = props;

    const { t } = useTranslation();
    const { currencyIsoCode, formatCurrencyNoCode, sanitizeCurrencyInput } = useCurrency();

    const [input, setInput] = useState<string | null>(null);
    const [isLastChangeInvalid, setIsLastChangeInvalid] = useState(false);

    const handleFocus = (event: FocusEvent<HTMLInputElement>) => {
        const original = value?.toString() ?? "";
        const formatted = formatCurrencyNoCode(original);
        setInput(formatted);
        onFocus?.(event);
    };

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const change = event.target.value;

        if (!isValidSimpleCurrencyInput(change)) {
            setIsLastChangeInvalid(true);
            return;
        }

        const sanitized = sanitizeCurrencyInput(change);
        const isValid = sanitized === "" || isValidCurrencyInput(sanitized);

        if (!isValid) {
            setIsLastChangeInvalid(true);
            return;
        }

        let parsed = sanitized !== "" ? Number(sanitized) : null;

        if (Number.isNaN(parsed)) {
            parsed = null;
        }

        setIsLastChangeInvalid(false);
        setInput(change);
        onChange?.(parsed);
    };

    const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
        setInput(null);
        setIsLastChangeInvalid(false);
        onBlur?.(event);
    };

    const startAdornment = <InputAdornment position="start">{currencyIsoCode}</InputAdornment>;
    const formatted = formatCurrencyNoCode(value ?? "");

    return (
        <TextField
            value={input ?? formatted}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onChange={handleChange}
            error={isLastChangeInvalid}
            helperText={isLastChangeInvalid && t("NoLettersInCurrency")}
            InputProps={{ ...InputProps, startAdornment }}
            {...otherProps}
        />
    );
};
