import { styled } from "@mui/material";
import { WeeklySavingsRunUpWidgetConfig, FieldDefinitionsDto, FilterDefinition } from "api-shared";
import FilterForm from "../../../measures/preferences/filter-configuration/FilterForm.tsx";
import { useTranslation } from "react-i18next";
import { useMeasureAttributes } from "../../../../domain/endpoint.ts";
import { useNonDeletedUsers } from "../../../../domain/users.ts";

const WidgetConfigTabContainer = styled("div")(({ theme }) => ({
    padding: theme.spacing(3),
    minHeight: theme.spacing(25),
}));

interface WeeklySavingsRunUpConfigTabFilterConfigurationProps {
    config: WeeklySavingsRunUpWidgetConfig;
    disabled: boolean;
    onConfigChange: (newConfig: WeeklySavingsRunUpWidgetConfig, overrideIsValidFilter: boolean) => void;
    fieldDefinitions: FieldDefinitionsDto;
}

const WeeklySavingsRunUpConfigTabFilterConfiguration = ({
    config,
    disabled,
    onConfigChange,
    fieldDefinitions,
}: WeeklySavingsRunUpConfigTabFilterConfigurationProps) => {
    const { t } = useTranslation();
    const measureAttributes = useMeasureAttributes();
    const users = useNonDeletedUsers();

    return (
        <WidgetConfigTabContainer>
            <FilterForm
                filterDefinition={config.filter}
                onChange={(newFilter: FilterDefinition, isValid) => {
                    onConfigChange({ ...config, filter: newFilter }, isValid);
                }}
                fieldDefinitions={fieldDefinitions}
                measureAttributes={measureAttributes}
                users={users}
                translate={t}
                disabled={disabled}
            />
        </WidgetConfigTabContainer>
    );
};

export default WeeklySavingsRunUpConfigTabFilterConfiguration;
