import { LinkableAxisTick, LinkableAxisTickProps } from "./LinkableAxisTick";

const VERTICAL_OFFSET = 8;

const AngledXAxisTick = ({ dy = VERTICAL_OFFSET, ...props }: LinkableAxisTickProps) => {
    return (
        <LinkableAxisTick
            {...props}
            textAnchor="end"
            dy={dy}
            // rotate around (x+16, y + offset)
            transform={`rotate(-45 ${Number(props.x ?? 0) + 16} ${Number(props.y ?? 0) + Number(dy ?? 0)})`}
        />
    );
};

export default AngledXAxisTick;
