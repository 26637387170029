import { Stack, styled, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import WidgetErrorImage from "../../static/images/widgets/error.svg";
import { translationKeys } from "../../translations/main-translations";

const Text = styled(Typography)(() => ({
    maxWidth: 300,
}));

const WidgetInvalidConfig = ({ children }: React.PropsWithChildren<unknown>) => {
    const { t: translate } = useTranslation();
    const text = translate(translationKeys.VDLANG_DASHBOARDS_WIDGET_ERROR);

    return (
        <Stack sx={{ height: "100%" }} alignItems="center" justifyContent="center" spacing={2}>
            <img src={WidgetErrorImage} alt={text} />
            <Text variant="body2" color="textSecondary" align="center">
                {children ?? text}
            </Text>
        </Stack>
    );
};

export default WidgetInvalidConfig;
