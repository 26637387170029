import { validateBasicMeasureFilterAndScopeConfig } from "api-shared";
import PotentialKPI from "./PotentialKPI";
import PotentialSumWidgetConfigForm from "./PotentialSumWidgetConfigForm";
import { IWidgetContentProps } from "./Widget";
import WidgetConfigDialog from "./WidgetConfigDialog";

const PotentialSumWidget = ({
    widget,
    isConfigDialogOpen,
    onConfigDialogClose,
    onConfigSave,
    translate,
    disabled = false,
    readOnlyLabel,
    isInView,
}: IWidgetContentProps) => {
    if (!validateBasicMeasureFilterAndScopeConfig(widget.config)) {
        return null;
    }

    return (
        <>
            <WidgetConfigDialog
                open={isConfigDialogOpen}
                onClose={onConfigDialogClose}
                onSave={onConfigSave}
                translate={translate}
                widget={widget}
                validateConfig={validateBasicMeasureFilterAndScopeConfig}
                FormComponent={PotentialSumWidgetConfigForm}
                noPadding
                disabled={disabled}
                readOnlyLabel={readOnlyLabel}
            />
            <PotentialKPI widget={widget} filter={widget.config.filter} scope={widget.config.scope} enableQueries={isInView} />
        </>
    );
};

export default PotentialSumWidget;
