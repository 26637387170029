import { styled } from "@mui/material";
import WidgetDescriptionField from "../../WidgetDescriptionField";

const WidgetConfigTabContainer = styled("div")(({ theme }) => ({
    padding: theme.spacing(3),
    minHeight: theme.spacing(25),
}));

interface WeeklySavingsRunUpConfigTabDescriptionProps {
    label: string;
    disabled: boolean;
    description: string | null;
    onDescriptionChange: (newDescription: string | null) => void;
}

const WeeklySavingsRunUpConfigTabDescription = ({
    label,
    disabled,
    description,
    onDescriptionChange,
}: WeeklySavingsRunUpConfigTabDescriptionProps) => {
    return (
        <WidgetConfigTabContainer>
            <WidgetDescriptionField description={description} onDescriptionChange={onDescriptionChange} label={label} disabled={disabled} />
        </WidgetConfigTabContainer>
    );
};

export default WeeklySavingsRunUpConfigTabDescription;
