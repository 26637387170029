import { Typography, TypographyProps, styled } from "@mui/material";

interface MultilineEllipsisTypographyProps extends TypographyProps {
    lines: number;
}

const ClampedTypography = styled(Typography, { shouldForwardProp: (name) => name !== "lines" })<{
    lines: number;
}>(({ lines }) => ({
    display: "-webkit-box",
    overflow: "hidden",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: lines,
    wordBreak: "break-word",
}));

/*
This component renders a Typography text that is clamped and shows an ellipsis.
It takes the number of lines that should be shown as parameter.
*/
const MultilineEllipsisTypography = ({ lines, children, ...restProps }: MultilineEllipsisTypographyProps) => {
    return (
        <span>
            <ClampedTypography lines={lines} {...restProps}>
                {children}
            </ClampedTypography>
        </span>
    );
};

export default MultilineEllipsisTypography;
