import { styled } from "@mui/material";
import { times } from "lodash";
import { GRID_COLUMNS } from "./utils";

const DashboardUnderlayContainer = styled("div", {
    shouldForwardProp: (name) => name !== "rowHeight" && name !== "show",
})<{
    rowHeight: number;
    show: boolean;
}>(({ theme, rowHeight, show }) => ({
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: -1,
    display: "grid",
    gridGap: theme.spacing(2),
    gridAutoRows: rowHeight,
    gridTemplateColumns: times(GRID_COLUMNS, () => "1fr").join(" "),
    padding: theme.spacing(2),
    transition: theme.transitions.create("opacity"),
    opacity: show ? 1 : 0,
}));

export default DashboardUnderlayContainer;
