import moment, { Moment } from "moment";

export function generateTargetMonthId(year: number, month: number): string;
export function generateTargetMonthId(m: Moment): string;
export function generateTargetMonthId(momentOrYear: Moment | number, month = 0): string {
    const myMoment = moment.isMoment(momentOrYear) ? momentOrYear : moment().startOf("month").month(month).year(momentOrYear);
    return myMoment.format("YYYY-MM");
}

export function generatePartitionedMonths(startDate: string, endDate: string): Moment[][] {
    const numberOfMonths = moment(endDate).diff(startDate, "months") + 1;
    const months = [...Array(numberOfMonths).keys()].map((index) => moment(startDate).add(index, "months"));
    const middleIndex = Math.ceil(numberOfMonths / 2);

    const firstMonths = months.slice(0, middleIndex);
    const lastMonths = months.slice(middleIndex);
    return [firstMonths, lastMonths];
}
