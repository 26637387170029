import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import { type ICompareExpression, type ProjectProgressMonth, type ScopeDto } from "api-shared";
import moment from "moment";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import BaseDialog, { type IBaseDialogProps } from "../../../components/dialogues/BaseDialog";
import { translationKeys } from "../../../translations/main-translations";
import WidgetConfigTab from "../WidgetConfigTab";
import WidgetConfigTabs from "../WidgetConfigTabs";
import DrilldownTable, { type DrilldownTableProps } from "../reporting/DrilldownTable";
import { useEffectCategoryAttributes } from "../../../domain/measure-config.ts";

enum DrilldownTab {
    Recurring,
    OneTime,
}

interface ProjectProgressDrilldownDialogProps extends Omit<DrilldownTableProps, "scope">, Pick<IBaseDialogProps, "open" | "onClose"> {
    selectedYear: ProjectProgressMonth;
    projectStart: ProjectProgressMonth;
    scope: Omit<ScopeDto, "startDate" | "endDate">;
    oneTimeFilter: ICompareExpression;
    recurringFilter: ICompareExpression;
    recurringAttribute: string | null;
}

const ProjectProgressDrilldownDialog = ({
    open,
    onClose,
    dataKey,
    drilldown,
    filter,
    scope,
    selectedYear,
    projectStart,
    oneTimeFilter,
    recurringFilter,
    recurringAttribute,
}: ProjectProgressDrilldownDialogProps) => {
    const { t } = useTranslation();

    const [openTab, setOpenTab] = useState(DrilldownTab.Recurring);
    const isOneTimeTab = openTab === DrilldownTab.OneTime;

    const selectedYearMoment = moment({ ...selectedYear, day: 1 });
    const scopeStartMoment = isOneTimeTab ? selectedYearMoment : moment({ ...projectStart, day: 1 });
    const scopeEndMoment = selectedYearMoment.clone().add(1, "year").subtract(1, "day");

    // When the recurringAttribute is an effect category attribute, we add it to the scope that is used by the drilldown
    const scopeAttributeNames = useEffectCategoryAttributes().map(({ title }) => title);
    const scopeAttributes =
        recurringAttribute !== null && scopeAttributeNames.includes(recurringAttribute)
            ? {
                  ...scope.attributes,
                  [recurringAttribute]: isOneTimeTab ? oneTimeFilter.values.map(Number) : recurringFilter.values.map(Number),
              }
            : scope.attributes;

    const activeScope = {
        ...scope,
        startDate: scopeStartMoment.format(moment.HTML5_FMT.DATE),
        endDate: scopeEndMoment.format(moment.HTML5_FMT.DATE),
        attributes: scopeAttributes,
    };

    const activeFilter = [...(filter ?? []), isOneTimeTab ? oneTimeFilter : recurringFilter];

    return (
        <BaseDialog
            open={open}
            onClose={onClose}
            title={t(translationKeys.VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_DRILLDOWN_TITLE)}
            menuActions={[
                <IconButton key="close" onClick={onClose}>
                    <CloseIcon />
                </IconButton>,
            ]}
            // fix DraggableCore error, disable possibility to double click measure link. https://github.com/react-grid-layout/react-draggable/blob/2c3aa84352ca8eaed42a9ac0b59a1187e7850b6a/lib/DraggableCore.js#L277
            onMouseDown={(e) => e.stopPropagation()}
            maxWidth="lg"
            disableContentPadding
        >
            <WidgetConfigTabs value={openTab} onChange={(event, newTab) => setOpenTab(newTab)}>
                <WidgetConfigTab
                    value={DrilldownTab.Recurring}
                    label={t(translationKeys.VDLANG_DASHBOARDS_PROJECT_PROGRESS_WIDGET_DRILLDOWN_RECURRING_TAB)}
                />
                <WidgetConfigTab
                    value={DrilldownTab.OneTime}
                    label={t(translationKeys.VDLANG_DASHBOARDS_PROJECT_PROGRESS_WIDGET_DRILLDOWN_ONE_TIME_TAB)}
                />
            </WidgetConfigTabs>
            <DrilldownTable
                key={`${dataKey}-${openTab}`}
                dataKey={dataKey}
                drilldown={drilldown}
                filter={activeFilter}
                scope={activeScope}
            />
        </BaseDialog>
    );
};

export default ProjectProgressDrilldownDialog;
