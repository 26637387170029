import { WaterfallWidgetConfig, validateWaterfallWidgetConfig, MeasureFieldNames, EffectFilterCurrencyField } from "api-shared";
import { IWidgetContentProps } from "../Widget";
import ChartWidgetRoot from "../reporting/ChartWidgetRoot";
import WidgetConfigDialog from "../WidgetConfigDialog";
import WaterfallChart from "./WaterfallChart";
import WaterfallConfig from "./WaterfallConfig";
import { useState } from "react";
import { Drilldown } from "../reporting/DrilldownTable";
import DrilldownDialog from "../reporting/DrilldownDialog";
import { useWaterfallData } from "../../../domain/reporting";
import WidgetNoData from "../WidgetNoData";
import EmptyWaterfallIllustration from "../../../static/images/widgets/empty-waterfall.svg";
import { useFilterValidation } from "../../../hooks/useFilterValidation";
import WidgetStateContainer from "../widget-states/WidgetStateContainer";
import WaterfallChartSkeleton from "../../../components/loading/WaterfallChartSkeleton";

const WaterfallWidget = ({
    widget,
    isConfigDialogOpen,
    openConfigDialog,
    onConfigDialogClose,
    onConfigSave,
    translate,
    disabled,
    readOnlyLabel,
    isInView,
}: IWidgetContentProps) => {
    const config = widget.config as WaterfallWidgetConfig;
    const isValidConfig = validateWaterfallWidgetConfig(config);
    const { validate } = useFilterValidation(isInView);
    const hasValidFilter = validate?.(config.filter);
    const waterfallQuery = useWaterfallData(
        {
            filter: config.filter,
            scope: config.scope,
            attribute: config.attribute,
            fieldValues: config.fieldValues,
            initialBlockName: config.initialBlockName,
            blocks: config.blocks,
        },
        isValidConfig && isInView,
    );
    const data = waterfallQuery.data ?? [];
    const [drilldown, setDrilldown] = useState<Drilldown>();

    function openDrilldown(payload: any) {
        setDrilldown({ [payload.content.attribute]: payload.content.fieldValues });
    }

    const drilldownColumns =
        config.attribute !== null
            ? [
                  MeasureFieldNames.ClientIid,
                  MeasureFieldNames.Title,
                  MeasureFieldNames.CurrentGateTaskConfigId,
                  MeasureFieldNames.Status,
                  MeasureFieldNames.AssignedToId,
                  config.attribute,
                  EffectFilterCurrencyField.Effect,
              ]
            : [];

    return (
        <ChartWidgetRoot>
            {drilldown !== undefined && (
                <DrilldownDialog
                    open
                    onClose={() => setDrilldown(undefined)}
                    dataKey="value"
                    drilldown={drilldown}
                    filter={config.filter}
                    scope={config.scope}
                    columns={drilldownColumns}
                />
            )}
            <WidgetConfigDialog
                open={isConfigDialogOpen}
                onClose={onConfigDialogClose}
                onSave={onConfigSave}
                translate={translate}
                widget={widget}
                validateConfig={validateWaterfallWidgetConfig}
                FormComponent={WaterfallConfig}
                noPadding
                disabled={disabled}
                readOnlyLabel={readOnlyLabel}
            />
            <WidgetStateContainer
                widgetType={widget.type}
                // config validation is async, so explicitly check for false to avoid invalid config state being shortly shown while validation is running
                hasInvalidConfig={hasValidFilter === false}
                dataQuery={waterfallQuery}
                additionalQueries={[]}
                renderEmpty={() => <WidgetNoData src={EmptyWaterfallIllustration} />}
                renderLoading={() => <WaterfallChartSkeleton />}
                openConfigDialog={openConfigDialog}
                hasIncompleteConfig={config.attribute === null || config.fieldValues.length === 0}
            >
                <WaterfallChart
                    data={data}
                    onOpenDrilldown={openDrilldown}
                    showSums={config.showSums}
                    axisMinValue={config.axisMinValue}
                    axisMaxValue={config.axisMaxValue}
                />
            </WidgetStateContainer>
        </ChartWidgetRoot>
    );
};

export default WaterfallWidget;
