import { zodResolver } from "@hookform/resolvers/zod";
import { TextField } from "@mui/material";
import { DashboardDto, UpdateDashboardRequestBody, zUpdateDashboardRequestBody } from "api-shared";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ActionItemDialog from "../../components/dialogues/ActionItemDialog";
import { useUpdateDashboardMutation } from "../../domain/dashboards";
import { tryTranslate } from "../../lib/translate";
import { translationKeys } from "../../translations/main-translations";

interface UpdateDashboardDialogProps {
    dashboard: DashboardDto;
    open: boolean;
    onClose: () => void;
}

const UpdateDashboardDialog = ({ dashboard, open, onClose }: UpdateDashboardDialogProps) => {
    const { t: translate } = useTranslation();

    const currentDashboardName = tryTranslate(translate, dashboard.name);

    const {
        register,
        handleSubmit,
        formState: { errors, isValid },
    } = useForm<UpdateDashboardRequestBody>({
        mode: "onChange",
        resolver: zodResolver(zUpdateDashboardRequestBody),
        defaultValues: {
            name: currentDashboardName,
        },
    });

    const updateDashboardMutation = useUpdateDashboardMutation();

    const handleUpdate = handleSubmit(({ name }) => {
        updateDashboardMutation.mutate({
            name,
            dashboardId: dashboard.id,
        });

        onClose();
    });

    const helperTextSelect = () => {
        if (errors.name === undefined) {
            return null;
        }
        if (errors.name?.type === "too_big") {
            return translate(translationKeys.VDLANG_DASHBOARDS_UPDATE_NAME_TOO_LONG);
        }
        if (errors.name?.type === "too_small") {
            return translate(translationKeys.VDLANG_DASHBOARDS_UPDATE_NAME_ERROR);
        }
        return null;
    };

    return (
        <ActionItemDialog
            title={translate(translationKeys.VDLANG_DASHBOARDS_UPDATE)}
            primary={translationKeys.VDLANG_DASHBOARDS_UPDATE_BUTTON}
            onPrimary={handleUpdate}
            primaryDisabled={!isValid}
            open={open}
            onClose={onClose}
            translate={translate}
        >
            <form onSubmit={handleUpdate}>
                <TextField
                    label={translate(translationKeys.VDLANG_DASHBOARDS_UPDATE_NAME)}
                    margin="dense"
                    fullWidth
                    autoFocus
                    error={errors.name !== undefined}
                    helperText={helperTextSelect()}
                    {...register("name")}
                />
            </form>
        </ActionItemDialog>
    );
};

export default UpdateDashboardDialog;
