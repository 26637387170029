import ClearIcon from "@mui/icons-material/ClearRounded";
import { ButtonBase, InputAdornment, TextField, Typography, styled, type TextFieldProps } from "@mui/material";
import { validateFormula } from "api-shared";
import { useTranslation } from "react-i18next";
import { translationKeys } from "../../../translations/main-translations";

type FormulaInputProps = Omit<TextFieldProps, "value" | "onChange"> & {
    value: string;
    onChange?: (value: string) => void;
};

const StartAdornment = styled(InputAdornment)(({ theme }) => ({
    backgroundColor: theme.palette.background.default,
    borderRight: `1px solid ${theme.palette.divider}`,
    paddingRight: theme.spacing(1),
    // Unset height restrictions form InputAdornment, so that background can span full height
    height: "unset",
    maxHeight: "unset",
    alignSelf: "stretch",
    // together with removed default left padding of input element, this allows the adornment background to span until the left edge
    paddingLeft: theme.spacing(1.5),
}));

function FormulaInput({ value, onChange, ...textFieldProps }: FormulaInputProps) {
    const { t: translate } = useTranslation();
    const isValidFormula = validateFormula(value);

    return (
        <TextField
            value={value}
            onChange={(event) => {
                onChange?.(event.target.value);
            }}
            error={!isValidFormula}
            helperText={!isValidFormula ? translate(translationKeys.VDLANG_FORMULA_INPUT_INVALID_FORMULA) : undefined}
            InputProps={{
                sx: { pl: 0 }, // replace input's left padding with left padding on startAdornment, so that its background color can span to the top left edge
                startAdornment: (
                    <StartAdornment position="start">
                        <Typography color="textPrimary">ƒ(w)</Typography>
                    </StartAdornment>
                ),
                endAdornment:
                    value != "" && !textFieldProps.disabled ? (
                        <InputAdornment position="end">
                            <ButtonBase disableRipple onClick={() => onChange?.("")}>
                                <ClearIcon fontSize="small" />
                            </ButtonBase>
                        </InputAdornment>
                    ) : undefined,
                ...textFieldProps.InputProps,
            }}
            {...textFieldProps}
        />
    );
}

export default FormulaInput;
