import { WaterfallWidgetConfig, WidgetConfig } from "api-shared";
import { styled, Grid, TextField, Switch, Typography } from "@mui/material";
import { translationKeys } from "../../../../translations/main-translations.ts";
import { useTranslation } from "react-i18next";
import { SimpleCurrencyInput } from "../../../../components/input/SimpleCurrencyInput.tsx";

const WidgetConfigTabContainer = styled("div")(({ theme }) => ({
    padding: theme.spacing(3),
    minHeight: theme.spacing(25),
}));

interface WaterfallConfigTabGeneralProps {
    config: WaterfallWidgetConfig;
    name: string;
    onNameChange: (newName: string) => void;
    onConfigChange: (newConfig: WidgetConfig, isValid?: boolean) => void;
    disabled: boolean;
}

const WaterfallConfigTabGeneral = ({ config, name, onNameChange, onConfigChange, disabled }: WaterfallConfigTabGeneralProps) => {
    const { t } = useTranslation();

    return (
        <WidgetConfigTabContainer>
            <Grid container rowSpacing={2} columnSpacing={1}>
                <Grid item xs={12}>
                    <TextField
                        value={name}
                        onChange={(event) => onNameChange(event.target.value)}
                        label={t(translationKeys.VDLANG_DASHBOARDS_WATERFALL_CONFIG_NAME)}
                        margin="none"
                        disabled={disabled}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={6}>
                    <SimpleCurrencyInput
                        value={config.axisMinValue}
                        onChange={(axisMinValue) => onConfigChange({ ...config, axisMinValue })}
                        label={t(translationKeys.VDLANG_DASHBOARDS_WATERFALL_CONFIG_AXIS_MIN_VALUE)}
                        margin="none"
                        disabled={disabled}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={6}>
                    <SimpleCurrencyInput
                        value={config.axisMaxValue}
                        onChange={(axisMaxValue) => onConfigChange({ ...config, axisMaxValue })}
                        label={t(translationKeys.VDLANG_DASHBOARDS_WATERFALL_CONFIG_AXIS_MAX_VALUE)}
                        margin="none"
                        disabled={disabled}
                        fullWidth
                    />
                </Grid>
                <Grid direction="row" flexWrap="nowrap" display="flex" alignItems="center" item xs={12}>
                    <Switch
                        size="small"
                        edge="start"
                        checked={config.showSums}
                        onChange={(e, checked) => onConfigChange({ ...config, showSums: checked })}
                        disabled={disabled}
                    />
                    <Typography>{t(translationKeys.VDLANG_DASHBOARDS_WATERFALL_CONFIG_SHOW_SUMS)}</Typography>
                </Grid>
            </Grid>
        </WidgetConfigTabContainer>
    );
};

export default WaterfallConfigTabGeneral;
