import InfoOutlined from "@mui/icons-material/InfoOutlined";
import InfoRounded from "@mui/icons-material/InfoRounded";
import TuneIcon from "@mui/icons-material/TuneRounded";
import UpdateIcon from "@mui/icons-material/UpdateRounded";
import { IconButton, ListItemText, MenuItem, Paper, Stack, styled, Tooltip, Typography, useTheme } from "@mui/material";
import { AclNamespaces, AclPermissions, ProcessListWidgetConfig, UpdateWidgetRequestBodyDto, WidgetDto, WidgetType } from "api-shared";
import { TFunction } from "i18next";
import React, { ReactNode, Suspense, useState } from "react";
import { useTranslation } from "react-i18next";
import { useInView } from "react-intersection-observer";
import { useDispatch } from "react-redux";
import BannerLayout from "../../components/BannerLayout";
import DangerMenuItem from "../../components/DangerMenuItem";
import DeleteDialog from "../../components/dialogues/DeleteDialog";
import LoadingAnimation from "../../components/loading/LoadingAnimation";
import MoreActionsMenu from "../../components/MoreActionsMenu";
import { useAddDashboardWidgetMutation, useRemoveDashboardWidgetMutation, useUpdateDashboardWidgetMutation } from "../../domain/dashboards";
import { useUserHasPermissionQuery } from "../../domain/permissions";
import { useAllUsers } from "../../domain/users";
import useConfirmDialog from "../../hooks/useConfirmDialog";
import useDialog from "../../hooks/useDialog";
import useTimezone from "../../hooks/useTimezone";
import { NotificationCustomAction, showNotificationEvent } from "../../infrastructure/notifications";
import { formatUserFromId } from "../../lib/formatters";
import { NotificationType } from "../../lib/notifications";
import { tryTranslate } from "../../lib/translate";
import { translationKeys } from "../../translations/main-translations";
import { FeedbackTranslationKeys } from "../../translations/notification-translations";
import CSVExportDialog from "../measures/preferences/CSVExportDialog";
import ActivityListWidget from "./ActivityListWidget";
import StatusAggregationWidget from "./aggregation/StatusAggregationWidget";
import CommentStreamWidget from "./CommentStreamWidget";
import IdeaListWidget from "./IdeaListWidget";
import IdeaMatrixWidget from "./IdeaMatrixWidget";
import LiveRunUpWidget from "./live-runup/LiveRunUpWidget";
import PotentialSumWidget from "./PotentialSumWidget";
import ProcessListWidget from "./ProcessListWidget";
import ProjectProgressWidget from "./project-progress/ProjectProgressWidget";
import CompletedEffectsWidget from "./reporting/CompletedEffectsWidget";
import CustomBarChartWidget from "./reporting/CustomBarChartWidget";
import FunnelChartWidget from "./reporting/FunnelChartWidget";
import RollingForecastWidget from "./rolling-forecast/RollingForecastWidget";
import TimelineWidget from "./timeline/TimelineWidget";
import { getWidgetId, handleScrollToElement } from "./utils";
import WaterfallWidget from "./waterfall/WaterfallWidget";
import WeeklySavingsRunUpWidget from "./weekly-savings/WeeklySavingsRunUpWidget";
import ProcessWhiteSpotMatrix from "./whitespot/ProcessWhiteSpotMatrix";

export const widgetClasses = {
    dragHandle: "VdWidget-dragHandle",
    actions: "VdWidget-actions",
    hint: "VdWidget-hint",
};

interface IWidgetProps {
    isSelected?: boolean;
    widget: WidgetDto;
    className?: string;
    disabled?: boolean;
    dashboardOwnerId: number;
}

const WidgetRoot = styled(Paper, {
    shouldForwardProp: (prop) => prop !== "active",
})<{
    active?: boolean;
}>(({ theme, active = false }) => ({
    // inherit sizing from parent component (grid layout)
    height: "100%",
    width: "100%",
    borderColor: active ? theme.palette.primary.main : undefined,
    [`& .${widgetClasses.dragHandle}`]: {
        cursor: active ? "grabbing" : "grab",
    },
    [`& .${widgetClasses.actions}`]: {
        opacity: active ? 1 : 0,
        transition: theme.transitions.create("opacity"),
    },
    [`& .${widgetClasses.hint}`]: {
        opacity: active ? 1 : 0,
        transition: theme.transitions.create("opacity"),
    },
    [`&:hover .${widgetClasses.actions}`]: {
        opacity: 1,
    },
    [`&:hover .${widgetClasses.hint}`]: {
        opacity: 1,
    },
}));

const Actions = styled("div")(({ theme }) => ({
    position: "absolute",
    top: theme.spacing(3),
    right: theme.spacing(3),
    backgroundColor: theme.palette.background.paper,
}));

const WidgetTitle = styled(Typography)(({ theme }) => ({
    overflowWrap: "anywhere",
    padding: theme.spacing(3, 3, 2, 3),
    display: "flex",
    alignItems: "center",
}));

const InfoIcon = styled(InfoOutlined)(({ theme }) => ({
    color: theme.palette.primary.main,
    marginLeft: theme.spacing(1),
}));

const HintInfoIcon = styled(InfoRounded)(({ theme }) => ({
    color: theme.palette.primary.main,
    marginLeft: theme.spacing(1),
    cursor: "pointer",
}));

const UpdatedRoundedIcon = styled(UpdateIcon)(({ theme }) => ({
    color: theme.palette.grey[400],
}));

const Banner = styled(Stack)(() => ({
    backgroundColor: "rgba(33, 150, 243, 0.08)",
}));

const BannerText = styled(Typography)(({ theme }) => ({
    // custom color for banner - only used here for now
    color: theme.palette.primary.dark,
}));

export interface IWidgetContentProps {
    isConfigDialogOpen: boolean;
    onConfigDialogClose: () => void;
    openConfigDialog: () => void;
    onConfigSave: (changes: UpdateWidgetRequestBodyDto) => void;
    translate: TFunction;
    widget: WidgetDto;
    disabled?: boolean;
    readOnlyLabel?: ReactNode;
    isInView: boolean;
}

const WidgetComponents: Record<WidgetType, React.ComponentType<IWidgetContentProps>> = {
    [WidgetType.ProcessList]: ProcessListWidget,
    [WidgetType.CompletedEffects]: CompletedEffectsWidget,
    [WidgetType.ActivityList]: ActivityListWidget,
    [WidgetType.CustomBarChart]: CustomBarChartWidget,
    [WidgetType.FunnelChart]: FunnelChartWidget,
    [WidgetType.RollingForecast]: RollingForecastWidget,
    [WidgetType.IdeaList]: IdeaListWidget,
    [WidgetType.IdeaMatrix]: IdeaMatrixWidget,
    [WidgetType.ProcessWhiteSpotMatrix]: ProcessWhiteSpotMatrix,
    [WidgetType.CommentStream]: CommentStreamWidget,
    [WidgetType.LiveRunUp]: LiveRunUpWidget,
    [WidgetType.PotentialKPI]: PotentialSumWidget,
    [WidgetType.StatusAggregation]: StatusAggregationWidget,
    [WidgetType.ProjectProgress]: ProjectProgressWidget,
    [WidgetType.Timeline]: TimelineWidget,
    [WidgetType.WeeklySavingsRunUp]: WeeklySavingsRunUpWidget,
    [WidgetType.Waterfall]: WaterfallWidget,
};
// Add similar config component resolving here

type WidgetHintProps = {
    type: WidgetType;
    description: string;
    updatedAt: Date;
};

const WidgetHint = ({ type, description, updatedAt }: WidgetHintProps) => {
    const { t: translate } = useTranslation();
    const { formatDate } = useTimezone();
    const theme = useTheme();

    return (
        <Tooltip
            title={
                <Stack gap={0.5}>
                    <Typography variant="body2" fontWeight="medium">
                        {translate(type)}
                    </Typography>
                    {description !== "" ? <Typography variant="body2">{tryTranslate(translate, description)}</Typography> : null}
                    <Stack direction="row" gap={0.5}>
                        <UpdatedRoundedIcon fontSize="small" />
                        <Typography variant="body2" color={theme.palette.grey[400]}>
                            {translate(translationKeys.VDLANG_DASHBOARDS_WIDGET_UPDATED_AT, {
                                date: formatDate(updatedAt),
                            })}
                        </Typography>
                    </Stack>
                </Stack>
            }
            placement="top"
        >
            <HintInfoIcon className={widgetClasses.hint} fontSize="small" />
        </Tooltip>
    );
};

const Widget = ({ className, widget, isSelected: isDragging, disabled, dashboardOwnerId }: IWidgetProps) => {
    const updateWidgetMutation = useUpdateDashboardWidgetMutation();
    const removeWidgetMutation = useRemoveDashboardWidgetMutation();
    const addWidgetMutation = useAddDashboardWidgetMutation();
    const { t: translate } = useTranslation();
    const users = useAllUsers();
    const dashboardOwnerName = formatUserFromId(dashboardOwnerId, users, { translate });

    const { ref, inView } = useInView({ triggerOnce: true });

    const readOnlyLabel = disabled ? (
        <Banner direction="row" p={1.5} gap={2} alignItems="center">
            <InfoIcon />
            <BannerText variant="body2">
                {translate(translationKeys.VDLANG_DASHBOARDS_WIDGET_CONFIG_DISABLED_HINT, {
                    displayname: dashboardOwnerName,
                })}
            </BannerText>
        </Banner>
    ) : null;

    const removeWidget = (widget: WidgetDto) => removeWidgetMutation.mutate({ dashboardId: widget.dashboardId, widgetId: widget.id });
    const updateWidget = (changes: UpdateWidgetRequestBodyDto) =>
        updateWidgetMutation.mutate({ dashboardId: widget.dashboardId, widgetId: widget.id, changes });

    const { openConfirmDialog, onConfirm, ...dialogProps } = useConfirmDialog({ onConfirm: removeWidget });

    // manage state here, so action button can open the dialog.
    // Further dialog is managed by specialized widget component
    const settingsDialog = useDialog();

    // The actions are only visible on hovering the widget
    // When opening the menu, the hover is not active anymore, and the actions would hide
    // Track menu open state, so that the visibleOnHoverClassName will only be applied, when menu is not open
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const dispatch = useDispatch();
    const showHint = (widgetId: number) => {
        const widgetName = getWidgetId(widgetId);
        const actions: NotificationCustomAction = {
            label: translationKeys.VDLANG_DASHBOARDS_DUPLICATE_WIDGET_BUTTON_LABEL,
            onClick: () => handleScrollToElement(widgetName),
        };

        dispatch(showNotificationEvent(NotificationType.SUCCESS, FeedbackTranslationKeys.VDLANG_FEEDBACK_WIDGET_DUPLICATED, {}, actions));
    };

    const duplicateWidget = () => {
        addWidgetMutation.mutate(
            {
                dashboardId: widget.dashboardId,
                name: widget.name,
                description: widget.description,
                type: widget.type,
                config: widget.config,
            },
            { onSuccess: (response) => showHint(response.id) },
        );
    };
    const menuActions = [
        <MenuItem
            key="duplicate_widget"
            onClick={(e) => {
                duplicateWidget();
                // stop propapagtion towards grid item
                e.stopPropagation();
            }}
        >
            <ListItemText primary={translate(translationKeys.VDLANG_DASHBOARDS_DUPLICATE_WIDGET_MENU_ITEM_LABEL)} />
        </MenuItem>,
        <DangerMenuItem
            key="remove"
            onClick={(e) => {
                openConfirmDialog(widget);
                // stop propapagtion towards grid item
                e.stopPropagation();
            }}
        >
            <ListItemText primary={translate(translationKeys.VDLANG_DASHBOARDS_REMOVE_WIDGET_MENU_ITEM_LABEL)} />
        </DangerMenuItem>,
    ];

    const csvDialog = useDialog();
    const hasCSVExportPermissionQuery = useUserHasPermissionQuery({
        namespace: AclNamespaces.Api,
        permission: AclPermissions.Export,
        fact: {},
    });

    if (!hasCSVExportPermissionQuery.isSuccess) {
        return <LoadingAnimation />;
    }

    if (hasCSVExportPermissionQuery.data.hasPermission && widget.type === WidgetType.ProcessList) {
        menuActions.unshift(
            <MenuItem
                key="csv_export"
                onClick={(e) => {
                    csvDialog.open();
                    e.stopPropagation();
                }}
            >
                <ListItemText primary={translate(translationKeys.VDLANG_EXPORT_CSV)} />
            </MenuItem>,
        );
    }

    const title = translate(widget.name, { defaultValue: null }) ?? widget.name;

    const WidgetComponent = WidgetComponents[widget.type];

    return (
        <WidgetRoot id={getWidgetId(widget.id)} active={isDragging} className={className} ref={ref}>
            <DeleteDialog
                {...dialogProps}
                onDelete={onConfirm}
                translate={translate}
                hideDescription
                title={translate(translationKeys.VDLANG_DASHBOARDS_REMOVE_WIDGET_CONFIRM_DIALOG_TITLE, { name: title })}
            />
            {hasCSVExportPermissionQuery.data.hasPermission && widget.type === WidgetType.ProcessList && csvDialog.isOpen ? (
                <CSVExportDialog
                    open={csvDialog.isOpen}
                    searchConfig={{
                        filterId: null,
                        filter: (widget.config as ProcessListWidgetConfig).filter,
                        gridSort: (widget.config as ProcessListWidgetConfig).sort,
                        gridOrderBy: (widget.config as ProcessListWidgetConfig).orderBy,
                        scope: (widget.config as ProcessListWidgetConfig).scope,
                        gridColumns: (widget.config as ProcessListWidgetConfig).columns,
                    }}
                    onClose={csvDialog.close}
                />
            ) : null}
            <Actions className={!isMenuOpen ? widgetClasses.actions : undefined}>
                <IconButton
                    size="small"
                    color="primary"
                    onClick={(e) => {
                        settingsDialog.open();
                        e.stopPropagation();
                    }}
                >
                    <TuneIcon />
                </IconButton>
                {!disabled && (
                    <MoreActionsMenu
                        buttonProps={{
                            size: "small",
                            color: "primary",
                        }}
                        onOpen={() => setIsMenuOpen(true)}
                        onClose={() => setIsMenuOpen(false)}
                        actions={menuActions}
                    />
                )}
            </Actions>
            <BannerLayout
                disableElevation
                banner={
                    <WidgetTitle variant="subtitle1" className={disabled ? undefined : widgetClasses.dragHandle}>
                        {title}
                        <WidgetHint type={widget.type} description={widget.description ?? ""} updatedAt={widget.updatedAt} />
                    </WidgetTitle>
                }
            >
                {/* Add suspense so that any suspense queries inside widgets will not bubble up until the global suspense */}
                <Suspense fallback={null}>
                    <WidgetComponent
                        widget={widget}
                        isConfigDialogOpen={settingsDialog.isOpen}
                        onConfigDialogClose={settingsDialog.close}
                        openConfigDialog={settingsDialog.open}
                        translate={translate}
                        onConfigSave={updateWidget}
                        disabled={disabled}
                        readOnlyLabel={readOnlyLabel}
                        isInView={inView}
                    />
                </Suspense>
            </BannerLayout>
        </WidgetRoot>
    );
};

export default Widget;
