import { TextProps, YAxisProps } from "recharts";
import { TickItem } from "recharts/types/util/types";
import { AxisTick } from "./AxisTick";
import { SvgLink } from "./SvgLink";

export type LinkableAxisTickProps = Pick<TextProps, "x" | "y" | "dy" | "transform" | "width" | "textAnchor"> &
    Pick<YAxisProps, "tickFormatter"> & {
        maxCharacters?: number;
        payload?: TickItem;
        isLabelClickable?: (id: number) => boolean;
        onClick?: (id: number) => void;
        isHighlighted?: (id: number) => void;
    };
export const LinkableAxisTick = ({
    x,
    y,
    maxCharacters,
    payload,
    tickFormatter,
    textAnchor = "end",
    isLabelClickable,
    onClick,
    isHighlighted,
    dy,
    transform,
}: LinkableAxisTickProps) => {
    if (payload == null) {
        return null;
    }
    const { value, index = -1 } = payload;
    const baseLabel = tickFormatter?.(value, index) ?? String(value);
    const label = maxCharacters != null && baseLabel.length > maxCharacters ? `${baseLabel.substring(0, maxCharacters)}…` : baseLabel;

    const id = Number(value);
    const isClickable = !Number.isNaN(id) && onClick != null && (isLabelClickable == null || isLabelClickable(id));

    const text = (
        <AxisTick
            x={x}
            y={y}
            dy={dy}
            transform={transform}
            textAnchor={textAnchor}
            fontWeight={isHighlighted?.(value) ? "medium" : undefined}
        >
            {label}
        </AxisTick>
    );

    if (!isClickable) {
        return text;
    }

    return (
        <SvgLink
            underline="always"
            onClick={(e) => {
                e.preventDefault(); // prevent link navigation to #
                return onClick(id);
            }}
            color="textPrimary"
            // Set href so that link role is assigned to a tag even when inside svg context
            // See: https://www.w3.org/TR/svg-aam-1.0/#mapping_role_table
            href="#"
        >
            {text}
        </SvgLink>
    );
};
