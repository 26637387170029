import { Stack } from "@mui/material";
import { EvalFunction, MeasureFieldNames, TimelineWidgetConfig, compileFormula, validateTimelineWidgetConfig } from "api-shared";
import { isEmpty } from "lodash";
import VerticalBarChartSkeleton from "../../../components/loading/VerticalBarChartSkeleton";
import { usePivotFields, useTimelineData } from "../../../domain/reporting";
import EmptyVerticalBarChartIllustration from "../../../static/images/widgets/empty-widget-bar.svg";
import { IWidgetContentProps } from "../Widget";
import WidgetConfigDialog from "../WidgetConfigDialog";
import WidgetNoData from "../WidgetNoData";
import ChartWidgetRoot from "../reporting/ChartWidgetRoot";
import { useFieldOptions } from "../reporting/useFieldOptions";
import TimelineChart from "./TimelineChart";
import TimelineWidgetConfigForm from "./TimelineWidgetConfigForm";
import TimelineWidgetFormulaErrorHint, { FormulaEvaluationError } from "./TimelineWidgetFormulaErrorHint";

type CurvePointsResult = {
    curvePoints: Record<string, number>;
    errors: FormulaEvaluationError[];
};

function generateCurvePointsForWeek(levelFormulas: Record<string, EvalFunction>, week: number): CurvePointsResult {
    const curvePoints: Record<string, number> = {};
    const errors: FormulaEvaluationError[] = [];

    Object.entries(levelFormulas).forEach(([gateTaskConfigId, formula]) => {
        const value = formula({ w: week });
        if (value != null) {
            curvePoints[gateTaskConfigId] = value;
        } else {
            errors.push({ id: +gateTaskConfigId, week });
        }
    });

    return { curvePoints, errors };
}

const TimelineWidget = ({
    widget,
    isConfigDialogOpen,
    onConfigDialogClose,
    onConfigSave,
    translate,
    disabled,
    readOnlyLabel,
    isInView,
}: IWidgetContentProps) => {
    const config = widget.config as TimelineWidgetConfig;

    const pivotFieldsQuery = usePivotFields();
    const stackingGroups =
        useFieldOptions({
            definitions: pivotFieldsQuery.data,
            fieldName: MeasureFieldNames.CurrentGateTaskConfigId,
            // avoid level name being suffixed with process names
            customizeField: (field) => ({ ...field, options: { ...field.options, resolveDuplicates: false } }),
        }) ?? [];

    // inverse the order of gate task groups to display the last gate task ("completed") nearest to the 0-line of the chart
    const orderedGroups = stackingGroups.toReversed();

    const timelineData = useTimelineData({ fiscalYear: config.fiscalYear, timeFrame: config.timeFrame, filter: config.filter }, isInView);

    const curveFormulas = {} as Record<string, EvalFunction>;
    const invalidFormulas = new Set<string>();
    Object.entries(config.curveFormulas).forEach(([k, v]) => {
        const compiled = compileFormula(v);
        if (compiled !== null) {
            curveFormulas[k] = compiled;
        } else {
            invalidFormulas.add(k);
        }
    });

    const curvePointErrors: FormulaEvaluationError[] = [];

    const chartData =
        timelineData.data?.map((week) => {
            const { curvePoints, errors } = generateCurvePointsForWeek(curveFormulas, week.week);
            curvePointErrors.push(...errors);
            return {
                week: week.week,
                carryOver: week.carryOver ?? 0,
                values: week.sums,
                curves: curvePoints,
            };
        }) ?? [];

    const isEmptyData =
        timelineData.isSuccess && timelineData.data.every(({ sums, carryOver }) => isEmpty(sums) && carryOver === undefined);
    const isEmptyChart = isEmptyData && config.target === null && isEmpty(config.curveFormulas);

    return (
        <ChartWidgetRoot>
            <WidgetConfigDialog
                open={isConfigDialogOpen}
                onClose={onConfigDialogClose}
                onSave={onConfigSave}
                translate={translate}
                widget={widget}
                validateConfig={validateTimelineWidgetConfig}
                FormComponent={TimelineWidgetConfigForm}
                noPadding
                disabled={disabled}
                readOnlyLabel={readOnlyLabel}
            />
            {/* Wrapper so that chart claims only remaining space in case alert is visible */}
            <Stack sx={{ height: "100%", overflow: "hidden" }}>
                {!timelineData.isSuccess ? <VerticalBarChartSkeleton /> : null}

                {isEmptyChart ? <WidgetNoData src={EmptyVerticalBarChartIllustration} /> : null}
                {curvePointErrors.length > 0 ? (
                    <TimelineWidgetFormulaErrorHint errors={curvePointErrors} invalidFormulas={invalidFormulas} options={orderedGroups} />
                ) : null}
                {timelineData.isSuccess && !isEmptyChart ? (
                    <TimelineChart
                        target={config.target}
                        data={chartData}
                        stacks={orderedGroups}
                        axisMinValue={config.axisMinValue}
                        axisMaxValue={config.axisMaxValue}
                        timeFrame={config.timeFrame}
                    />
                ) : null}
            </Stack>
        </ChartWidgetRoot>
    );
};

export default TimelineWidget;
