import { styled } from "@mui/material";

const ChartWidgetRoot = styled("div")(({ theme }) => ({
    width: "100%",
    height: "100%",
    padding: theme.spacing(0, 3, 3),
    // Chart will adapt to available space automatically, but with a small delay
    // tooltips and fast resizing may introduce horizontal scrolling, so disable it
    overflowX: "hidden",
}));

export default ChartWidgetRoot;
