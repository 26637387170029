import { Typography } from "@mui/material";
import { TickItem } from "recharts/types/util/types";

interface CustomYAxisTickProps {
    x?: number;
    y?: number;
    payload?: TickItem;
    tickFormatter?: (value: any, index: number) => string;
}

const CustomYAxisTick = ({ x = 0, y = 0, payload, tickFormatter }: CustomYAxisTickProps) => {
    if (payload == null) {
        return null;
    }
    const { value, index = -1 } = payload;
    return (
        <g transform={`translate(${x},${Number(y) + 4})`}>
            <Typography component="text" x={0} y={0} textAnchor="end">
                {tickFormatter != null ? tickFormatter(value, index) : value}
            </Typography>
        </g>
    );
};

export default CustomYAxisTick;
