import { styled, Typography } from "@mui/material";
import {
    AggregationMethod,
    CurrentGateType,
    FunnelChartWidgetConfig,
    MeasureFieldNames,
    PivotMetric,
    validateFunnelChartWidgetConfig,
} from "api-shared";
import { useState } from "react";
import FunnelChartSkeleton from "../../../components/loading/FunnelChartSkeleton";
import { useMeasureConfigs } from "../../../domain/measure-config";
import { usePivotData, usePivotFields } from "../../../domain/reporting";
import { useFilterValidation } from "../../../hooks/useFilterValidation";
import EmptyVerticalBarChartIllustration from "../../../static/images/widgets/empty-widget-bar.svg";
import { translationKeys } from "../../../translations/main-translations";
import FunnelStackChart from "../charts/FunnelStackChart";
import { useDashboardColors } from "../charts/hooks";
import { getBarSegments } from "../charts/utils";
import { IWidgetContentProps } from "../Widget";
import WidgetConfigDialog from "../WidgetConfigDialog";
import WidgetError from "../WidgetError";
import WidgetNoData from "../WidgetNoData";
import ChartWidgetRoot from "./ChartWidgetRoot";
import DrilldownDialog from "./DrilldownDialog";
import { Drilldown } from "./DrilldownTable";
import FunnelChartWidgetConfigForm from "./FunnelChartWidgetConfigForm";
import { useFieldOptions } from "./useFieldOptions";

const NoPotentialContainer = styled("div")({
    height: "100%",
    display: "grid",
    placeContent: "center",
});

const NoPotentialMessage = styled(Typography)(({ theme }) => ({
    color: theme.palette.text.disabled,
}));

const FunnelChartWidget = ({
    widget,
    isConfigDialogOpen,
    onConfigDialogClose,
    onConfigSave,
    translate,
    disabled = false,
    readOnlyLabel,
    isInView,
}: IWidgetContentProps) => {
    const { filter, scope } = widget.config as FunnelChartWidgetConfig;

    const dashboardColors = useDashboardColors();

    const [drillDownData, setDrillDownData] = useState<Drilldown>();

    // Check that filter is allowed
    const { validate } = useFilterValidation(isInView);
    const hasValidFilter = Boolean(validate?.(filter));

    const pivotQuery = usePivotData({
        aggregation: AggregationMethod.Sum,
        metric: PivotMetric.Effect,
        fields: [MeasureFieldNames.CurrentGateTaskConfigId],
        filter,
        enabled: isInView && hasValidFilter,
        scope,
    });
    const hasErrorState = !pivotQuery.isFetching && validate !== undefined && !hasValidFilter;

    const fieldsQuery = usePivotFields(isInView);
    const fieldOptions = useFieldOptions({ definitions: fieldsQuery.data, fieldName: MeasureFieldNames.CurrentGateTaskConfigId }) ?? [];

    const pivotData = pivotQuery.data ?? [];
    const hasNoPotential = !pivotQuery.data?.some((item) => item.value > 0);
    const hasEmptyData = !pivotQuery.data?.length;

    // fill data with empty
    const measureConfigs = useMeasureConfigs();
    const measureConfigFilter = filter.find((f) => f.field === MeasureFieldNames.MeasureConfigId);

    const selectedMeasureConfigs = measureConfigs.filter((mc) => measureConfigFilter?.values.includes(mc.id));

    const gateTaskConfigs = selectedMeasureConfigs?.flatMap((config) => config.gateTaskConfigs);
    const keysInUse = new Set(gateTaskConfigs.map((gtc) => String(gtc.id)));
    if (pivotData.find((data) => data.fields.currentGateTaskConfigId === CurrentGateType.GATE_CLOSED)) {
        keysInUse.add(String(CurrentGateType.GATE_CLOSED));
    }
    const bars = getBarSegments(fieldOptions.toReversed(), dashboardColors, keysInUse);

    // only show bars of the selected measure configs
    const visibleBars = bars.filter((segment) => keysInUse.has(segment.key)).toReversed();

    function openDrillDown(key: string): void {
        const firstPivotValues = [key];

        const data = { [MeasureFieldNames.CurrentGateTaskConfigId]: firstPivotValues };
        return setDrillDownData(data);
    }

    return (
        <ChartWidgetRoot>
            <WidgetConfigDialog
                open={isConfigDialogOpen}
                onClose={onConfigDialogClose}
                onSave={onConfigSave}
                translate={translate}
                widget={widget}
                validateConfig={validateFunnelChartWidgetConfig}
                FormComponent={FunnelChartWidgetConfigForm}
                noPadding
                disabled={disabled}
                readOnlyLabel={readOnlyLabel}
            />
            {drillDownData !== undefined && (
                <DrilldownDialog
                    open
                    onClose={() => setDrillDownData(undefined)}
                    dataKey={`widget${widget.id}`}
                    drilldown={drillDownData}
                    filter={filter}
                    scope={scope}
                />
            )}
            {!pivotQuery.isSuccess && !pivotQuery.isError ? <FunnelChartSkeleton /> : null}
            {pivotQuery.isError || hasErrorState ? <WidgetError /> : null}
            {pivotQuery.isSuccess && hasEmptyData ? <WidgetNoData src={EmptyVerticalBarChartIllustration} /> : null}

            {pivotQuery.isSuccess &&
                !hasEmptyData &&
                (hasNoPotential ? (
                    <NoPotentialContainer>
                        <NoPotentialMessage variant="body2">
                            {translate(translationKeys.VDLANG_DASHBOARDS_FUNNEL_CHART_WIDGET_NO_POTENTIAL_MESSAGE)}
                        </NoPotentialMessage>
                    </NoPotentialContainer>
                ) : (
                    <FunnelStackChart
                        onBarClick={openDrillDown}
                        bars={visibleBars.map(({ key, label, color }) => ({
                            title: label,
                            effect: pivotData.find((item) => item.fields[MeasureFieldNames.CurrentGateTaskConfigId] === +key)?.value ?? 0,
                            key,
                            color,
                        }))}
                    />
                ))}
        </ChartWidgetRoot>
    );
};

export default FunnelChartWidget;
