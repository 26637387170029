import { styled } from "@mui/material";

// This component can be used to structure forms in a more accessible way into different sections
const Fieldset = styled("fieldset")({
    // reset default fieldset browser styles
    // by default, a fieldset is rendered with a border and an inset label
    border: 0,
    margin: 0,
    padding: 0,
    "& > legend": {
        paddingLeft: 0,
        paddingRight: 0,
    },
});

export default Fieldset;
