import { styled, TextField } from "@mui/material";
import { AclNamespaces, AclPermissions, CommentParentType, formatUserName, validateCommentStreamWidgetConfig } from "api-shared";
import { orderBy } from "lodash";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { OnChangeValue } from "react-select";
import { z } from "zod";
import Form from "../../components/Form";
import Select from "../../components/input/select/Select";
import { Option } from "../../components/input/select/types";
import { useUserHasAccessPermissionQuery, useUserHasPermissionQuery } from "../../domain/permissions";
import { useCurrentUser, useNonDeletedUsers } from "../../domain/users";
import { translationKeys } from "../../translations/main-translations";
import { IWidgetConfigFormProps } from "./WidgetConfigDialog";
import WidgetConfigTab from "./WidgetConfigTab";
import WidgetConfigTabs from "./WidgetConfigTabs";
import WidgetDescriptionField from "./WidgetDescriptionField";

const WidgetConfigTabContainer = styled("div")(({ theme }) => ({
    padding: theme.spacing(3),
    minHeight: theme.spacing(66),
}));

const CommentStreamWidgetConfigForm = ({
    config,
    name,
    description,
    onNameChange,
    onDescriptionChange,
    onConfigChange,
    onSubmit,
    disabled,
}: IWidgetConfigFormProps) => {
    const [openTab, setOpenTab] = useState(0);
    const { t } = useTranslation();
    const entityOptions = [
        { label: t(translationKeys.VDLANG_PROCESSES), value: CommentParentType.Measures },
        { label: t(translationKeys.VDLANG_ACTIVITIES), value: CommentParentType.SubTasks },
    ];
    const currentUser = useCurrentUser();
    const hasIdeaAccessPermissionQuery = useUserHasAccessPermissionQuery(AclNamespaces.Idea);
    const hasPersonFilterPermissionQuery = useUserHasPermissionQuery({
        namespace: AclNamespaces.User,
        permission: AclPermissions.Filter,
        fact: {},
    });
    const users = useNonDeletedUsers();
    const personOptions = [{ label: formatUserName(currentUser), value: currentUser?.id ?? -1 }];

    if (!hasIdeaAccessPermissionQuery.isSuccess || !hasPersonFilterPermissionQuery.isSuccess) {
        return null;
    }

    if (hasIdeaAccessPermissionQuery.data.hasPermission) {
        entityOptions.push({ label: t(translationKeys.VDLANG_OPPORTUNITIES), value: CommentParentType.Ideas });
    }

    if (hasPersonFilterPermissionQuery.data.hasPermission) {
        personOptions.push(
            ...users.filter((user) => user.id !== currentUser?.id).map((user) => ({ label: formatUserName(user), value: user.id })),
        );
    }

    if (!validateCommentStreamWidgetConfig(config)) {
        return null;
    }

    const handleEntityChange = (newValue: OnChangeValue<Option<string>, true>) => {
        const entities = z.array(z.nativeEnum(CommentParentType)).safeParse(newValue.map(({ value }) => value));
        if (entities.success) {
            onConfigChange({ ...config, entities: entities.data });
        }
    };

    const handleCommentedByChange = (newValue: OnChangeValue<Option<number>, true>) => {
        const values = newValue.map((option) => option.value);
        onConfigChange({ ...config, commentedBy: values });
    };

    const handleResponsiblePerson = (newValue: OnChangeValue<Option<number>, true>) => {
        const values = newValue.map((option) => option.value);
        onConfigChange({ ...config, responsiblePerson: values });
    };

    return (
        <Form onSubmit={onSubmit}>
            <WidgetConfigTabs value={openTab} onChange={(event, newValue) => setOpenTab(newValue)}>
                <WidgetConfigTab label={t(translationKeys.VDLANG_DASHBOARDS_COMMENT_STREAM_WIDGET_CONFIG_TAB_GENERAL)} />
                <WidgetConfigTab label={t(translationKeys.VDLANG_DASHBOARDS_COMMENT_STREAM_WIDGET_CONFIG_TAB_DESCRIPTION)} />
                <WidgetConfigTab label={t(translationKeys.VDLANG_DASHBOARDS_COMMENT_STREAM_WIDGET_CONFIG_TAB_FILTER)} />
            </WidgetConfigTabs>
            {openTab === 0 ? (
                <WidgetConfigTabContainer>
                    <TextField fullWidth value={name} onChange={(e) => onNameChange(e.target.value)} label="Name" disabled={disabled} />
                </WidgetConfigTabContainer>
            ) : null}
            {openTab === 1 ? (
                <WidgetConfigTabContainer>
                    <WidgetDescriptionField
                        description={description}
                        onDescriptionChange={onDescriptionChange}
                        label={t(translationKeys.VDLANG_DASHBOARDS_COMMENT_STREAM_WIDGET_CONFIG_TAB_DESCRIPTION)}
                        disabled={disabled}
                    />
                </WidgetConfigTabContainer>
            ) : null}
            {openTab === 2 ? (
                <WidgetConfigTabContainer>
                    <Select
                        value={entityOptions.filter((option) => config.entities.includes(option.value))}
                        isMulti
                        isClearable
                        label={t(translationKeys.VDLANG_DASHBOARDS_COMMENT_STREAM_FILTER_ENTITY)}
                        fullWidth
                        margin="none"
                        options={orderBy(entityOptions, ["label"], ["asc"])}
                        onChange={handleEntityChange}
                        isDisabled={disabled}
                        menuPortalTarget={document.body}
                    />
                    <Select
                        value={personOptions.filter((option) => config.commentedBy.includes(option.value))}
                        isMulti
                        isClearable
                        label={t(translationKeys.VDLANG_DASHBOARDS_COMMENT_STREAM_FILTER_COMMENTED_BY)}
                        fullWidth
                        options={personOptions}
                        onChange={handleCommentedByChange}
                        isDisabled={disabled}
                        menuPortalTarget={document.body}
                    />
                    <Select
                        value={personOptions.filter((option) => config.responsiblePerson.includes(option.value))}
                        isMulti
                        isClearable
                        label={t(translationKeys.VDLANG_DASHBOARDS_COMMENT_STREAM_FILTER_RESPONSIBLE_PERSON)}
                        fullWidth
                        options={personOptions}
                        onChange={handleResponsiblePerson}
                        isDisabled={disabled}
                        menuPortalTarget={document.body}
                    />
                </WidgetConfigTabContainer>
            ) : null}
        </Form>
    );
};

export default CommentStreamWidgetConfigForm;
