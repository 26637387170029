import { Divider, List, ListItem, Stack, styled, Typography } from "@mui/material";
import { CommentParentType, CommentStreamWidgetConfig } from "api-shared";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import CommentList from "../../components/comment/CommentList";
import { CommentParentType as CommentDomainParentType, useDeleteComment, useUpdateComment } from "../../domain/comment";
import { CommentStreamKeys, useCommentStream } from "../../domain/comment-stream";
import useInfiniteQueryPageReset from "../../hooks/useInfiniteQueryPageReset";
import useInfiniteScrollingState from "../../hooks/useInfiniteScrollingState";
import { RouteFor } from "../../lib/routes";
import CommentStreamEmpty from "../../static/images/widgets/commentstream-empty.svg";
import { translationKeys } from "../../translations/main-translations";
import CommentStreamItemSkeleton from "./CommentStreamItemSkeleton";

interface CommentStreamWidgetInfiniteListProps {
    rootRef: React.Ref<unknown>;
    config: CommentStreamWidgetConfig;
    enableQueries: boolean;
}

const ParentLink = styled(Link)(({ theme }) => ({
    color: theme.palette.primary.main,
    textDecoration: "none",
    "&:hover": {
        textDecoration: "underline",
    },
}));

const CommentStreamInfiniteList = ({ rootRef, config, enableQueries = true }: CommentStreamWidgetInfiniteListProps) => {
    const { t } = useTranslation();
    const commentStreamQuery = useCommentStream(
        {
            entities: config.entities,
            commentedBy: config.commentedBy,
            responsiblePerson: config.responsiblePerson,
        },
        enableQueries,
    );
    const hasNextPage =
        commentStreamQuery.isSuccess &&
        commentStreamQuery.data.pages.length > 0 &&
        commentStreamQuery.data.pages[commentStreamQuery.data.pages.length - 1].hasNextPage;
    const updateCommentMutation = useUpdateComment();
    const deleteCommentMutation = useDeleteComment();

    function mapParentType(commentParentType: CommentParentType) {
        switch (commentParentType) {
            case CommentParentType.Ideas:
                return CommentDomainParentType.IDEA;
            case CommentParentType.Measures:
                return CommentDomainParentType.MEASURE;
            case CommentParentType.SubTasks:
                return CommentDomainParentType.SUBTASK;
            default:
                return null;
        }
    }

    function updateComment(commentParentType: CommentParentType, comment: string, commentId: number) {
        const parentType = mapParentType(commentParentType);
        if (parentType !== null) {
            return updateCommentMutation.mutate({ parentType, comment, commentId });
        }
    }

    function deleteComment(commentParentType: CommentParentType, commentId: number, parentId: number) {
        const parentType = mapParentType(commentParentType);
        if (parentType !== null) {
            return deleteCommentMutation.mutate({ parentType, commentId, parentId });
        }
    }

    function loadNextPage() {
        if (hasNextPage && !commentStreamQuery.isFetchingNextPage && commentStreamQuery.hasNextPage && commentStreamQuery.isSuccess) {
            const lastPage = commentStreamQuery.data.pages[commentStreamQuery.data.pages.length - 1];
            const nextDate = lastPage.nextDate ? new Date(lastPage.nextDate).toISOString() : null;

            commentStreamQuery.fetchNextPage({
                pageParam: nextDate,
            });
        }
    }

    useInfiniteQueryPageReset(CommentStreamKeys.all());

    const { guardRef } = useInfiniteScrollingState({
        isFetching: commentStreamQuery.isFetching,
        hasNextPage,
        loadNext: loadNextPage,
        parentRef: rootRef,
    });

    const comments = commentStreamQuery?.data?.pages.flatMap((page) => page.items) ?? [];
    const mappedComments = comments.map((comment) => {
        let link = "";
        let entityName = "";
        switch (comment.parent.tableName) {
            case CommentParentType.Ideas:
                link = RouteFor.opportunities.forId(comment.parent.id);
                entityName = t(translationKeys.VDLANG_IDEAS_IDEA);
                break;
            case CommentParentType.Measures:
                link = RouteFor.measure.forId(comment.parent.id);
                entityName = t(comment.parent.measureConfigName ?? translationKeys.VDLANG_PROCESS);
                break;
            case CommentParentType.SubTasks:
                link = RouteFor.activity(comment.parent.id);
                entityName = t(translationKeys.VDLANG_ACTIVITY);
                break;
        }
        return {
            ...comment,
            link,
            entityName,
            parentId: comment.parent.id,
            status: null,
        };
    });

    if (mappedComments.length === 0) {
        return (
            <List>
                <Stack component="li" spacing={2} alignItems="center" direction="column" justifyContent="center">
                    <Stack>
                        <img src={CommentStreamEmpty} style={{ width: "100%", maxWidth: "100%", maxHeight: "100%" }} alt="" />
                    </Stack>
                    <Stack>
                        <Typography align="center" color="textSecondary">
                            {t(translationKeys.VDLANG_DASHBOARDS_WIDGET_NO_DATA)}
                        </Typography>
                    </Stack>
                </Stack>
                {hasNextPage && <CommentStreamItemSkeleton ref={guardRef} />}
            </List>
        );
    }

    return (
        <List>
            {mappedComments.map((comment, index) => (
                <ListItem key={comment.comment} dense>
                    <Stack width="100%">
                        {index > 0 && <Divider sx={{ mb: 2 }} />}
                        <Typography>
                            <Trans
                                i18nKey={translationKeys.VDLANG_DASHBOARDS_COMMENT_STREAM_TITLE_PREFIX}
                                values={{ type: comment.entityName }}
                            />
                            <ParentLink to={comment.link}>
                                ID: {comment.parent.displayId} {comment.parent.title}
                            </ParentLink>
                            {t(translationKeys.VDLANG_DASHBOARDS_COMMENT_STREAM_TITLE_SUFFIX)}
                        </Typography>
                        <CommentList
                            sx={{ pb: 0, mb: 0 }}
                            id={String(comment.id)}
                            parentId={comment.parent.id}
                            comments={[comment]}
                            deleteComment={(commentId) => deleteComment(comment.parent.tableName, commentId, comment.parent.id)}
                            updateComment={(commentId, commentText) => updateComment(comment.parent.tableName, commentText, commentId)}
                        />
                    </Stack>
                </ListItem>
            ))}
            {hasNextPage && <CommentStreamItemSkeleton ref={guardRef} />}
        </List>
    );
};

export default CommentStreamInfiniteList;
