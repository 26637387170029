import { useTheme } from "@mui/material";
import { DEFAULT_DASHBOARD_COLORS } from "api-shared";
import moment from "moment-timezone";
import { LegendProps, TooltipProps, XAxisProps, YAxisProps } from "recharts";
import { NameType } from "recharts/types/component/DefaultTooltipContent";
import { useCurrentClient } from "../../../domain/client";
import useCurrency from "../../../hooks/useCurrency";
import { stripPxSuffix } from "../../../styles/utils";
import AngledXAxisTick from "./AngledXAxisTick";
import ChartTooltip, { IChartToolTipProps } from "./ChartTooltip";
import CustomYAxisTick from "./CustomYAxisTick";
import LegendItem from "./LegendItem";
import { LinkableAxisTick } from "./LinkableAxisTick";

export function useLegendProps(spacing?: number) {
    const theme = useTheme();
    return {
        align: "left",
        verticalAlign: "top",
        iconSize: 12,
        formatter: (value) => <LegendItem>{value}</LegendItem>,
        wrapperStyle: {
            left: 0,
            paddingBottom: theme.spacing(spacing ?? 2),
        },
    } satisfies LegendProps;
}

export function useCurrencyYAxisProps(target?: number, axisMinValue?: number | null, axisMaxValue?: number | null) {
    const theme = useTheme();
    const { formatCurrencyShort } = useCurrency();
    return {
        axisLine: false,
        tickLine: false,
        domain: [
            (dataMin: number) => Math.min(dataMin, target ?? 0, axisMinValue ?? 0, 0),
            (dataMax: number) => Math.max(dataMax, target ?? 0, axisMaxValue ?? 0, 0),
        ],
        tick: <CustomYAxisTick />,
        tickFormatter: (value) => formatCurrencyShort(value) ?? value,
        width: stripPxSuffix(theme.spacing(15)),
        scale: "linear",
        tickCount: 3,
    } satisfies YAxisProps;
}

export function useMonthXAxisProps() {
    return {
        height: 74,
        tick: <AngledXAxisTick isHighlighted={(value) => moment(value).isSame(moment(), "month")} />,
        tickFormatter: (value) => moment(value).format("MMM YYYY"),
        interval: 0,
    } satisfies XAxisProps;
}

export function useWeekXAxisProps() {
    return {
        tick: <LinkableAxisTick isHighlighted={(value) => moment().week() === value} />,
        tickLine: false,
        tickMargin: 5,
        tickFormatter: (value) => (Number(value) % 2 === 0 ? value : ""),
        interval: 0,
    } satisfies XAxisProps;
}

export function useAngledXAxisPropsWithMaxCharacters(maxCharacters = 20) {
    return {
        height: 120,
        tick: <AngledXAxisTick maxCharacters={maxCharacters} />,
        interval: 0,
    } satisfies XAxisProps;
}

export const useDashboardColors = () => {
    const client = useCurrentClient();
    return client.dashboardColors ?? DEFAULT_DASHBOARD_COLORS;
};

export function useTooltipProps(contentProps: Partial<IChartToolTipProps> = {}) {
    return {
        content: <ChartTooltip chartHeight={1} {...contentProps} />,
        offset: 0,
        isAnimationActive: false,
    } satisfies TooltipProps<string | number, NameType>;
}
