import { Grid, Typography } from "@mui/material";
import { RollingForecastWidgetConfig } from "api-shared";
import { TFunction } from "i18next";
import { translationKeys } from "../../../translations/main-translations";
import TargetConfigurationColumn from "./TargetConfigurationColumn";
import { generatePartitionedMonths } from "./utils";

type TargetConfigurationProps = {
    startDate: string;
    endDate: string;
    onTargetChange: (id: string, newValue: number) => void;
    targetValues: RollingForecastWidgetConfig["targetValues"];
    translate: TFunction;
    disabled?: boolean;
};

function TargetConfiguration({
    startDate,
    endDate,
    onTargetChange,
    targetValues,
    translate,
    disabled,
}: Readonly<TargetConfigurationProps>) {
    // order items in two column layout: First half of items in the left column, remaining items in the right column
    const partitionedMonths = generatePartitionedMonths(startDate, endDate);

    return (
        <>
            <Typography variant="subtitle1" sx={{ pb: 1 }}>
                {translate(translationKeys.VDLANG_DASHBOARDS_ROLLING_FORECAST_CONFIG_TARGET_HEADING)}
            </Typography>
            <Grid container spacing={{ xs: 1, md: 3 }}>
                {partitionedMonths.map((months, index) => (
                    <Grid key={index} item xs={12} md={6}>
                        <TargetConfigurationColumn
                            months={months}
                            targetValues={targetValues}
                            onChange={onTargetChange}
                            disabled={disabled}
                        />
                    </Grid>
                ))}
            </Grid>
        </>
    );
}

export default TargetConfiguration;
