import { Grid, Stack, styled, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { LegendLayout } from "api-shared";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { LegendProps } from "recharts";
import ShowMore from "../../../components/ShowMore.tsx";
import { translationKeys } from "../../../translations/main-translations.ts";

const SimpleCircle = styled("div", {
    shouldForwardProp: (name) =>
        name !== "color" && name !== "size" && name !== "borderDashed" && name !== "borderColor" && name !== "borderWidth",
})<{
    color: string | undefined;
    size: string | number;
    borderDashed?: boolean;
    borderColor?: string;
    borderWidth?: number;
}>(({ color, size, borderColor, borderDashed = false, borderWidth = 1 }) => ({
    borderRadius: "50%",
    width: size,
    height: size,
    background: color,
    ...(borderColor && { border: `${borderWidth}px ${borderDashed ? "dashed" : "solid"} ${borderColor}` }),
}));

type ColorSymbolProps = Pick<React.SVGProps<SVGSVGElement>, "color">;

const ColorLineDashed = ({ color }: ColorSymbolProps) => {
    return (
        <svg width="12" height="12" viewBox="0 0 12 1" fill="none">
            <line x1="0.5" y1="0.5" x2="11.5" y2="0.499999" stroke={color} strokeWidth={2} strokeLinecap="square" strokeDasharray="4 2" />
            <circle x1="5.5" y1="0.5" x2="11.5" y2="0.499999" fill={color} cx="5.5" cy="0.5" r="2.75" />
            <circle x1="5.5" y1="0.5" x2="11.5" y2="0.499999" fill="white" cx="5.5" cy="0.5" r="1.25" />
        </svg>
    );
};

interface ExtendableCircleLegendProps extends LegendProps {
    legendLayout?: LegendLayout;
    deltaTimestamp?: string | null;
    hiddenStacks?: string[];
}

// Shorten legend if larger than 2 lines
// 20px height per line + 4px vertical padding per line
const COLLAPSED_HEIGHT = 2 * (20 + 4);

// Provide a minimal, circle-symbol only re-implementation of recharts DefaultLegendContent, which is not exported properly (yet)
// FIXME: With next recharts release, it will be exported so re-use DefaultLegentContent then
// See: https://github.com/recharts/recharts/issues/3288
// Additionally, render any children above the actual legend, so it is possible to place any content above the legend without introducing
// scrollbars
export const ExtendableCircleLegend = ({
    payload,
    children,
    legendLayout,
    deltaTimestamp,
    hiddenStacks,
    onBBoxUpdate,
}: ExtendableCircleLegendProps) => {
    const { t } = useTranslation();
    const legendContainerRef = useRef<HTMLDivElement>(null);
    const contentRef = useRef(null);
    if (payload == null) {
        return null;
    }

    const sortedPayload = legendLayout === LegendLayout.Reversed ? payload.toReversed() : payload;

    function onToggled(domRect: DOMRect) {
        // The passed-in domRect may not always provide accurate dimensions. This is why we use the dimensions from
        // legendContainerRef. This way we can accurately control where it's placed and how it looks based on the real
        // space the legendContainerRef takes
        const rect = legendContainerRef.current?.getBoundingClientRect() ?? domRect;
        return onBBoxUpdate?.(rect);
    }

    return (
        <Stack ref={legendContainerRef} useFlexGap spacing={2} alignItems="flex-start" paddingBottom={2}>
            {children}
            <Stack justifyContent="flex-start" alignItems="flex-end" direction="row" spacing={1}>
                <ShowMore height={COLLAPSED_HEIGHT} translate={t} contentRef={contentRef} onToggled={onToggled}>
                    <Grid
                        container
                        columnSpacing={1}
                        rowSpacing={0.5}
                        component="ol"
                        padding={0}
                        marginBottom={0}
                        sx={{ listStyle: "none" }}
                        ref={contentRef}
                    >
                        {deltaTimestamp != null ? (
                            <Grid item component="li">
                                <Stack spacing={0.5} direction="row" alignItems="center">
                                    <SimpleCircle
                                        color="linear-gradient(90deg, rgba(255, 0, 0, 0.16) 50%, rgba(76,175,80,0.1) 50%)"
                                        size="0.75em"
                                        borderColor={grey[300]}
                                        borderWidth={1}
                                    />
                                    <Typography lineHeight="1.25rem">
                                        {t(translationKeys.VDLANG_DASHBOARDS_REFERENCE_VALUES_FORM_TIMESTAMP_LEGEND, {
                                            timestamp: deltaTimestamp,
                                        })}
                                    </Typography>
                                </Stack>
                            </Grid>
                        ) : null}
                        {sortedPayload
                            .filter((item) => !hiddenStacks?.includes(String(item.dataKey)))
                            .map((item, index) => {
                                const payload = item.payload;

                                return (
                                    <Grid item key={`${item.value}_${index}`} component="li">
                                        <Stack spacing={0.5} direction="row" alignItems="center">
                                            {item.type === "line" ? (
                                                <ColorLineDashed color={item.color} />
                                            ) : (
                                                <SimpleCircle
                                                    color={item.color}
                                                    size="0.75em"
                                                    borderDashed={payload?.strokeDasharray !== undefined}
                                                    borderColor={
                                                        payload !== undefined && "stroke" in payload ? String(payload.stroke) : undefined
                                                    }
                                                    borderWidth={
                                                        payload !== undefined && "strokeWidth" in payload && payload.strokeWidth != null
                                                            ? 1
                                                            : undefined
                                                    }
                                                />
                                            )}
                                            <Typography lineHeight="1.25rem">{item.value}</Typography>
                                        </Stack>
                                    </Grid>
                                );
                            })}
                    </Grid>
                </ShowMore>
            </Stack>
        </Stack>
    );
};
