import { TranslateFunction } from "api-shared";
import moment from "moment-timezone";
import { useTranslation } from "react-i18next";
import { formatFiscalYear } from "../../../lib/fiscal-units";
import Select from "./Select";
import { ISelectProps, Option } from "./types";

const getFiscalYearOptions = (fiscalYearStartMonth: number, yearsBefore: number, yearsAfter: number, t: TranslateFunction) => {
    const currentFiscalYear = moment.utc().month(fiscalYearStartMonth).startOf("month");

    if (moment.utc().isBefore(currentFiscalYear)) {
        currentFiscalYear.subtract(1, "year");
    }

    const firstFiscalYear = currentFiscalYear.clone().subtract(yearsBefore, "years");
    return [...Array(yearsBefore + 1 + yearsAfter).keys()]
        .map((index) => firstFiscalYear.clone().add(index, "years"))
        .map((fiscalYearStart) => ({
            label: `${t("fiscal_year")} ${formatFiscalYear(fiscalYearStart)}`,
            value: fiscalYearStart.year(),
        }));
};

interface IFiscalYearSelectProps extends Omit<ISelectProps<Option<number>, false>, "onChange" | "value"> {
    value: number | null;
    onChange?: (value: number | null) => void;
    fiscalYearStartMonth: number;
    yearsBefore?: number;
    yearsAfter?: number;
}

export const FiscalYearSelect = (props: IFiscalYearSelectProps) => {
    const { value, onChange, fiscalYearStartMonth, yearsBefore = 0, yearsAfter = 0, ...selectProps } = props;
    const { t } = useTranslation();

    const options = getFiscalYearOptions(fiscalYearStartMonth, yearsBefore, yearsAfter, t);
    const selectedOption = options.find((option) => option.value === value);

    return (
        <Select
            value={selectedOption}
            options={options}
            onChange={(option) => {
                onChange?.(option?.value ?? null);
            }}
            {...selectProps}
        />
    );
};
