import { useState } from "react";

interface IUseConfirmDialogProps<T> {
    onConfirm: (item: T) => void;
}

function useConfirmDialog<T>({ onConfirm }: IUseConfirmDialogProps<T>) {
    const [itemToConfirm, setItemToConfirm] = useState<T | null>(null);
    const close = () => setItemToConfirm(null);
    return {
        open: itemToConfirm !== null,
        onClose: close,
        openConfirmDialog: setItemToConfirm,
        onConfirm: () => itemToConfirm !== null && onConfirm(itemToConfirm),
    };
}

export default useConfirmDialog;
