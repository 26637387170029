import { Chip, chipClasses, styled } from "@mui/material";
import { blueGrey } from "@mui/material/colors";

const TargetChip = styled(Chip)(({ theme }) => ({
    order: 1,
    margin: "0 auto",
    transform: "translateX(65px)",
    borderRadius: theme.shape.borderRadius,
    [`& > .${chipClasses.label}`]: {
        ...theme.typography.caption,
    },
    backgroundColor: blueGrey[800],
    color: theme.palette.getContrastText(blueGrey[800]),
    boxShadow: theme.shadows[3],
}));

export default TargetChip;
