import { blueGrey, grey } from "@mui/material/colors";
import { LegendLayout, TooltipLayout } from "api-shared";
import { sortBy } from "lodash";
import moment from "moment-timezone";
import { useTranslation } from "react-i18next";
import { Bar, CartesianGrid, Cell, ComposedChart, Legend, Line, ReferenceLine, Tooltip, XAxis, YAxis } from "recharts";
import ResponsiveContainer from "../../../components/ResponsiveContainer";
import { Option } from "../../../components/input/select/types";
import useCurrency from "../../../hooks/useCurrency";
import { translationKeys } from "../../../translations/main-translations";
import ShortNoCodeCurrencyLabelList from "../charts/ShortNoCodeCurrencyLabelList";
import TargetChip from "../charts/TargetChip";
import { useCurrencyYAxisProps, useDashboardColors, useLegendProps, useMonthXAxisProps, useTooltipProps } from "../charts/hooks";
import { getBarSegments } from "../charts/utils";
import { CalculatedStackKeys } from "../utils";
import { ExtendableCircleLegend } from "./ExtendableCircleLegend";

interface IWidgetBarChartProps {
    data: { [key: string]: string | number; snapshot: string }[];
    xAxis: string;
    target?: number;
    showSums?: boolean;
    groups: Option<string>[];
    axisMinValue: number | null;
    axisMaxValue: number | null;
}

const WidgetBarChart = (props: IWidgetBarChartProps) => {
    const { data, xAxis, target, groups, showSums, axisMinValue, axisMaxValue } = props;
    const colors = useDashboardColors();

    const { formatCurrency, formatCurrencyShort } = useCurrency();
    const { t } = useTranslation();

    const currencyYAxisProps = useCurrencyYAxisProps(target, axisMinValue, axisMaxValue);
    const monthXAxisProps = useMonthXAxisProps();
    const legendProps = useLegendProps(showSums ? 2 : undefined);
    const tooltipProps = useTooltipProps({ tooltipLayout: TooltipLayout.Reversed });

    const barKeysInUse = new Set<string>(data.flatMap(Object.keys));
    barKeysInUse.delete(xAxis);

    const hasSum = barKeysInUse.has("sum");
    barKeysInUse.delete("sum");

    const barSegments = getBarSegments(groups, colors, barKeysInUse);

    const sortedData = sortBy(data, "snapshot");
    const liveDataIndex = sortedData.findIndex((dataItem) => moment().isSame(dataItem.snapshot, "month"));
    const isYearInThePast = sortedData.every((dataItem) => moment(dataItem.snapshot).isBefore(moment(), "month"));
    // If liveDataIndex >=0  the current year is display with historical data and forecasted data
    const sortedDataWithSumSplit = sortedData.map((dataSet, index) =>
        liveDataIndex >= 0
            ? {
                  // The first predictedSum is available in the last item with a real historical sum to connect both lines
                  ...dataSet,
                  sum: index < liveDataIndex ? dataSet.sum : null,
                  predictedSum: index >= liveDataIndex - 1 ? dataSet.sum : null,
              }
            : {
                  ...dataSet,
                  sum: isYearInThePast ? dataSet.sum : null,
                  predictedSum: isYearInThePast ? null : dataSet.sum,
              },
    );

    const barSegmentKeys = barSegments.map((segment) => segment.key);

    return (
        <ResponsiveContainer>
            <ComposedChart data={sortedDataWithSumSplit} stackOffset="sign">
                <CartesianGrid strokeDasharray="4" vertical={false} />
                <XAxis {...monthXAxisProps} dataKey={xAxis} />
                <YAxis {...currencyYAxisProps} />
                <Legend
                    {...legendProps}
                    content={
                        target !== undefined ? (
                            <ExtendableCircleLegend legendLayout={LegendLayout.Reversed}>
                                <TargetChip
                                    size="small"
                                    label={t(translationKeys.VDLANG_DASHBOARDS_COMPLETED_EFFECTS_LEGEND_TARGET_SUMMARY, {
                                        value: formatCurrencyShort(target),
                                    })}
                                />
                            </ExtendableCircleLegend>
                        ) : (
                            <ExtendableCircleLegend legendLayout={LegendLayout.Reversed} />
                        )
                    }
                />
                <Tooltip
                    {...tooltipProps}
                    formatter={(value: number | string) => formatCurrency(value) ?? value}
                    labelFormatter={(label) => {
                        const snapshot = moment(label);
                        const isPredicted = moment().isSameOrBefore(snapshot);
                        const suffix = isPredicted
                            ? ` (${t(translationKeys.VDLANG_DASHBOARDS_COMPLETED_EFFECTS_FORECAST_CONFIGURATION)})`
                            : "";
                        return snapshot.format("MMM YYYY") + suffix;
                    }}
                />
                {barSegments.map(({ key, color, label }) => (
                    <Bar
                        name={label}
                        key={key}
                        dataKey={key}
                        stackId="0" // all bars should be stacked
                        fill={color}
                        legendType="circle"
                    >
                        {showSums ? <ShortNoCodeCurrencyLabelList stroke="none" stackDataKeys={barSegmentKeys} fillOpacity={1} /> : null}
                        {sortedData.map((item, monthIndex) => (
                            <Cell
                                key={`cell-${key}`}
                                stroke={color}
                                fillOpacity={monthIndex >= liveDataIndex && !isYearInThePast ? 0.25 : 1}
                            />
                        ))}
                    </Bar>
                ))}
                {hasSum && (
                    <>
                        <Line
                            name={t(translationKeys.VDLANG_DASHBOARDS_COMPLETED_EFFECTS_LEGEND_SUM)}
                            stroke={blueGrey[600]}
                            dataKey={CalculatedStackKeys.SUM}
                        />
                        <Line
                            name={t(translationKeys.VDLANG_DASHBOARDS_COMPLETED_EFFECTS_LEGEND_SUM)}
                            strokeDasharray="5 5"
                            stroke={blueGrey[600]}
                            dataKey={CalculatedStackKeys.PREDICTED_SUM}
                            legendType="none"
                            dot={{ strokeDasharray: "none" }}
                        />
                    </>
                )}
                {target !== undefined && (
                    <>
                        <ReferenceLine y={target} stroke={blueGrey[800]} strokeDasharray="4" strokeWidth={2} />
                        {/* Dummy line to add a legend item for the target line */}
                        <Line name={t(translationKeys.VDLANG_DASHBOARDS_COMPLETED_EFFECTS_LEGEND_TARGET)} stroke={blueGrey[800]} />
                    </>
                )}
                <ReferenceLine y={0} stroke={grey[600]} />
            </ComposedChart>
        </ResponsiveContainer>
    );
};

export default WidgetBarChart;
