import { styled } from "@mui/material";
import classNames from "classnames";
import React from "react";
import ResizeHandleIcon from "../../components/icons/ResizeHandleIcon";

const ResizeHandle = styled(ResizeHandleIcon)({
    position: "absolute",
    "& > *": {
        // set opacity one level deeper than root, because root's opacity is also used for transitioning hover effects and would be
        // overwritten
        opacity: 0.5,
    },
});

interface IDashboardResizeHandleProps {
    className?: string;
    handleAxis?: string;
}

const DashboardResizeHandle = React.forwardRef<SVGSVGElement, IDashboardResizeHandleProps>(
    ({ handleAxis, className, ...otherProps }, ref) => {
        return (
            <ResizeHandle
                fontSize="large"
                color="primary"
                ref={ref}
                // positioning and orientation of the handle is provided by the react-resizable-handle-* classes
                className={classNames(className, handleAxis != null && `react-resizable-handle-${handleAxis}`)}
                {...otherProps}
            />
        );
    },
);

export default DashboardResizeHandle;
