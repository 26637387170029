import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

const ResizeHandleIcon = React.forwardRef<SVGSVGElement, SvgIconProps>((props, ref) => (
    <SvgIcon viewBox="0 0 34 34" {...props} ref={ref}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M28 8C28 9.1 27.1 10 26 10C24.9 10 24 9.1 24 8C24 6.9 24.9 6 26 6C27.1 6 28 6.9 28 8ZM18 14C18 12.9 18.9 12 20 12C21.1 12 22 12.9 22 14C22 15.1 21.1 16 20 16C18.9 16 18 15.1 18 14ZM18 20C18 18.9 18.9 18 20 18C21.1 18 22 18.9 22 20C22 21.1 21.1 22 20 22C18.9 22 18 21.1 18 20ZM10 26C10 27.1 9.1 28 8 28C6.9 28 6 27.1 6 26C6 24.9 6.9 24 8 24C9.1 24 10 24.9 10 26ZM14 28C15.1 28 16 27.1 16 26C16 24.9 15.1 24 14 24C12.9 24 12 24.9 12 26C12 27.1 12.9 28 14 28ZM20 28C21.1 28 22 27.1 22 26C22 24.9 21.1 24 20 24C18.9 24 18 24.9 18 26C18 27.1 18.9 28 20 28ZM14 18C12.9 18 12 18.9 12 20C12 21.1 12.9 22 14 22C15.1 22 16 21.1 16 20C16 18.9 15.1 18 14 18ZM26 16C27.1 16 28 15.1 28 14C28 12.9 27.1 12 26 12C24.9 12 24 12.9 24 14C24 15.1 24.9 16 26 16ZM26 18C24.9 18 24 18.9 24 20C24 21.1 24.9 22 26 22C27.1 22 28 21.1 28 20C28 18.9 27.1 18 26 18ZM24 26C24 24.9 24.9 24 26 24C27.1 24 28 24.9 28 26C28 27.1 27.1 28 26 28C24.9 28 24 27.1 24 26Z"
        />
    </SvgIcon>
));
export default ResizeHandleIcon;
