import { ViewBox } from "recharts/types/util/types";
import useCurrency from "../../../hooks/useCurrency";
import SumLabelList, { SumLabelListProps } from "./SumLabelList";

// this interface is sadly not exposed by recharts
// see https://github.com/recharts/recharts/blob/master/src/component/LabelList.tsx#L13-L17
interface RechartsLabelListData {
    value?: number | string | Array<number | string>;
    payload?: any;
    parentViewBox?: ViewBox;
}

const ShortNoCodeCurrencyLabelList = <T extends RechartsLabelListData>(props: SumLabelListProps<T>) => {
    const { formatCurrencyShortNoCode } = useCurrency();

    return <SumLabelList formatter={(value: number | string) => formatCurrencyShortNoCode(value) ?? value} {...props} />;
};

// This is required by recharts because otherwise this component is filtered out by the recharts `findAllByType` function
// that is collecting label list components for the label list layer.
ShortNoCodeCurrencyLabelList.displayName = "LabelList";

export default ShortNoCodeCurrencyLabelList;
