import { Grid } from "@mui/material";
import ScopeSelect from "../../../measures/preferences/ScopeSelect";
import { WidgetConfig, WaterfallWidgetConfig } from "api-shared";
import { useCurrentClient } from "../../../../domain/client";
import { useTranslation } from "react-i18next";
import { useMeasureConfigs } from "../../../../domain/measure-config";
import WidgetConfigTabContainer from "../../WidgetConfigTabContainer";

interface WaterfallConfigTabScopeProps {
    config: WaterfallWidgetConfig;
    onConfigChange: (newConfig: WidgetConfig, isValid?: boolean) => void;
    disabled: boolean;
    skipFields: string[];
}

const WaterfallConfigTabScope = ({ config, onConfigChange, disabled, skipFields }: WaterfallConfigTabScopeProps) => {
    const { t } = useTranslation();
    const client = useCurrentClient();
    const measureConfigs = useMeasureConfigs();

    return (
        <WidgetConfigTabContainer>
            <Grid container>
                <Grid item xs={6}>
                    <ScopeSelect
                        value={config.scope}
                        onChange={(scope) => onConfigChange({ ...config, scope })}
                        disabled={disabled}
                        measureConfigs={measureConfigs}
                        measureConfigIds={measureConfigs.map((measureConfig) => measureConfig.id)}
                        financialMonth={client.fiscalYear}
                        yearsBefore={client.fiscalYearRangePast}
                        yearsAfter={client.fiscalYearRangeFuture}
                        menuPortalTarget={document.body}
                        translate={t}
                        skipFields={skipFields}
                    />
                </Grid>
            </Grid>
        </WidgetConfigTabContainer>
    );
};

export default WaterfallConfigTabScope;
