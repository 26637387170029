import DeleteIcon from "@mui/icons-material/DeleteRounded";
import SaveIcon from "@mui/icons-material/SaveRounded";
import OverrideIcon from "@mui/icons-material/UpdateRounded";
import { Chip, Grid, Popover, Stack, styled, Typography } from "@mui/material";
import { FiscalMoment, generateFiscalMomentsFromInterval, LiveRunUpGranularity, LiveRunUpMonth, LiveRunUpWidgetConfig } from "api-shared";
import moment from "moment";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import ConfirmationPopupDialog from "../../../components/ConfirmationPopupContent";
import { SimpleCurrencyCellInput } from "../../../components/input/SimpleCurrencyCellInput";
import OutlinedIconButton from "../../../components/OutlinedIconButton";
import Tooltip from "../../../components/Tooltip";
import useTimezone from "../../../hooks/useTimezone";
import { translationKeys } from "../../../translations/main-translations";
import { MeasureCalculationGranularityMap } from "./utils";

function groupByFiscalYear(fiscalMoments: FiscalMoment[]): { [year: number]: FiscalMoment[] } {
    return fiscalMoments.reduce(
        (result, item) => {
            const fiscalYear = item.fiscalMoment.year();
            if (!result[fiscalYear]) {
                result[fiscalYear] = [];
            }
            result[fiscalYear].push(item);
            return result;
        },
        {} as { [year: number]: FiscalMoment[] },
    );
}

const ReferenceTimestampChip = styled(Chip)(({ theme }) => ({
    backgroundColor: theme.palette.background.default,
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
}));

enum ConfirmationType {
    OVERRIDE = "OVERRIDE",
    DELETE = "DELETE",
}

interface ReferenceValuesFormProps {
    granularity: LiveRunUpGranularity;
    start: LiveRunUpMonth;
    end: LiveRunUpMonth;
    referenceValues: LiveRunUpWidgetConfig["referenceValues"];
    disabled?: boolean;
    onSave: () => void;
    onDelete: () => void;
}

const LiveRunUpReferenceValuesForm = ({
    granularity,
    start,
    end,
    referenceValues,
    disabled,
    onSave,
    onDelete,
}: ReferenceValuesFormProps) => {
    const { t } = useTranslation();
    const { formatShortDateTime } = useTimezone();
    const fiscalMoments = generateFiscalMomentsFromInterval(
        moment.utc(start),
        moment.utc(end),
        MeasureCalculationGranularityMap[granularity],
        0,
    );
    const fiscalMomentsGroupedByYear = groupByFiscalYear(fiscalMoments);

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [popupType, setPopupType] = useState<ConfirmationType>(ConfirmationType.OVERRIDE);

    function closePopup() {
        setAnchorEl(null);
    }

    function handleSave(el: React.MouseEvent<HTMLButtonElement>) {
        if (referenceValues?.data !== undefined) {
            setAnchorEl(el.currentTarget);
            setPopupType(ConfirmationType.OVERRIDE);
        } else {
            onSave();
        }
    }

    function handleDelete(el: React.MouseEvent<HTMLButtonElement>) {
        if (referenceValues?.data !== undefined) {
            setAnchorEl(el.currentTarget);
            setPopupType(ConfirmationType.DELETE);
        }
    }

    function confirm() {
        if (popupType === ConfirmationType.OVERRIDE) {
            onSave();
        } else if (popupType === ConfirmationType.DELETE) {
            onDelete();
        }
        closePopup();
    }

    return (
        <>
            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
                <Typography variant="subtitle1">
                    {t(translationKeys.VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_CONFIG_TAB_REFERENCE_VALUES)}
                </Typography>
                <Stack direction="row" spacing={1}>
                    {referenceValues && (
                        <ReferenceTimestampChip
                            label={t(translationKeys.VDLANG_DASHBOARDS_REFERENCE_VALUES_FORM_TIMESTAMP, {
                                timestamp: formatShortDateTime(moment(referenceValues.timestamp)),
                            })}
                        />
                    )}
                    <Tooltip title={referenceValues ? t(translationKeys.VDLANG_UPDATE) : t(translationKeys.VDLANG_SAVE)}>
                        <OutlinedIconButton onClick={handleSave} disabled={disabled}>
                            {referenceValues ? <OverrideIcon fontSize="small" /> : <SaveIcon fontSize="small" />}
                        </OutlinedIconButton>
                    </Tooltip>
                    <Tooltip title={t(translationKeys.VDLANG_DELETE)}>
                        <OutlinedIconButton onClick={handleDelete} disabled={disabled}>
                            <DeleteIcon fontSize="small" />
                        </OutlinedIconButton>
                    </Tooltip>
                    <Popover
                        open={Boolean(anchorEl)}
                        anchorEl={anchorEl}
                        onClose={closePopup}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                        }}
                    >
                        <ConfirmationPopupDialog
                            title={
                                popupType === ConfirmationType.OVERRIDE
                                    ? t(translationKeys.VDLANG_DASHBOARDS_REFERENCE_VALUES_FORM_CONFIRM_OVERRIDE_TITLE)
                                    : t(translationKeys.VDLANG_DASHBOARDS_REFERENCE_VALUES_FORM_CONFIRM_DELETE_TITLE)
                            }
                            text={
                                popupType === ConfirmationType.OVERRIDE
                                    ? t(translationKeys.VDLANG_DASHBOARDS_REFERENCE_VALUES_FORM_CONFIRM_OVERRIDE_TEXT)
                                    : t(translationKeys.VDLANG_DASHBOARDS_REFERENCE_VALUES_FORM_CONFIRM_DELETE_TEXT)
                            }
                            onCancel={closePopup}
                            onConfirm={confirm}
                        />
                    </Popover>
                </Stack>
            </Stack>
            {referenceValues ? (
                <Grid container direction="column">
                    {granularity === LiveRunUpGranularity.Year
                        ? Object.entries(fiscalMomentsGroupedByYear).map(([year, moments], i, obj) =>
                              moments.map((moment, j) => (
                                  <Grid
                                      key={`${year}-${i}-${j}`}
                                      container
                                      borderBottom={(theme) => (i < obj.length - 1 ? `1px solid ${theme.palette.divider}` : "none")}
                                      alignItems="center"
                                      px={1}
                                  >
                                      <Grid item xs={6} px={1}>
                                          <Typography variant="body2">{moment.calendarMoment.format("YYYY")}</Typography>
                                      </Grid>
                                      <Grid item xs={6} borderLeft={(theme) => `1px solid ${theme.palette.divider}`}>
                                          <SimpleCurrencyCellInput
                                              value={
                                                  referenceValues
                                                      ? (referenceValues.data[moment.calendarMoment.format("YYYY-MM-DD")] ?? 0)
                                                      : 0
                                              }
                                              disabled
                                          />
                                      </Grid>
                                  </Grid>
                              )),
                          )
                        : Object.entries(fiscalMomentsGroupedByYear).map(([year, moments]) => (
                              <Grid item key={year} mb={2}>
                                  <Grid container>
                                      <Grid item xs={12} bgcolor={(theme) => theme.palette.background.default} p={1}>
                                          <Typography variant="body2" fontWeight="medium">
                                              {year}
                                          </Typography>
                                      </Grid>
                                      {moments.map((moment, index) => (
                                          <Grid
                                              key={`${year}-${index}`}
                                              container
                                              borderBottom={(theme) =>
                                                  index < moments.length - 1 ? `1px solid ${theme.palette.divider}` : "none"
                                              }
                                              alignItems="center"
                                          >
                                              <Grid item xs={6} px={1}>
                                                  <Typography variant="body2">
                                                      {granularity === LiveRunUpGranularity.Quarter
                                                          ? moment.calendarMoment.format("[Q]Q YYYY")
                                                          : null}
                                                      {granularity === LiveRunUpGranularity.Month
                                                          ? moment.calendarMoment.format("MMMM YYYY")
                                                          : null}
                                                  </Typography>
                                              </Grid>
                                              <Grid item xs={6} borderLeft={(theme) => `1px solid ${theme.palette.divider}`}>
                                                  <Stack direction="row">
                                                      <SimpleCurrencyCellInput
                                                          value={
                                                              referenceValues
                                                                  ? (referenceValues.data[moment.calendarMoment.format("YYYY-MM-DD")] ?? 0)
                                                                  : 0
                                                          }
                                                          disabled
                                                      />
                                                  </Stack>
                                              </Grid>
                                          </Grid>
                                      ))}
                                  </Grid>
                              </Grid>
                          ))}
                </Grid>
            ) : (
                <Typography color="textSecondary">{t(translationKeys.VDLANG_DASHBOARDS_REFERENCE_VALUES_FORM_NO_VALUES)}</Typography>
            )}
        </>
    );
};

export default LiveRunUpReferenceValuesForm;
