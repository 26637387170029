import { ProcessWhiteSpotMatrixWidgetConfig } from "api-shared";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import Select from "../../../components/input/select/Select";
import { OrderedOptionHandler, useOrderOptions } from "../../../hooks/useOrderOptions";
import { translationKeys } from "../../../translations/main-translations";

export enum MatrixSorting {
    Title = "title",
    Aggregation = "aggregation",
    FieldOrder = "fieldOrder",
}

const ORDERS = {
    [MatrixSorting.Title]: translationKeys.VDLANG_DASHBOARDS_PROCESS_WHITESPOT_CHART_CONFIG_SORT_TITLE,
    [MatrixSorting.Aggregation]: translationKeys.VDLANG_DASHBOARDS_PROCESS_WHITESPOT_CHART_CONFIG_SORT_AGGREGATION,
    [MatrixSorting.FieldOrder]: translationKeys.VDLANG_DASHBOARDS_PROCESS_WHITESPOT_CHART_CONFIG_SORT_FIELD,
};

const ProcessWhiteSpotMatrixWidgetConfigSortSelect = ({
    direction,
    config,
    disabled,
    onConfigChange,
}: {
    direction: "row" | "column";
    config: ProcessWhiteSpotMatrixWidgetConfig;
    disabled: boolean;
    onConfigChange: (newConfig: ProcessWhiteSpotMatrixWidgetConfig) => void;
}) => {
    const { t: translate } = useTranslation();

    const options = useMemo(
        () =>
            Object.entries(ORDERS).map(([value, label]) => ({
                value,
                label: translate(label),
            })),
        [translate],
    );

    const handleChange: OrderedOptionHandler<string> = (value, sort) => {
        if (value == null || sort == null) {
            return;
        }

        const newConfig =
            direction === "row"
                ? {
                      orderByRow: value,
                      sortRow: sort,
                  }
                : {
                      orderByColumn: value,
                      sortColumn: sort,
                  };

        onConfigChange({
            ...config,
            ...newConfig,
        });
    };

    const orderOptions = useOrderOptions(
        direction === "row" ? config.orderByRow : config.orderByColumn,
        direction === "row" ? config.sortRow : config.sortColumn,
        options,
        handleChange,
    );

    return (
        <Select
            value={orderOptions.value}
            options={orderOptions.options}
            onChange={orderOptions.onChange}
            label={translate(translationKeys.VDLANG_DESK_SORT_BY)}
            menuPortalTarget={document.body}
            margin="none"
            fullWidth
            isDisabled={disabled}
            isMulti={false} // help the compiler picking the correct generics
        />
    );
};

export default ProcessWhiteSpotMatrixWidgetConfigSortSelect;
