import { Skeleton, Stack } from "@mui/material";
import { times } from "lodash";

const BarHeights = ["80%", "70%", "100%", "50%", "40%", "55%", "35%", "20%"];

const BottomLabelOffset = "80px";

const VerticalBarChartSkeleton = () => {
    return (
        <>
            <Stack direction="row" gap={2}>
                {times(3, (i) => (
                    <Skeleton key={`skeleton_legend_${i}`} width="20%" />
                ))}
            </Stack>
            <Stack height={`calc(100% - ${BottomLabelOffset})`} direction="row" flexWrap="nowrap">
                <Stack width="100%" direction="column" display="flex" marginRight={2}>
                    <Stack
                        height="100%"
                        direction="row"
                        display="flex"
                        justifyContent="space-between"
                        alignItems="flex-end"
                        marginTop={2.5}
                    >
                        {BarHeights.map((height, i) => (
                            <Skeleton key={`skeleton_${i}`} height={height} width="8%" variant="rectangular" />
                        ))}
                    </Stack>
                    <Stack display="flex" height={20} justifyContent="flex-end" alignItems="flex-end" paddingBottom={1}>
                        <Skeleton key="skeleton_axis" width="100%" height={2} variant="rectangular" />
                    </Stack>
                </Stack>
            </Stack>
        </>
    );
};
export default VerticalBarChartSkeleton;
