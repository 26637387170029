import { TextField, Typography, useTheme } from "@mui/material";
import { Box } from "@mui/system";
import { WIDGET_DESCRIPTION_MAX_LENGTH } from "api-shared";
import { useTranslation } from "react-i18next";
import { translationKeys } from "../../translations/main-translations";

interface WidgetDescriptionFieldProps {
    label: string;
    rows?: number;
    description: string | null;
    disabled: boolean;
    maxLength?: number;
    onDescriptionChange: (newValue: string) => void;
}

const WidgetDescriptionField = ({
    disabled,
    onDescriptionChange,
    label,
    description,
    rows = 5,
    maxLength = WIDGET_DESCRIPTION_MAX_LENGTH,
}: WidgetDescriptionFieldProps) => {
    const theme = useTheme();
    const { t } = useTranslation();
    const currentLength = description?.length ?? 0;

    return (
        <>
            <TextField
                multiline
                fullWidth
                minRows={rows}
                maxRows={rows}
                value={description}
                onChange={(e) => onDescriptionChange(e.target.value)}
                label={label}
                disabled={disabled}
                placeholder={t(translationKeys.VDLANG_DASHBOARDS_WIDGET_DESCRIPTION_PLACEHOLDER)}
            />
            <Box textAlign="right">
                <Typography variant="caption" textAlign="right" color={currentLength > maxLength ? theme.palette.error.main : "inherit"}>
                    {currentLength} / {maxLength}
                </Typography>
            </Box>
        </>
    );
};

export default WidgetDescriptionField;
