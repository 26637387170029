import { Grid, styled, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { translationKeys } from "../../translations/main-translations";

const Image = styled("img")(({ theme }) => ({
    maxWidth: "100%",
    maxHeight: "100%",
}));

const Root = styled(Grid)({
    height: "100%",
});

interface IWidgetNoDataProps {
    src: string;
}

const WidgetNoData = ({ src }: IWidgetNoDataProps) => {
    const { t: translate } = useTranslation();
    return (
        <Root container spacing={2} alignItems="center" direction="column" justifyContent="center">
            <Grid item>
                <Image src={src} />
            </Grid>
            <Grid item>
                <Typography align="center" color="textSecondary">
                    {translate(translationKeys.VDLANG_DASHBOARDS_WIDGET_NO_DATA)}
                </Typography>
            </Grid>
        </Root>
    );
};

export default WidgetNoData;
