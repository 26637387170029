import { Divider, ListItem, ListItemAvatar, Skeleton, Stack, styled } from "@mui/material";
import React from "react";

const Bubble = styled("div")(({ theme }) => ({
    flex: "1 1 auto",
    borderRadius: theme.shape.borderRadius,
    borderTopLeftRadius: 0,
    backgroundColor: theme.palette.background.light,
    padding: theme.spacing(1, 1, 1, 2),
}));

const CommentStreamItemSkeleton = React.forwardRef<HTMLLIElement>((_, ref) => (
    <ListItem ref={ref}>
        <Divider sx={{ mb: 2 }} />
        <Stack width="100%">
            <Skeleton component="p" width="50%" />
            <Stack mt={2} direction="row">
                <ListItemAvatar>
                    <Skeleton variant="circular" width={40} height={40} />
                </ListItemAvatar>
                <Bubble>
                    <Skeleton component="p" width="25%" />
                    <Skeleton component="p" width="75%" />
                    <Skeleton component="p" width="90%" />
                    <Skeleton component="p" width="80%" />
                </Bubble>
            </Stack>
        </Stack>
    </ListItem>
));

export default CommentStreamItemSkeleton;
