import { styled } from "@mui/material";
import { ProcessListWidgetConfig, Sort, validateProcessListWidgetConfig, AclNamespaces, AclPermissions } from "api-shared";
import MeasuresWidget from "../../components/measures/MeasuresWidget";
import ProcessListWidgetConfigForm from "./ProcessListWidgetConfigForm";
import { IWidgetContentProps } from "./Widget";
import WidgetConfigDialog from "./WidgetConfigDialog";
import { useUserHasPermissionQuery } from "../../domain/permissions.ts";
import { useCallback, useState } from "react";

const BorderedMeasuresWidget = styled(MeasuresWidget)(({ theme }) => ({
    borderTop: `1px solid ${theme.palette.divider}`,
}));

const ProcessListWidget = ({
    widget,
    isConfigDialogOpen,
    onConfigDialogClose,
    onConfigSave,
    translate,
    disabled = false,
    readOnlyLabel,
    isInView,
}: IWidgetContentProps) => {
    const dataKey = `widget_${widget.id}`;
    const [pageIndex, setPageIndex] = useState(0);

    const hasUpdatePermission = useUserHasPermissionQuery({
        namespace: AclNamespaces.Dashboard,
        permission: AclPermissions.Update,
        fact: { id: widget.dashboardId },
    });

    const handleSortingChange = useCallback(
        (orderBy: string, sort: Sort) => {
            if (hasUpdatePermission.data?.hasPermission === true) {
                onConfigSave({
                    config: {
                        ...(widget.config as ProcessListWidgetConfig),
                        orderBy,
                        sort,
                    },
                });
            }
        },
        [hasUpdatePermission.data?.hasPermission, onConfigSave, widget.config],
    );

    const handlePaginationChange = useCallback(
        (newPage: number, newPageSize: number) => {
            setPageIndex(newPage);
            if (
                validateProcessListWidgetConfig(widget.config) &&
                newPageSize !== widget.config.pageSize &&
                hasUpdatePermission.data?.hasPermission === true
            ) {
                onConfigSave({
                    config: {
                        ...widget.config,
                        pageSize: newPageSize,
                    },
                });
            }
        },
        [hasUpdatePermission.data?.hasPermission, onConfigSave, widget.config],
    );

    const handleColumnsResized = useCallback(
        (newWidths: Record<string, number>) => {
            if (hasUpdatePermission.data?.hasPermission === true) {
                onConfigSave({
                    config: {
                        ...widget.config,
                        columnWidths: Object.entries(newWidths).reduce(
                            (columnWidths, [column, width]) => ({ ...columnWidths, [column]: +width.toFixed(0) }),
                            {},
                        ),
                    },
                });
            }
        },
        [hasUpdatePermission.data?.hasPermission, onConfigSave, widget.config],
    );

    if (!validateProcessListWidgetConfig(widget.config)) {
        return null;
    }

    return (
        <>
            <WidgetConfigDialog
                open={isConfigDialogOpen}
                onClose={onConfigDialogClose}
                onSave={onConfigSave}
                translate={translate}
                widget={widget}
                validateConfig={validateProcessListWidgetConfig}
                FormComponent={ProcessListWidgetConfigForm}
                noPadding
                disabled={disabled}
                readOnlyLabel={readOnlyLabel}
            />
            <BorderedMeasuresWidget
                isDense
                fullHeight
                dataKey={dataKey}
                filter={widget.config.filter}
                orderBy={widget.config.orderBy}
                sort={widget.config.sort}
                scope={widget.config.scope}
                onSortChanged={handleSortingChange}
                columns={widget.config.columns}
                defaultColumnWidths={widget.config.columnWidths}
                onColumnsResized={handleColumnsResized}
                densePagination
                defaultPageSize={widget.config.pageSize}
                onPaginationChanged={handlePaginationChange}
                enableQueries={isInView}
                pageIndex={pageIndex}
            />
        </>
    );
};

export default ProcessListWidget;
