import { UpdateWidgetRequestBodyDto, WIDGET_DESCRIPTION_MAX_LENGTH, WidgetConfig, WidgetDto } from "api-shared";
import { TFunction } from "i18next";
import React, { ReactNode, useState } from "react";
import ActionItemDialog, { IActionItemDialogProps } from "../../components/dialogues/ActionItemDialog";
import { trackEvent } from "../../infrastructure/tracking";
import { translationKeys } from "../../translations/main-translations";
import { styled, Chip } from "@mui/material";

interface IWidgetConfigDialogProps<T = never> extends Pick<IActionItemDialogProps, "open"> {
    widget: WidgetDto;
    translate: TFunction;
    onSave: (config: UpdateWidgetRequestBodyDto) => void;
    validateConfig: (config: Record<string, unknown>) => boolean;
    FormComponent: React.ComponentType<IWidgetConfigFormProps<T>>;
    noPadding?: boolean;
    onClose: (event?: React.MouseEvent | React.KeyboardEvent) => void;
    disabled?: boolean;
    readOnlyLabel?: ReactNode;
    data?: T;
}

export interface IWidgetConfigFormProps<T = never> {
    config: Record<string, unknown>;
    name: string;
    description: string | null;
    onNameChange: (newName: string) => void;
    onDescriptionChange: (newDescription: string | null) => void;
    onConfigChange: (newConfig: WidgetConfig, isValid?: boolean) => void;
    onSubmit: () => void;
    disabled: boolean;
    data?: T;
}

const TypeChip = styled(Chip)(({ theme }) => ({
    fontSize: theme.typography.pxToRem(12),
    fontWeight: theme.typography.fontWeightRegular,
    marginLeft: theme.spacing(1.5),
}));

const WidgetConfigDialog = <T = never,>({
    widget,
    open,
    onClose,
    translate,
    onSave,
    validateConfig,
    FormComponent,
    noPadding,
    disabled = false,
    readOnlyLabel,
    data,
}: IWidgetConfigDialogProps<T>) => {
    const [changes, setChanges] = useState<UpdateWidgetRequestBodyDto | null>(null);
    const [isValidFilter, setIsValidFilter] = useState(true);

    const resetChanges = () => {
        setChanges(null);
        setIsValidFilter(true);
    };

    const isValidName = changes?.name === undefined || changes.name.trim().length > 0;
    const isValidDescription = (changes?.description?.trim().length ?? 0) <= WIDGET_DESCRIPTION_MAX_LENGTH;
    const isValidConfig = (changes?.config === undefined || validateConfig(changes.config)) && isValidFilter;

    const handleSave = () => {
        if (changes?.name === undefined && changes?.config === undefined && changes?.description === undefined) {
            return;
        }

        if (changes.config !== undefined && !isValidConfig) {
            return;
        }

        if (changes.name !== undefined && !isValidName) {
            return;
        }

        if (changes.description !== undefined && !isValidDescription) {
            return;
        }

        onSave(changes);
        trackEvent({ category: "Dashboard", action: "Widget Updated", name: changes.name ?? widget.name });
    };

    const originalDisplayName = translate(widget.name, { defaultValue: null }) ?? widget.name;
    const originalDescription = translate(widget.description ?? "", { defaultValue: null }) ?? widget.description;

    const updateName = (newName: string) => setChanges({ ...changes, name: newName !== originalDisplayName ? newName : undefined });
    const updateDescription = (newDescription: string | null) =>
        setChanges({ ...changes, description: newDescription !== originalDisplayName ? newDescription : undefined });

    const handleClose = (event?: React.MouseEvent | React.KeyboardEvent) => {
        resetChanges();
        onClose(event);
    };

    const handleFormSubmit = () => {
        handleSave();
        handleClose();
    };

    const configChangeValidation = (newConfig: WidgetConfig, isValid?: boolean) => {
        setChanges({ ...changes, config: newConfig });
        setIsValidFilter(isValid ?? true);
    };

    return (
        <ActionItemDialog
            open={open}
            onClose={handleClose}
            translate={translate}
            onPrimary={handleSave}
            primary={translate(translationKeys.VDLANG_SAVE)}
            primaryIsTranslated
            primaryDisabled={disabled || !isValidConfig || !isValidName || !isValidDescription}
            title={translate(translationKeys.VDLANG_DASHBOARDS_WIDGET_CONFIGURATION_DIALOG_TITLE)}
            titleLabel={<TypeChip size="small" label={translate(widget.type)} />}
            maxWidth="md"
            fullWidth
            readOnlyLabel={readOnlyLabel}
            disableContentPadding={noPadding}
        >
            <FormComponent
                name={changes?.name ?? originalDisplayName}
                description={changes?.description ?? originalDescription}
                config={changes?.config ?? widget.config}
                onNameChange={updateName}
                onDescriptionChange={updateDescription}
                onConfigChange={configChangeValidation}
                onSubmit={handleFormSubmit}
                disabled={disabled}
                data={data}
            />
        </ActionItemDialog>
    );
};

export default WidgetConfigDialog;
