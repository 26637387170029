import { ButtonBase, Grid, Paper, Skeleton, Stack, styled, useTheme } from "@mui/material";
import {
    AclNamespaces,
    AclPermissions,
    MeasureFieldNames,
    PivotMetric,
    ProcessWhiteSpotMatrixWidgetConfig,
    Sort,
    UserStatus,
    validateProcessWhiteSpotMatrixWidgetConfig,
} from "api-shared";
import classNames from "classnames";
import { TFunction } from "i18next";
import { orderBy, times } from "lodash";
import { Fragment, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import MultilineEllipsisTypography from "../../../components/MultilineEllipsisTypography";
import Tooltip from "../../../components/Tooltip";
import { TreeNavigationBreadcrumbs } from "../../../components/TreeNavigationBreadcrumbs";
import { useCurrentClient } from "../../../domain/client.ts";
import { useUserHasPermissionQuery } from "../../../domain/permissions.ts";
import { usePivotData, usePivotFields } from "../../../domain/reporting";
import { useAllUsers } from "../../../domain/users";
import useCurrency from "../../../hooks/useCurrency";
import { useFilterValidation } from "../../../hooks/useFilterValidation";
import IncompleteImage from "../../../static/images/widgets/white-spot-incomplete-config.svg";
import { translationKeys } from "../../../translations/main-translations";
import { IWidgetContentProps } from "../Widget";
import WidgetConfigDialog from "../WidgetConfigDialog";
import WidgetNoData from "../WidgetNoData";
import DrilldownDialog from "../reporting/DrilldownDialog";
import { Drilldown } from "../reporting/DrilldownTable.tsx";
import { FieldOption, useFieldOptions } from "../reporting/useFieldOptions";
import useTreeAggregation, { TreeAggregation } from "../reporting/useTreeAggregation";
import { DELETED_USER_ID, calculateFieldOptionsWithDeletedIds, combineDeletedUsersData } from "../utils.ts";
import WidgetStateContainer from "../widget-states/WidgetStateContainer";
import ProcessWhiteSpotMatrixWidgetConfigForm from "./ProcessWhiteSpotMatrixWidgetConfigForm";
import WhiteSpotLegend from "./WhiteSpotLegend";
import { computeMatrixData, computeMatrixSums, getCellColor, getReferenceValue } from "./utils";

const Container = styled(Stack)(({ theme }) => ({
    height: "100%",
    padding: theme.spacing(0, 3, 3),
}));

const navigatableClass = "navigatable";
const secondRowClass = "secondRow";
const footerRowClass = "footerRow";
const footerColumnClass = "footerColumn";
const secondColumnClass = "secondColumn";
const notFilledRowClass = "notFilledRow";
const notFilledColumnClass = "notFilledColumn";

const getClassnames = (rowIndex?: number, columnIndex?: number, isUnfilledField = false): string => {
    return classNames({
        [secondRowClass]: rowIndex === 0,
        [secondColumnClass]: columnIndex === 0,
        [notFilledRowClass]: rowIndex !== undefined && isUnfilledField,
        [notFilledColumnClass]: columnIndex !== undefined && isUnfilledField,
    });
};

const Table = styled(Paper, { shouldForwardProp: (name) => name !== "columnCount" })<{ columnCount: number }>(({ theme, columnCount }) => ({
    display: "grid",
    gridTemplateColumns: `repeat(${columnCount},  minmax(${theme.spacing(18)}, 1fr))`,
    gridAutoRows: `minmax(${theme.spacing(7)}, 1fr)`,
    flexShrink: 1,
    flexGrow: 1,
    minHeight: 0,
    overflow: "auto",
    ...theme.typography.body2,
    "& > *": {
        display: "flex",
        placeItems: "center",
        placeContent: "center", // align single-line text
        textAlign: "center", // align multi-line text
        padding: theme.spacing(),
        // inner table grid lines
        '&:not([role~="columnheader"])': {
            // parent container provides top border
            borderTop: `1px dashed ${theme.palette.divider}`,
        },
        '&:not([role~="rowheader"])': {
            // parent container provides left border
            borderLeft: `1px dashed ${theme.palette.divider}`,
        },
        [`&.${footerRowClass}, &.${secondRowClass}`]: {
            borderTop: `1px solid ${theme.palette.divider}`,
        },
        [`&.${footerColumnClass}, &.${secondColumnClass}`]: {
            borderLeft: `1px solid ${theme.palette.divider}`,
        },
        [`&.${notFilledRowClass}, &.${notFilledColumnClass}`]: {
            backgroundColor: theme.palette.background.default,
        },
    },
    [`& > .${footerColumnClass}`]: {
        placeContent: "flex-end", // align single-line text
        textAlign: "right", // also align multi-line text
    },
    "& > [role~='rowheader']": {
        placeContent: "flex-start", // align single-line text
        textAlign: "left", // align multi-line text
    },
    "& strong": {
        fontWeight: theme.typography.fontWeightMedium,
    },
    [`& .${navigatableClass}`]: {
        cursor: "pointer",
        "&:hover": {
            backgroundColor: theme.palette.action.hover,
        },
        textDecoration: "underline",
    },
    [`& > button`]: {
        background: "none",
        color: "inherit",
        borderBottom: "none",
        borderRight: "none",
        padding: theme.spacing(),
        font: "inherit",
        cursor: "pointer",
        outline: "inherit",
        "&:hover": {
            textDecoration: "underline",
        },
    },
}));

const getHeaderCellWithTooltip = (label: string) => {
    return (
        <Tooltip title={label}>
            <span>
                <MultilineEllipsisTypography lines={2} component="strong">
                    {label}
                </MultilineEllipsisTypography>
            </span>
        </Tooltip>
    );
};

const getFooterCellWithTooltip = (label: string | null) => {
    return (
        <Tooltip title={label} onlyOverflowing>
            <strong style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>{label}</strong>
        </Tooltip>
    );
};

const TreeNavigationButton = styled(ButtonBase)(({ theme }) => ({
    textDecoration: "underline",
    "&:hover": {
        backgroundColor: theme.palette.action.hover,
    },
}));

const SelectedHeaderCell = styled("div")(({ theme }) => ({
    backgroundColor: theme.palette.background.default,
}));

type WhiteSpotSkeletonProps = {
    defaultColumnCount?: number;
    defaultRowCount?: number;
    color: string;
};

const WhiteSpotSkeleton = ({ defaultColumnCount = 10, defaultRowCount = 10, color }: WhiteSpotSkeletonProps) => {
    // More detailed skeletons bare many edge cases, so just make a simple generic skeleton
    const colHeaders = times(defaultColumnCount).map((i) => <Skeleton key={i} width="100%" />);
    const rowsHeaders = times(defaultRowCount).map((i) => <Skeleton key={i} width="100%" />);
    return (
        <>
            <WhiteSpotLegend color={color} stepCount={6} />
            <Table variant="outlined" columnCount={defaultColumnCount + 1}>
                <div role="rowheader columnheader">{/* empty cell */}</div>
                {colHeaders.map((columnHeader, i) => (
                    <div role="columnheader" key={i}>
                        {columnHeader}
                    </div>
                ))}
                {rowsHeaders.map((rowHeader, rowIndex) => (
                    <Fragment key={rowIndex}>
                        <div role="rowheader">{rowHeader}</div>
                        {times(defaultColumnCount, (i) => (
                            <div key={i}>
                                <Skeleton width="100%" />
                            </div>
                        ))}
                    </Fragment>
                ))}
            </Table>
        </>
    );
};

function filterOptions(treeNavigation: TreeAggregation | null, value: number | null): unknown {
    if (treeNavigation == null) {
        // Do not filter anything
        return true;
    }

    // For tree fields, filter values matching visible children but do not filter out null values (-> field not filled)
    return (
        treeNavigation.visibleChildren.some((child) => child.id === value || value === null) || treeNavigation.isSelectedNode(value ?? 0)
    );
}

// Custom computations of field options specific to the whitespot matrix
function computeWhiteSpotFieldOptions(fieldOptions: FieldOption[], hasUnfilledValues: boolean, translate: TFunction): FieldOption[] {
    if (hasUnfilledValues) {
        const notFilledEntry: FieldOption = {
            value: "null",
            label: translate(translationKeys.VDLANG_DASHBOARDS_PROCESS_WHITESPOT_CHART_WIDGET_NOT_FILLED_LABEL),
        };
        return [...fieldOptions, notFilledEntry];
    }
    return fieldOptions;
}

function sortFieldOptions(fieldOptions: FieldOption[], fieldSums: Map<string, number>, orderByProperty: string, sort: Sort): FieldOption[] {
    if (orderByProperty === "title") {
        const sortedByTitle = fieldOptions.toSorted((a, b) => (a.label < b.label ? -1 : 1));
        const sortedFieldOptions = sort === Sort.ASCENDING ? sortedByTitle : sortedByTitle.toReversed();
        // If options include the "Not filled" option, always move it to the end of the array
        const unfilledFieldOptionIndex = sortedFieldOptions.findIndex(({ value }) => value === "null");
        if (unfilledFieldOptionIndex !== -1) {
            const [unfilledFieldOption] = sortedFieldOptions.splice(unfilledFieldOptionIndex, 1);
            sortedFieldOptions.push(unfilledFieldOption);
        }
        return sortedFieldOptions;
    }
    if (orderByProperty === "aggregation") {
        return orderBy(
            fieldOptions,
            [({ value }) => value === "null", ({ value }) => fieldSums.get(value) ?? -Infinity],
            // This always sorts the columns/rows with filled values on top of the one(s) with non-filled values
            ["asc", sort === Sort.ASCENDING ? "asc" : "desc"],
        );
    }
    const sortedFieldOptions = sort === Sort.ASCENDING ? fieldOptions : fieldOptions.toReversed();

    // When sorting trees by field value, always move the parent node of the selected level to the top
    const parentIds = sortedFieldOptions.map((opts) => opts.parentId);
    const parentNodeIdx = sortedFieldOptions.findIndex((node) => parentIds.includes(node.id));
    if (parentNodeIdx !== -1) {
        const [parentNode] = sortedFieldOptions.splice(parentNodeIdx, 1);
        sortedFieldOptions.unshift(parentNode);
    }

    // If options include the "Not filled" option, always move it to the end of the array
    const unfilledFieldOptionIndex = sortedFieldOptions.findIndex(({ value }) => value === "null");
    if (unfilledFieldOptionIndex !== -1) {
        const [unfilledFieldOption] = sortedFieldOptions.splice(unfilledFieldOptionIndex, 1);
        sortedFieldOptions.push(unfilledFieldOption);
    }
    return sortedFieldOptions;
}

const ProcessWhiteSpotMatrix = ({
    widget,
    isConfigDialogOpen,
    onConfigDialogClose,
    onConfigSave,
    disabled,
    readOnlyLabel,
    openConfigDialog,
    isInView,
}: IWidgetContentProps) => {
    const { config } = widget;
    const { t: translate } = useTranslation();
    const theme = useTheme();
    const { whiteSpotColor } = useCurrentClient();

    const {
        rowPivotField,
        columnPivotField,
        scope,
        filter,
        metric,
        aggregation,
        sortColumn,
        sortRow,
        orderByColumn,
        orderByRow,
        defaultStartTreeNodeIds,
    } = widget.config as ProcessWhiteSpotMatrixWidgetConfig;

    const fieldsQuery = usePivotFields(isInView);
    const [drilldown, setDrilldown] = useState<Drilldown>();

    // Once the unset columns/rows are enabled, withDefaults parameter can be removed from the hook
    const rowFieldOptions = useFieldOptions({ definitions: fieldsQuery.data, fieldName: rowPivotField ?? "", withDefaults: false }) ?? [];
    const columnFieldOptions =
        useFieldOptions({
            definitions: fieldsQuery.data,
            fieldName: columnPivotField ?? "",
            withDefaults: false,
        }) ?? [];

    const deletedUserIds = useAllUsers()
        .filter((user) => user.status === UserStatus.STATUS_DELETED)
        .map(({ id }) => id);

    const { validate } = useFilterValidation(isInView);
    const hasValidFilter = validate?.(filter);

    const { options: calculatedRowOptions, deletedIds: deletedRowIds } = calculateFieldOptionsWithDeletedIds(
        rowPivotField,
        rowFieldOptions,
        deletedUserIds,
    );

    const { options: calculatedColumnOptions, deletedIds: deletedColumnIds } = calculateFieldOptionsWithDeletedIds(
        columnPivotField,
        columnFieldOptions,
        deletedUserIds,
    );

    // Check that pivot fields are allowed
    const hasValidPivotFields = rowPivotField !== null && columnPivotField !== null;
    const hasPersonPivotField = rowPivotField === MeasureFieldNames.AssignedToId || columnPivotField === MeasureFieldNames.AssignedToId;
    const hasPersonFilterPermissionQuery = useUserHasPermissionQuery({
        namespace: AclNamespaces.User,
        permission: AclPermissions.Filter,
        fact: {},
    });
    const hasValidPivotPersonFields = !(hasPersonPivotField && !hasPersonFilterPermissionQuery.data?.hasPermission);

    // Combine all checks
    // a value of undefined means that config validation is still running
    const isValidConfig = hasValidFilter === undefined ? undefined : hasValidFilter && hasValidPivotFields && hasValidPivotPersonFields;

    const pivotQuery = usePivotData({
        metric,
        aggregation,
        fields: [
            rowPivotField ?? "",
            columnPivotField ?? "",
            MeasureFieldNames.ClientIid, // Add measureId to be able to find out how many unique processes have nullish values for the hint
        ],
        scope,
        filter,
        enabled: isInView && isValidConfig === true,
    });

    const { formatCurrency } = useCurrency();

    const rowTreeNavigation = useTreeAggregation({
        fieldDefinitions: fieldsQuery.data ?? {},
        fieldName: rowPivotField ?? "",
        pivotData: pivotQuery.data,
        defaultSelectedNodeId: defaultStartTreeNodeIds?.[rowPivotField ?? ""],
    });

    const columnTreeNavigation = useTreeAggregation({
        fieldDefinitions: fieldsQuery.data ?? {},
        fieldName: columnPivotField ?? "",
        pivotData: rowTreeNavigation?.mappedData ?? pivotQuery.data,
        defaultSelectedNodeId: defaultStartTreeNodeIds?.[columnPivotField ?? ""],
    });

    const processedData = combineDeletedUsersData(
        columnTreeNavigation?.mappedData ?? rowTreeNavigation?.mappedData ?? pivotQuery.data ?? [],
        deletedUserIds,
    );

    const hasUnfilledRowValues = (processedData ?? []).some((row) => row.fields[rowPivotField ?? ""] == null);
    const computedRowOptions = computeWhiteSpotFieldOptions(calculatedRowOptions, hasUnfilledRowValues, translate);
    const hasUnfilledColumnValues = (processedData ?? []).some((row) => row.fields[columnPivotField ?? ""] == null);
    const computedColumnOptions = computeWhiteSpotFieldOptions(calculatedColumnOptions, hasUnfilledColumnValues, translate);

    const matrixData = useMemo(
        () => computeMatrixData(processedData ?? [], rowPivotField, columnPivotField),
        [rowPivotField, columnPivotField, processedData],
    );

    const { rowSums, columnSums, totalSum } = useMemo(() => computeMatrixSums(matrixData), [matrixData]);

    if (!validateProcessWhiteSpotMatrixWidgetConfig(config)) {
        return null;
    }

    const visibleRowOptions = computedRowOptions.filter((option) => {
        const value = option.value !== "null" ? +option.value : null;
        return filterOptions(rowTreeNavigation, value);
    });
    const sortedRowFieldOptions = sortFieldOptions(visibleRowOptions, rowSums, orderByRow, sortRow);

    const visibleColumnOptions = computedColumnOptions.filter((option) => {
        const value = option.value !== "null" ? +option.value : null;
        return filterOptions(columnTreeNavigation, value);
    });
    const sortedColumnFieldOptions = sortFieldOptions(visibleColumnOptions, columnSums, orderByColumn, sortColumn);

    const columnCount = sortedColumnFieldOptions.length + 2; // +2 for row header and total sum

    const stepCount = 6;

    const maxReferenceValue = getReferenceValue(matrixData, config);

    const formatValue = metric === PivotMetric.Effect ? formatCurrency : (value: number) => value.toString();

    function getPivotValues(
        pivotField: string | null,
        valueId: number,
        treeNavigation: TreeAggregation | null,
        deletedDrilldownIds?: (number | string)[],
    ) {
        if (pivotField === MeasureFieldNames.AssignedToId && deletedDrilldownIds && valueId === DELETED_USER_ID) {
            return deletedDrilldownIds;
        } else {
            return treeNavigation != null && !Number.isNaN(valueId) ? treeNavigation.getSubtreeIds(valueId) : [String(valueId)];
        }
    }

    function openDrillDown(optionRow: string, optionColumn: string): void {
        if (rowPivotField === null || columnPivotField === null) {
            return;
        }

        const rowValueId = +optionRow;
        const columnValueId = +optionColumn;
        const newDrilldown: Drilldown = {};
        // Handle cases where either one of the fields is not filled
        newDrilldown[rowPivotField] = !Number.isNaN(rowValueId)
            ? getPivotValues(rowPivotField, rowValueId, rowTreeNavigation, deletedRowIds)
            : ["null"];
        newDrilldown[columnPivotField] = !Number.isNaN(columnValueId)
            ? getPivotValues(columnPivotField, columnValueId, columnTreeNavigation, deletedColumnIds)
            : ["null"];

        return setDrilldown(newDrilldown);
    }

    function handleConfigSave(result: { name?: string; description?: string | null; config?: Record<string, unknown> }): void {
        onConfigSave(result);
        if (rowPivotField !== null) {
            const persistedRowTreeNodeId = (result.config as ProcessWhiteSpotMatrixWidgetConfig)?.defaultStartTreeNodeIds?.[rowPivotField];
            if (rowTreeNavigation !== null && persistedRowTreeNodeId !== undefined) {
                rowTreeNavigation.setSelectedNode(persistedRowTreeNodeId);
            }
        }
        if (columnPivotField !== null) {
            const persistedColumnTreeNodeId = (result.config as ProcessWhiteSpotMatrixWidgetConfig)?.defaultStartTreeNodeIds?.[
                columnPivotField
            ];
            if (columnTreeNavigation !== null && persistedColumnTreeNodeId !== undefined) {
                columnTreeNavigation.setSelectedNode(persistedColumnTreeNodeId);
            }
        }
    }

    return (
        // Mui Stack with spacing but without useFlexGap triggers a getBoundingClientRect call which leads to a layout update of all children
        // This is a performance issue for large tables.
        // See https://gist.github.com/paulirish/5d52fb081b3570c81e3a?permalink_comment_id=3974644
        <Container spacing={2} useFlexGap>
            <WidgetConfigDialog
                open={isConfigDialogOpen}
                onClose={onConfigDialogClose}
                onSave={handleConfigSave}
                translate={translate}
                widget={widget}
                validateConfig={validateProcessWhiteSpotMatrixWidgetConfig}
                FormComponent={ProcessWhiteSpotMatrixWidgetConfigForm}
                noPadding
                disabled={disabled}
                readOnlyLabel={readOnlyLabel}
            />
            {drilldown !== undefined && (
                <DrilldownDialog
                    open
                    onClose={() => setDrilldown(undefined)}
                    dataKey={`widget${widget.id}`}
                    drilldown={drilldown}
                    filter={filter}
                    scope={scope}
                />
            )}
            <WidgetStateContainer
                widgetType={widget.type}
                hasIncompleteConfig={!hasValidPivotFields}
                // config validation is async, so explicitly check for false to avoid invalid config state being shortly shown while validation is running
                hasInvalidConfig={isValidConfig === false}
                dataQuery={pivotQuery}
                additionalQueries={[fieldsQuery]}
                renderEmpty={() => <WidgetNoData src={IncompleteImage} />}
                renderLoading={() => <WhiteSpotSkeleton color={whiteSpotColor} />}
                openConfigDialog={openConfigDialog}
            >
                <WhiteSpotLegend
                    color={whiteSpotColor}
                    stepCount={stepCount}
                    maxReferenceValue={maxReferenceValue}
                    aggregation={aggregation}
                />
                <Grid container spacing={1} wrap="nowrap">
                    {columnTreeNavigation != null ? (
                        <Grid item xs={12} md={rowTreeNavigation != null ? 6 : 12}>
                            <TreeNavigationBreadcrumbs
                                selectedPath={columnTreeNavigation.selectedPath}
                                onSelect={columnTreeNavigation.setSelectedNode}
                                rootLabel={columnPivotField != null ? translate(columnPivotField) : ""}
                                label={translate(translationKeys.VDLANG_DASHBOARDS_PROCESS_WHITESPOT_CHART_WIDGET_X_AXIS)}
                            />
                        </Grid>
                    ) : null}
                    {rowTreeNavigation != null ? (
                        <Grid item xs={12} md={columnTreeNavigation != null ? 6 : 12}>
                            <TreeNavigationBreadcrumbs
                                selectedPath={rowTreeNavigation.selectedPath}
                                onSelect={rowTreeNavigation.setSelectedNode}
                                rootLabel={rowPivotField != null ? translate(rowPivotField) : ""}
                                label={translate(translationKeys.VDLANG_DASHBOARDS_PROCESS_WHITESPOT_CHART_WIDGET_Y_AXIS)}
                            />
                        </Grid>
                    ) : null}
                </Grid>
                <Table columnCount={columnCount} variant="outlined">
                    <div key="header_rowheader" role="rowheader columnheader">
                        {/* empty cell */}
                    </div>
                    {sortedColumnFieldOptions.map((option, index) => {
                        const id = option.value !== "null" ? +option.value : null;
                        const isNavigatable = columnTreeNavigation?.isNavigatable(id ?? 0);
                        const isSelected = columnTreeNavigation?.isSelectedNode(id ?? 0);
                        const Component = isNavigatable ? TreeNavigationButton : isSelected ? SelectedHeaderCell : "div";
                        return (
                            <Component
                                key={`header_${option.value}`}
                                role="columnheader"
                                onClick={isNavigatable ? () => columnTreeNavigation?.setSelectedNode(id) : undefined}
                                className={getClassnames(undefined, index, option.value === "null")}
                            >
                                {getHeaderCellWithTooltip(option.label)}
                            </Component>
                        );
                    })}
                    <div key="header_sum" role="columnheader" className={footerColumnClass}>
                        <strong>{translate(translationKeys.VDLANG_DASHBOARDS_PROCESS_WHITESPOT_CHART_WIDGET_SUM_LABEL)}</strong>
                    </div>
                    {sortedRowFieldOptions.map((option, rowIndex) => {
                        const id = option.value !== "null" ? +option.value : null;
                        const isNavigatable = rowTreeNavigation?.isNavigatable(id ?? 0);
                        const isSelected = rowTreeNavigation?.isSelectedNode(id ?? 0);
                        const Component = isNavigatable ? TreeNavigationButton : isSelected ? SelectedHeaderCell : "div";

                        return (
                            <Fragment key={`row_${option.value}`}>
                                <Component
                                    role="rowheader"
                                    onClick={isNavigatable ? () => rowTreeNavigation?.setSelectedNode(id) : undefined}
                                    className={getClassnames(rowIndex, undefined, option.value === "null")}
                                >
                                    {getHeaderCellWithTooltip(option.label)}
                                </Component>
                                {sortedColumnFieldOptions.map((option2, columnIndex) => {
                                    const value = matrixData.get(option.value)?.get(option2.value);
                                    return value != null ? (
                                        <button
                                            key={option2.value}
                                            // Prefer static styles over styled components for performance reasons
                                            // using classNames does not work with (possibly) dynamic stepCount
                                            // maxReferenceValue + 1 because only fields with a higher count than the maxReference number should have 100% opacity
                                            style={{
                                                ...getCellColor(value, maxReferenceValue + 1, stepCount, whiteSpotColor, theme),
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                                wordBreak: "break-word",
                                            }}
                                            onClick={() => openDrillDown(option.value, option2.value)}
                                            className={getClassnames(rowIndex, columnIndex)}
                                            title={formatValue(value) ?? ""}
                                        >
                                            {formatValue(value)}
                                        </button>
                                    ) : (
                                        <div key={option2.value} className={getClassnames(rowIndex, columnIndex)} />
                                    );
                                })}
                                <div key={`row_sum_${option.value}`} className={classNames(getClassnames(rowIndex, 0), footerColumnClass)}>
                                    {getFooterCellWithTooltip(formatValue(rowSums.get(option.value) ?? 0))}
                                </div>
                            </Fragment>
                        );
                    })}
                    <div key="footer_rowheader" role="rowheader" className={footerRowClass}>
                        <strong>{translate(translationKeys.VDLANG_DASHBOARDS_PROCESS_WHITESPOT_CHART_WIDGET_SUM_LABEL)}</strong>
                    </div>
                    {sortedColumnFieldOptions.map((option, index) => (
                        <div key={`footer_${option.value}`} role="cell" className={classNames(getClassnames(0, index), footerRowClass)}>
                            {getFooterCellWithTooltip(formatValue(columnSums.get(option.value) ?? 0))}
                        </div>
                    ))}
                    <div key="total_sum" role="cell" className={classNames(footerRowClass, footerColumnClass)}>
                        {getFooterCellWithTooltip(formatValue(totalSum))}
                    </div>
                </Table>
            </WidgetStateContainer>
        </Container>
    );
};

export default ProcessWhiteSpotMatrix;
