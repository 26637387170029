import { styled, TextField } from "@mui/material";
import { IdeaFieldNames, IdeaFilterDefintion, validateIdeaListWidgetConfig } from "api-shared";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Form from "../../components/Form";
import TableColumnConfigForm from "../../components/table/TableColumnConfigForm";
import { useIdeaFieldDefinitionsQuery } from "../../domain/filters";
import { IDEA_FIELD_PREFIX } from "../../translations/field-translations";
import { translationKeys } from "../../translations/main-translations";
import IdeaFilterForm from "../ideas/preferences/filter-configuration/IdeaFilterForm";
import { IWidgetConfigFormProps } from "./WidgetConfigDialog";
import WidgetConfigTab from "./WidgetConfigTab";
import WidgetConfigTabs from "./WidgetConfigTabs";
import WidgetDescriptionField from "./WidgetDescriptionField";

const WidgetConfigTabContainer = styled("div")(({ theme }) => ({
    padding: theme.spacing(3),
    minHeight: theme.spacing(66),
}));

const IdeaListWidgetConfigForm = ({
    config,
    name,
    description,
    onNameChange,
    onDescriptionChange,
    onConfigChange,
    onSubmit,
    disabled,
}: IWidgetConfigFormProps) => {
    const [openTab, setOpenTab] = useState(0);
    const { t } = useTranslation();
    const fieldDefinitions = useIdeaFieldDefinitionsQuery();

    if (!validateIdeaListWidgetConfig(config)) {
        return null;
    }

    const handleFilterChange = (filter: IdeaFilterDefintion) => {
        onConfigChange({ ...config, filter });
    };

    const handleColumnsChange = (newColumns: string[]): void => {
        let newOrderBy: string | undefined = undefined;
        if (!newColumns.includes(config.orderBy)) {
            // Column after which was ordered is not visible anymore. Fall back to the first visible and sortable one
            // If none of the columns is sortable, fall back to the title.
            newOrderBy = newColumns.find((newColumn) => fieldDefinitions.data?.[newColumn]?.isSortable) ?? IdeaFieldNames.Title;
        }
        onConfigChange({
            ...config,
            columns: newColumns,
            ...(newOrderBy != null && { orderBy: newOrderBy }),
        });
    };

    return (
        <Form onSubmit={onSubmit}>
            <WidgetConfigTabs value={openTab} onChange={(event, newValue) => setOpenTab(newValue)}>
                <WidgetConfigTab label={t(translationKeys.VDLANG_DASHBOARDS_IDEA_LIST_WIDGET_CONFIG_TAB_GENERAL)} />
                <WidgetConfigTab label={t(translationKeys.VDLANG_DASHBOARDS_IDEA_LIST_WIDGET_CONFIG_TAB_DESCRIPTION)} />
                <WidgetConfigTab label={t(translationKeys.VDLANG_COLUMN_CONFIGURATION)} />
                <WidgetConfigTab label={t(translationKeys.VDLANG_DASHBOARDS_IDEA_LIST_WIDGET_CONFIG_TAB_FILTER)} />
            </WidgetConfigTabs>
            {openTab === 0 ? (
                <WidgetConfigTabContainer>
                    <TextField fullWidth value={name} onChange={(e) => onNameChange(e.target.value)} label="Name" disabled={disabled} />
                </WidgetConfigTabContainer>
            ) : null}
            {openTab === 1 ? (
                <WidgetConfigTabContainer>
                    <WidgetDescriptionField
                        description={description}
                        onDescriptionChange={onDescriptionChange}
                        label={t(translationKeys.VDLANG_DASHBOARDS_IDEA_LIST_WIDGET_CONFIG_TAB_DESCRIPTION)}
                        disabled={disabled}
                    />
                </WidgetConfigTabContainer>
            ) : null}
            {openTab === 2 ? (
                <WidgetConfigTabContainer>
                    {fieldDefinitions.data !== undefined && (
                        <TableColumnConfigForm
                            columns={config.columns}
                            disabledColumns={[IdeaFieldNames.Title]}
                            updateColumns={handleColumnsChange}
                            translate={t}
                            fieldDefinitions={fieldDefinitions.data}
                            disabled={disabled}
                            translationPrefix={IDEA_FIELD_PREFIX}
                        />
                    )}
                </WidgetConfigTabContainer>
            ) : null}
            {openTab === 3 ? (
                <WidgetConfigTabContainer>
                    <IdeaFilterForm filter={config.filter} onChange={handleFilterChange} disabled={disabled} />
                </WidgetConfigTabContainer>
            ) : null}
        </Form>
    );
};

export default IdeaListWidgetConfigForm;
