import DeleteIcon from "@mui/icons-material/DeleteRounded";
import SaveIcon from "@mui/icons-material/SaveRounded";
import OverrideIcon from "@mui/icons-material/UpdateRounded";
import { Chip, Grid, Popover, Stack, styled, TextField, Typography } from "@mui/material";
import { AggregationMethod, CustomBarChartWidgetConfig, FieldDefinitionsDto, MeasureAttributeDto } from "api-shared";
import moment from "moment";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import ConfirmationPopupDialog from "../../../components/ConfirmationPopupContent";
import { SimpleCurrencyCellInput } from "../../../components/input/SimpleCurrencyCellInput";
import OutlinedIconButton from "../../../components/OutlinedIconButton";
import Tooltip from "../../../components/Tooltip";
import useFieldData from "../../../hooks/useFieldData";
import useTimezone from "../../../hooks/useTimezone";
import { Field, findField } from "../../../lib/fields";
import { translationKeys } from "../../../translations/main-translations";

const ReferenceTimestampChip = styled(Chip)(({ theme }) => ({
    backgroundColor: theme.palette.background.default,
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
}));

const CellNumberInput = styled(TextField)(({ theme }) => ({
    width: "100%",
    "& fieldset": {
        border: "none",
        borderRadius: 0,
    },
}));

enum ConfirmationType {
    OVERRIDE = "OVERRIDE",
    DELETE = "DELETE",
}

interface ReferenceValuesFormProps {
    referenceValues: CustomBarChartWidgetConfig["referenceValues"];
    fieldDefinitions: FieldDefinitionsDto;
    attributes: MeasureAttributeDto[];
    disabled?: boolean;
    onSave: () => void;
    onDelete: () => void;
}

const CustomBarChartReferenceValuesForm = ({
    referenceValues,
    fieldDefinitions,
    attributes,
    disabled,
    onSave,
    onDelete,
}: ReferenceValuesFormProps) => {
    const { t } = useTranslation();
    const { formatShortDateTime } = useTimezone();

    const fields = Object.values(fieldDefinitions)
        .map(({ name }) => findField(attributes, fieldDefinitions, name))
        .filter((field): field is Field => field?.options != null);
    const fieldData = useFieldData(fields);
    const fieldName = referenceValues?.config.firstPivotField ?? "";
    const fieldIndex = fields.findIndex((field) => field.title === fieldName);
    const options = fieldData[fieldIndex];

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [popupType, setPopupType] = useState<ConfirmationType>(ConfirmationType.OVERRIDE);

    function closePopup() {
        setAnchorEl(null);
    }

    function handleSave(el: React.MouseEvent<HTMLButtonElement>) {
        if (referenceValues?.data !== undefined) {
            setAnchorEl(el.currentTarget);
            setPopupType(ConfirmationType.OVERRIDE);
        } else {
            onSave();
        }
    }

    function handleDelete(el: React.MouseEvent<HTMLButtonElement>) {
        if (referenceValues?.data !== undefined) {
            setAnchorEl(el.currentTarget);
            setPopupType(ConfirmationType.DELETE);
        }
    }

    function confirm() {
        if (popupType === ConfirmationType.OVERRIDE) {
            onSave();
        } else if (popupType === ConfirmationType.DELETE) {
            onDelete();
        }
        closePopup();
    }

    return (
        <>
            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
                <Typography variant="subtitle1">
                    {t(translationKeys.VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_CONFIG_TAB_REFERENCE_VALUES)}
                </Typography>
                <Stack direction="row" spacing={1}>
                    {referenceValues && (
                        <ReferenceTimestampChip
                            label={t(translationKeys.VDLANG_DASHBOARDS_REFERENCE_VALUES_FORM_TIMESTAMP, {
                                timestamp: formatShortDateTime(moment(referenceValues.timestamp)),
                            })}
                        />
                    )}
                    <Tooltip title={referenceValues ? t(translationKeys.VDLANG_UPDATE) : t(translationKeys.VDLANG_SAVE)}>
                        <OutlinedIconButton onClick={handleSave} disabled={disabled}>
                            {referenceValues ? <OverrideIcon fontSize="small" /> : <SaveIcon fontSize="small" />}
                        </OutlinedIconButton>
                    </Tooltip>
                    <Tooltip title={t(translationKeys.VDLANG_DELETE)}>
                        <OutlinedIconButton onClick={handleDelete} disabled={disabled}>
                            <DeleteIcon fontSize="small" />
                        </OutlinedIconButton>
                    </Tooltip>
                    <Popover
                        open={Boolean(anchorEl)}
                        anchorEl={anchorEl}
                        onClose={closePopup}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                        }}
                    >
                        <ConfirmationPopupDialog
                            title={
                                popupType === ConfirmationType.OVERRIDE
                                    ? t(translationKeys.VDLANG_DASHBOARDS_REFERENCE_VALUES_FORM_CONFIRM_OVERRIDE_TITLE)
                                    : t(translationKeys.VDLANG_DASHBOARDS_REFERENCE_VALUES_FORM_CONFIRM_DELETE_TITLE)
                            }
                            text={
                                popupType === ConfirmationType.OVERRIDE
                                    ? t(translationKeys.VDLANG_DASHBOARDS_REFERENCE_VALUES_FORM_CONFIRM_OVERRIDE_TEXT)
                                    : t(translationKeys.VDLANG_DASHBOARDS_REFERENCE_VALUES_FORM_CONFIRM_DELETE_TEXT)
                            }
                            onCancel={closePopup}
                            onConfirm={confirm}
                        />
                    </Popover>
                </Stack>
            </Stack>
            {referenceValues ? (
                <Grid container direction="column" spacing={2} p={1}>
                    <Grid container bgcolor={(theme) => theme.palette.background.default} p={1}>
                        <Typography variant="body2" fontWeight="medium">
                            {t(referenceValues.config.firstPivotField)}
                        </Typography>
                    </Grid>
                    {Object.entries(referenceValues.data).map(([key, value], index, items) => {
                        return (
                            <Grid
                                key={key}
                                container
                                alignItems="center"
                                borderBottom={(theme) => (index < items.length - 1 ? `1px solid ${theme.palette.divider}` : "none")}
                            >
                                <Grid item xs={6} px={1}>
                                    <Typography variant="body2">
                                        {key === "null"
                                            ? t(translationKeys.VDLANG_DASHBOARDS_UNDEFINED_GROUP)
                                            : options.find((option) => String(option.id) === key)?.label}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} borderLeft={(theme) => `1px solid ${theme.palette.divider}`}>
                                    {referenceValues.config.aggregation === AggregationMethod.Count ? (
                                        <CellNumberInput value={value} disabled />
                                    ) : null}
                                    {referenceValues.config.aggregation === AggregationMethod.Sum ? (
                                        <SimpleCurrencyCellInput value={value} disabled />
                                    ) : null}
                                </Grid>
                            </Grid>
                        );
                    })}
                </Grid>
            ) : (
                <Typography color="textSecondary">{t(translationKeys.VDLANG_DASHBOARDS_REFERENCE_VALUES_FORM_NO_VALUES)}</Typography>
            )}
        </>
    );
};

export default CustomBarChartReferenceValuesForm;
