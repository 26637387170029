import { Breadcrumbs, Link, Stack, Typography, breadcrumbsClasses, styled } from "@mui/material";
import { NamedTreeNode } from "../lib/tree";

const RootContainer = styled(Stack)(({ theme }) => ({
    height: "100%",
    backgroundColor: theme.palette.background.default,
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(0.5, 1),
}));

const FilledBreadCrumbs = styled(Breadcrumbs)(({ theme }) => ({
    [`& .${breadcrumbsClasses.li}`]: {
        // Default styling of display: listitem adds a half pixel to height, leading to a small content shift during navigation
        display: "contents",
    },
}));

type TreeNavigationBreadcrumbsProps = {
    selectedPath?: NamedTreeNode[];
    onSelect: (id: number | null) => void;
    rootLabel: string;
    label?: string;
};

export function TreeNavigationBreadcrumbs({ selectedPath, onSelect, rootLabel, label }: TreeNavigationBreadcrumbsProps) {
    const rootNode = { id: null, name: rootLabel };
    const pathWithRoot = [rootNode, ...(selectedPath ?? [])];

    const innerPath = pathWithRoot.slice(0, -1);
    const leaf = pathWithRoot.at(-1);

    return (
        <RootContainer direction="row">
            {label != null ? <Typography mr={0.5} component="span" color="textPrimary">{`${label}:`}</Typography> : null}
            <FilledBreadCrumbs>
                {innerPath.map((node) => (
                    <Link key={node.id} component="button" color="inherit" onClick={() => onSelect(node.id)} underline="hover">
                        {node.name}
                    </Link>
                ))}
                {leaf != null ? <Typography color="inherit">{leaf.name}</Typography> : null}
            </FilledBreadCrumbs>
        </RootContainer>
    );
}
