import { alpha, Box, hexToRgb, keyframes, Stack, styled, Typography, useTheme } from "@mui/material";
import { red } from "@mui/material/colors";
import { max } from "lodash";
import { Payload } from "recharts/types/component/DefaultLegendContent";
import useCurrency from "../../../hooks/useCurrency";
import { ExtendableCircleLegend } from "../reporting/ExtendableCircleLegend";

// Note: Animating width causes re-paints and can lead to layout shifts, which is not optimal for performance.
// However, for simplicity and given the limited number of bars in this Funnel Chart, this method is used.
const growEffect = keyframes`
  0% { width: 0%; }
  100% { width: 100%; }
`;

const BarLabelContainer = styled(Box, { shouldForwardProp: (prop) => prop !== "bgcolor" && prop !== "borderColor" })<{
    bgcolor?: string;
    borderColor?: string;
}>(({ theme, bgcolor, borderColor }) => ({
    backgroundColor: bgcolor ?? alpha(hexToRgb(theme.palette.background.paper), 0.9),
    backdropFilter: "blur(4px)",
    display: "inline-block",
    padding: theme.spacing(0, 1),
    borderRadius: theme.shape.borderRadius,
    border: `solid 1px ${borderColor ?? theme.palette.divider}`,
}));

const BarBackgroundContainer = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.background.light,
    justifyContent: "center",
    display: "flex",
    borderRadius: theme.shape.borderRadius,
    flexGrow: 1,
    flexBasis: 1,
    cursor: "pointer",
}));

const BarSizeContainer = styled(Box, { shouldForwardProp: (prop) => prop !== "width" })<{ width: number }>(({ width }) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: `${width}%`,
    minWidth: 2,
}));

const BarColorContainer = styled(Box, { shouldForwardProp: (prop) => prop !== "color" })<{ color: string }>(({ color, theme }) => ({
    backgroundColor: color,
    borderRadius: theme.shape.borderRadius,
    animation: `${growEffect} 1s ease`,
    minWidth: 2,
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
}));

interface FunnelBarProps {
    color: string;
    data: FunnelStackData;
    width: number;
    onBarClick: (key: string) => void;
    labelColor?: string;
    backgroundColor?: string;
    borderColor?: string;
}

const FunnelBar = ({ color, data, width, onBarClick, labelColor, backgroundColor, borderColor }: FunnelBarProps) => {
    const { formatCurrency } = useCurrency();

    return (
        <BarBackgroundContainer color={backgroundColor} onClick={() => onBarClick(data.key)} data-testid="bar-row">
            <BarSizeContainer width={width}>
                <BarColorContainer color={color}>
                    <BarLabelContainer bgcolor={backgroundColor} borderColor={borderColor}>
                        <Typography variant="caption" sx={{ whiteSpace: "nowrap" }} color={labelColor}>
                            {formatCurrency(data.effect)}
                        </Typography>
                    </BarLabelContainer>
                </BarColorContainer>
            </BarSizeContainer>
        </BarBackgroundContainer>
    );
};

interface FunnelStackData {
    effect: number;
    title: string;
    key: string;
    color: string;
}

interface FunnelStackChartProps {
    bars: FunnelStackData[];
    onBarClick: (key: string) => void;
}

const FunnelStackChart = ({ bars, onBarClick }: FunnelStackChartProps) => {
    const theme = useTheme();
    const maxEffect = max(bars.map((bar) => bar.effect)) ?? 1;

    const errorBackgroundColor = alpha(red[600], 0.08);

    if (bars.length === 0) {
        return null;
    }

    const legendPayload = bars.map(
        (bar): Payload => ({
            value: bar.title,
            id: `legend-${bar.key}`,
            color: bar.color,
        }),
    );

    return (
        <Stack spacing={0.5} sx={{ height: "100%" }}>
            <Box p={1} marginBottom={2}>
                <ExtendableCircleLegend payload={legendPayload} />
            </Box>

            {bars.map((bar, index) => {
                const key = `bar-${bar.key}`;
                const barColor = bar.color;
                const isNegativeEffect = bar.effect < 0;
                const width = isNegativeEffect ? 0 : (bar.effect / maxEffect) * 100;

                if (isNegativeEffect) {
                    return (
                        <FunnelBar
                            data={bar}
                            width={width}
                            color={barColor}
                            key={key}
                            onBarClick={onBarClick}
                            labelColor={theme.palette.error.dark}
                            backgroundColor={errorBackgroundColor}
                            borderColor={theme.palette.error.main}
                        />
                    );
                }

                return <FunnelBar data={bar} width={width} color={barColor} key={key} onBarClick={onBarClick} />;
            })}
        </Stack>
    );
};

export default FunnelStackChart;
