import {
    MeasureFieldNames,
    Operators,
    ProjectProgressDto,
    ProjectProgressWidgetConfig,
    validateProjectProgressWidgetConfig,
    type ProjectProgressMonth,
} from "api-shared";
import { isEqual } from "lodash";
import moment from "moment";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import VerticalBarChartSkeleton from "../../../components/loading/VerticalBarChartSkeleton";
import { usePivotFields, useProjectProgressData } from "../../../domain/reporting";
import { useFilterValidation } from "../../../hooks/useFilterValidation";
import EmptyVerticalBarChartIllustration from "../../../static/images/widgets/empty-widget-bar.svg";
import { IWidgetContentProps } from "../Widget";
import WidgetConfigDialog from "../WidgetConfigDialog";
import WidgetNoData from "../WidgetNoData";
import ChartWidgetRoot from "../reporting/ChartWidgetRoot";
import type { Drilldown } from "../reporting/DrilldownTable";
import { useFieldOptions } from "../reporting/useFieldOptions";
import WidgetStateContainer from "../widget-states/WidgetStateContainer";
import ProjectProgressDrilldownDialog from "./ProjectProgressDrilldownDialog";
import ProjectProgressRunUpChart from "./ProjectProgressRunUpChart";
import ProjectProgressWidgetConfigForm from "./ProjectProgressWidgetConfigForm";

type PeriodBarGroup = {
    [k: string]: Record<string, string | number> & {
        period: string;
    };
};
type ProjectProgressConfigFormItem = Record<string, string | number> & { period: string };
export type ProjectProgressConfigFormData = ProjectProgressConfigFormItem[];
export type PreparedData = { dataWithTarget: ProjectProgressConfigFormData; dataWithoutTarget: ProjectProgressConfigFormData };

// Data mapping here is not that nice, a better API interface could ease the mapping here
function prepareData(data: ProjectProgressDto | undefined, targets: ProjectProgressWidgetConfig["targets"]): PreparedData | null {
    if (data == null) {
        return null;
    }

    const dataMap = data.reduce<PeriodBarGroup>((acc, { timeRange, value, stackValue }) => {
        const period = moment.utc({ year: timeRange.year, month: timeRange.month, day: 1 }).format(moment.HTML5_FMT.DATE);
        let existingBar = acc[period];
        if (existingBar == null) {
            existingBar = acc[period] = { period };
        }
        existingBar[stackValue] = value;
        return acc;
    }, {});

    const dataValues = Object.values(dataMap);
    const dataWithTarget =
        targets != null
            ? dataValues.map((item): ProjectProgressConfigFormItem => {
                  const target = targets[item.period];
                  return target != null
                      ? {
                            ...item,
                            target,
                        }
                      : item;
              })
            : dataValues;

    return { dataWithTarget, dataWithoutTarget: dataValues };
}

const fieldName = MeasureFieldNames.CurrentGateTaskConfigId;

const ProjectProgressWidget = ({
    widget,
    isConfigDialogOpen,
    onConfigDialogClose,
    onConfigSave,
    disabled,
    readOnlyLabel,
    openConfigDialog,
    isInView,
}: IWidgetContentProps) => {
    const { t: translate } = useTranslation();

    const { oneTimeFieldValues, recurringFieldValues, recurringAttribute, targets, ...config } =
        widget.config as ProjectProgressWidgetConfig;

    const { validate } = useFilterValidation(isInView);
    const hasValidFilter = validate?.(config.filter);

    const [drilldown, setDrilldown] = useState<Drilldown>();
    const [drillDownYear, setDrillDownYear] = useState<ProjectProgressMonth>();

    const oneTimeFilter = { field: recurringAttribute ?? "", operator: Operators.In, values: oneTimeFieldValues };
    const recurringFilter = { field: recurringAttribute ?? "", operator: Operators.In, values: recurringFieldValues };

    const projectProgressQuery = useProjectProgressData(
        {
            ...config,
            oneTimeFieldValues: oneTimeFilter,
            recurringFieldValues: recurringFilter,
        },
        isInView && recurringAttribute != null,
    );

    const pivotFieldsQuery = usePivotFields(isInView);
    const stackingGroups =
        useFieldOptions({
            definitions: pivotFieldsQuery.data,
            fieldName,
            // avoid level name being suffixed with process names
            customizeField: (field) => ({ ...field, options: { ...field.options, resolveDuplicates: false } }),
        }) ?? [];

    // inverse the order of  gate task groups to display the last gate task ("completed") nearest to the 0-line of the chart
    const orderedGroups = stackingGroups.toReversed();

    const mappedData = prepareData(projectProgressQuery.data, targets);

    function openDrilldown(xValue: string, stackingValue: unknown) {
        // scope: From config.accumulationStart -> xValue.endOfPeriod
        setDrilldown({ [fieldName]: Array.isArray(stackingValue) ? stackingValue : [stackingValue] });
        // xValue holds the first day of the clicked (fiscal) year range
        const m = moment.utc(xValue);
        setDrillDownYear({ year: m.year(), month: m.month() });
    }

    const currentConfig = {
        filter: config.filter,
        scope: config.scope,
        start: config.start,
        end: config.end,
        oneTimeFieldValues,
        recurringFieldValues,
        recurringAttribute,
    };
    const currentConfigMatchesReferenceValuesConfig =
        config.referenceValues === null ? true : isEqual(currentConfig, config.referenceValues.config);

    return (
        <ChartWidgetRoot>
            <WidgetConfigDialog<ProjectProgressConfigFormData>
                open={isConfigDialogOpen}
                onClose={onConfigDialogClose}
                onSave={onConfigSave}
                translate={translate}
                widget={widget}
                validateConfig={validateProjectProgressWidgetConfig}
                FormComponent={ProjectProgressWidgetConfigForm}
                noPadding
                disabled={disabled}
                readOnlyLabel={readOnlyLabel}
                data={mappedData?.dataWithoutTarget ?? []}
            />
            <WidgetStateContainer
                widgetType={widget.type}
                // config validation is async, so explicitly check for false to avoid invalid config state being shortly shown while validation is running
                hasInvalidConfig={hasValidFilter === false}
                dataQuery={projectProgressQuery}
                additionalQueries={[pivotFieldsQuery]}
                renderEmpty={() => <WidgetNoData src={EmptyVerticalBarChartIllustration} />}
                renderLoading={() => <VerticalBarChartSkeleton />}
                openConfigDialog={openConfigDialog}
                hasIncompleteConfig={recurringAttribute == null}
            >
                {drilldown !== undefined && drillDownYear !== undefined ? (
                    <ProjectProgressDrilldownDialog
                        open={drilldown !== undefined}
                        onClose={() => setDrilldown(undefined)}
                        dataKey={`widget${widget.id}`}
                        drilldown={drilldown}
                        filter={config.filter}
                        scope={config.scope}
                        projectStart={config.start}
                        selectedYear={drillDownYear}
                        oneTimeFilter={oneTimeFilter}
                        recurringFilter={recurringFilter}
                        recurringAttribute={recurringAttribute}
                    />
                ) : null}
                <ProjectProgressRunUpChart
                    data={mappedData?.dataWithTarget ?? []}
                    xAxis="period"
                    groups={orderedGroups}
                    showSums={config.showSums}
                    axisMinValue={config.axisMinValue}
                    axisMaxValue={config.axisMaxValue}
                    showReferenceValues={currentConfigMatchesReferenceValuesConfig && config.showReferenceValues}
                    referenceValues={config.referenceValues}
                    onOpenDrilldown={openDrilldown}
                />
            </WidgetStateContainer>
        </ChartWidgetRoot>
    );
};

export default ProjectProgressWidget;
