import { zodResolver } from "@hookform/resolvers/zod";
import { TextField } from "@mui/material";
import { CreateDashboardRequestBody, DashboardDto, zCreateDashboardRequestBody } from "api-shared";
import { FieldError, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ActionItemDialog from "../../../components/dialogues/ActionItemDialog";
import { useCreateDashboardMutation } from "../../../domain/dashboards";
import { translationKeys } from "../../../translations/main-translations";

interface CreateDashboardDialogProps {
    open: boolean;
    onClose: () => void;
    onSuccess?: (data: DashboardDto) => void;
}

const CreateDashboardDialog = ({ open, onClose, onSuccess }: CreateDashboardDialogProps) => {
    const { t: translate } = useTranslation();

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors, isValid },
    } = useForm<CreateDashboardRequestBody>({
        mode: "onChange",
        resolver: zodResolver(zCreateDashboardRequestBody),
    });

    const createDashboardMutation = useCreateDashboardMutation({ onSuccess });

    const onSubmit = handleSubmit((data) => {
        createDashboardMutation.mutate(data);
        reset();
        onClose();
    });

    const errorText = (errors: FieldError | undefined) => {
        if (errors === undefined) {
            return errors;
        }
        return errors.type?.includes("too_big")
            ? translate(translationKeys.VDLANG_DASHBOARDS_CREATE_NAME_LENGTH_ERROR)
            : translate(translationKeys.VDLANG_DASHBOARDS_CREATE_NAME_ERROR);
    };

    return (
        <ActionItemDialog
            title={translate(translationKeys.VDLANG_DASHBOARDS_CREATE)}
            primary={translationKeys.VDLANG_DASHBOARDS_CREATE_BUTTON}
            onPrimary={onSubmit}
            primaryDisabled={!isValid}
            open={open}
            onClose={onClose}
            translate={translate}
        >
            <form onSubmit={onSubmit}>
                <TextField
                    label={translate(translationKeys.VDLANG_DASHBOARDS_CREATE_NAME)}
                    margin="dense"
                    fullWidth
                    autoFocus
                    error={errors.name !== undefined}
                    helperText={errorText(errors.name ? errors.name : undefined)}
                    {...register("name")}
                />
            </form>
        </ActionItemDialog>
    );
};

export default CreateDashboardDialog;
