import { Grid } from "@mui/material";
import ScopeSelect from "../../../measures/preferences/ScopeSelect.tsx";
import { WidgetConfig, ProjectProgressWidgetConfig } from "api-shared";
import { useCurrentClient } from "../../../../domain/client.ts";
import { useTranslation } from "react-i18next";
import { useMeasureConfigs } from "../../../../domain/measure-config.ts";
import WidgetConfigTabContainer from "../../WidgetConfigTabContainer";

interface ProjectProgressConfigTabScopeProps {
    config: ProjectProgressWidgetConfig;
    onConfigChange: (newConfig: WidgetConfig, isValid?: boolean) => void;
    isValidFilter: boolean;
    disabled: boolean;
    skipFields: string[];
}

const ProjectProgressConfigTabScope = ({
    config,
    onConfigChange,
    isValidFilter,
    disabled,
    skipFields,
}: ProjectProgressConfigTabScopeProps) => {
    const { t } = useTranslation();
    const client = useCurrentClient();
    const measureConfigs = useMeasureConfigs();

    return (
        <WidgetConfigTabContainer>
            <Grid container>
                <Grid item xs={6}>
                    <ScopeSelect
                        value={{ ...config.scope, startDate: null, endDate: null }}
                        onChange={(scope) =>
                            onConfigChange(
                                { ...config, scope: { attributes: scope.attributes, currencyIds: scope.currencyIds } },
                                isValidFilter,
                            )
                        }
                        disabled={disabled}
                        measureConfigs={measureConfigs}
                        measureConfigIds={measureConfigs.map((measureConfig) => measureConfig.id)}
                        financialMonth={client.fiscalYear}
                        yearsBefore={client.fiscalYearRangePast}
                        yearsAfter={client.fiscalYearRangeFuture}
                        menuPortalTarget={document.body}
                        translate={t}
                        skipTimeRange
                        skipFields={skipFields}
                    />
                </Grid>
            </Grid>
        </WidgetConfigTabContainer>
    );
};

export default ProjectProgressConfigTabScope;
