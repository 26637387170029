import { Skeleton, styled, useTheme } from "@mui/material";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import { stripPxSuffix } from "../../styles/utils";
import DashboardUnderlayContainer from "./DashboardUnderlayContainer";
import { ROW_HEIGHT_IN_SPACING_UNITS, usePlaceholders } from "./utils";

const Root = styled("div")({
    position: "relative", // allow absolute positioning of UnderLayContainer child
});

const PlaceHolderSkeleton = styled(Skeleton)(({ theme }) => ({
    borderRadius: theme.shape.borderRadiusLarge,
}));

const DashboardSkeleton = () => {
    const theme = useTheme();
    const gap = stripPxSuffix(theme.spacing(2));

    const rowHeight = gap * ROW_HEIGHT_IN_SPACING_UNITS;

    const { placeholders } = usePlaceholders({
        isDragging: true, // Additional row of placeholders on bottom
        widgets: [],
        gap,
        rowHeight,
    });

    return (
        <Root>
            <DashboardUnderlayContainer rowHeight={rowHeight} show>
                {placeholders.map(({ key, style }) => (
                    <PlaceHolderSkeleton key={key} variant="rounded" height={rowHeight} width={style.width} />
                ))}
            </DashboardUnderlayContainer>
        </Root>
    );
};

export default DashboardSkeleton;
