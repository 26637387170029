import { sortBy } from "lodash";
import { ViewBox } from "recharts/types/util/types";
import { Option } from "../../../components/input/select/types";

interface BarSegment {
    key: string;
    color: string;
    label: string;
}

/**
 * Generate color mapping for bar segments. Avoids adjacent segments with same colors by using:
 *
 * a) order of appearance in the data (if there are more segments than colors).
 * b) static assignment (if there are less segments than colors)
 *
 * Returns segment order (by Map entry order) and segment colors. Colors are wrapped
 *
 * @export
 * @param {Option<string>[]} orderedDomain
 * @param {string[]} availableColors
 * @param {Map<string, string>} keysInUse
 * @returns
 */
export function getBarSegments(orderedDomain: Option<string>[], availableColors: string[], keysInUse?: Set<string>): BarSegment[] {
    const allKeys = new Set([...orderedDomain.map(({ value }) => value), ...(keysInUse ?? [])]);

    // enough colors for all groups available, use static assignment independent of keys in use, so that color assignment is consistent
    const hasEnoughColors = allKeys.size <= availableColors.length;

    const visibleKeys = keysInUse ?? allKeys;

    const orderedKeys = sortBy(
        [...visibleKeys],
        (key) => {
            const index = orderedDomain.findIndex(({ value }) => value === key);
            // Make sure to order unknown keys at the end
            return index === -1 ? Number.MAX_SAFE_INTEGER : index;
        },
        (i) => +i, // tie breaker for non-resolvable ids
    );

    // Generate map from key to color
    const colors: BarSegment[] = [];
    orderedKeys.forEach((key, index) => {
        const domainItemIndex = orderedDomain.findIndex(({ value }) => value === key);

        let colorIndex: number;
        if (!hasEnoughColors) {
            // assign colors solely based on occurrence in data
            colorIndex = index;
        } else if (domainItemIndex !== -1) {
            // Use fixed colors and key is found in orderedDomain
            colorIndex = domainItemIndex;
        } else {
            // Use fixed colors, but key is NOT found in orderedDomain -> there is not way to guarantee fixed colors in this case, because
            // the occurrence of unknown ids might vary between widgets
            // -> Just take the first unused color
            colorIndex = availableColors.findIndex((color) => !colors.some((c) => c.color === color));
        }

        colors.push({
            key,
            color: availableColors[colorIndex % availableColors.length],
            label: orderedDomain[domainItemIndex]?.label ?? key,
        });
    });

    return colors;
}

interface IsLabelPositionOutsideOfChartProps {
    barSign: "positive" | "negative";
    barXPosition: number;
    barWidth: number;
    labelOffset: number;
    labelSize: number;
    referencePosition?: number;
}

export function isLabelPositionOutsideOfChart({
    barSign,
    barXPosition,
    barWidth,
    labelOffset,
    labelSize,
    referencePosition,
}: IsLabelPositionOutsideOfChartProps) {
    if (referencePosition === undefined) {
        return false;
    }

    if (barSign === "positive") {
        return barXPosition + barWidth + labelOffset + labelSize >= referencePosition;
    }

    // on bars with a negative value the width is also negative
    return barXPosition + barWidth - labelOffset - labelSize <= referencePosition;
}

// this interface is sadly not exposed by recharts
// see https://github.com/recharts/recharts/blob/master/src/component/LabelList.tsx#L13-L17
interface RechartsLabelListData {
    value?: number | string | Array<number | string>;
    payload?: any;
    parentViewBox?: ViewBox;
}

// Transforms a RechartsLabelListData value to a value that can be used on the label (string, number, undefined).
// For more details see utils.test.ts and usage example in SumLabelList.tsx
export function transformToLabelValue(input?: RechartsLabelListData["value"]): number | string | undefined {
    if (input === undefined || typeof input === "string" || typeof input === "number") {
        return input;
    }

    if (input.every((el) => typeof el === "number")) {
        return input.map(String).join("|num|");
    }

    return input.join("|str|");
}

// Reverts the transformation from the label (string, number, undefined) into a RechartsLabelListData value.
// For more details see utils.test.ts and usage example in SumLabelList.tsx
export function transformFromLabelValue(input?: string | number): RechartsLabelListData["value"] | undefined {
    if (input === undefined || typeof input === "number") {
        return input;
    }

    if (input.includes("|str|")) {
        return input.split("|str|");
    }

    if (input.includes("|num|")) {
        return input.split("|num|").map(Number);
    }

    return input;
}
