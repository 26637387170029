import {
    MeasureFieldNames,
    RollingForecastWidgetConfig,
    validateRollingForecastWidgetConfig,
    type GlobalCalculationIdentifier,
} from "api-shared";
import VerticalBarChartSkeleton from "../../../components/loading/VerticalBarChartSkeleton";
import { useGateTaskConfigs } from "../../../domain/measure-config";
import { usePivotFields, useRollingForecastEffects } from "../../../domain/reporting";
import EmptyVerticalBarChartIllustration from "../../../static/images/widgets/empty-widget-bar.svg";
import { IWidgetContentProps } from "../Widget";
import WidgetConfigDialog from "../WidgetConfigDialog";
import WidgetError from "../WidgetError";
import WidgetNoData from "../WidgetNoData";
import ChartWidgetRoot from "../reporting/ChartWidgetRoot";
import { useFieldOptions } from "../reporting/useFieldOptions";
import RollingForecastChart from "./RollingForecastChart";
import RollingForecastConfigForm from "./RollingForecastConfigForm";

const RollingForecastWidget = ({
    widget,
    isConfigDialogOpen,
    onConfigDialogClose,
    onConfigSave,
    translate,
    disabled = false,
    readOnlyLabel,
    isInView,
}: IWidgetContentProps) => {
    const config = widget.config as RollingForecastWidgetConfig;

    const queryOptions = {
        calculationIdentifiers: Object.keys(config.calculationDisplayModes) as GlobalCalculationIdentifier[],
        scope: config.scope,
        filter: config.filter,
        includeCombinedACT: config.showCombinedACT,
    };

    const isValidConfig = validateRollingForecastWidgetConfig(config);
    const forecastQuery = useRollingForecastEffects(queryOptions, isValidConfig && isInView);
    const isEmpty = forecastQuery.isSuccess && forecastQuery.data.length === 0;

    // Assign colors based on order in field options, so that colors are consistent with other widgets that are based on field options
    const fieldsQuery = usePivotFields(isInView);
    const fieldOptions = useFieldOptions({ definitions: fieldsQuery.data, fieldName: MeasureFieldNames.CurrentGateTaskConfigId }) ?? [];

    const gateTaskConfigs = useGateTaskConfigs();

    const extendedFieldOptions = fieldOptions.map((option) => ({
        ...option,
        calculationIdentifier: gateTaskConfigs.find((gtc) => gtc.id === +option.value)?.calculationIdentifier ?? null,
    }));

    return (
        <ChartWidgetRoot>
            <WidgetConfigDialog
                open={isConfigDialogOpen}
                onClose={onConfigDialogClose}
                onSave={onConfigSave}
                translate={translate}
                widget={widget}
                validateConfig={validateRollingForecastWidgetConfig}
                FormComponent={RollingForecastConfigForm}
                noPadding
                disabled={disabled}
                readOnlyLabel={readOnlyLabel}
            />
            {isEmpty ? <WidgetNoData src={EmptyVerticalBarChartIllustration} /> : null}
            {(!forecastQuery.isSuccess && !forecastQuery.isError) || (!fieldsQuery.isSuccess && !fieldsQuery.isError) ? (
                <VerticalBarChartSkeleton />
            ) : null}
            {forecastQuery.isError || fieldsQuery.isError ? <WidgetError /> : null}
            {forecastQuery.isSuccess && fieldsQuery.isSuccess && !isEmpty ? (
                <RollingForecastChart
                    data={forecastQuery.data}
                    config={config}
                    gateTaskConfigs={gateTaskConfigs}
                    extendedFieldOptions={extendedFieldOptions}
                />
            ) : null}
        </ChartWidgetRoot>
    );
};

export default RollingForecastWidget;
