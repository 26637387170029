import { FormLabel, Grid, GridProps, styled, Switch, Typography } from "@mui/material";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import InfoIcon from "../../../components/icons/InfoIcon";
import PercentageInput from "../../../components/input/PercantageInput";
import { useMeasureConfigs } from "../../../domain/measure-config";
import { translationKeys } from "../../../translations/main-translations";

const ForecastSwitch = styled(FormLabel)(({ theme }) => ({
    ...theme.typography.subtitle1,
    color: theme.palette.text.primary,
    marginLeft: theme.spacing(-1.5),
}));

const MeasureConfigGrid = styled(({ disabled, ...props }: GridProps & { disabled: boolean }) => <Grid {...props} />)(
    ({ theme, disabled }) => ({
        color: disabled ? theme.palette.action.disabled : undefined,
    }),
);

const MeasureConfigName = styled(Typography)(({ theme }) => ({
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
}));

interface IForecast {
    isActive: boolean;
    probabilities: { [x: string]: number };
}

interface IForecastFormProps {
    value: IForecast;
    onChange: (newForecast: IForecast) => void;
    disabled?: boolean;
}

const ForecastForm = ({ value, onChange, disabled = false }: IForecastFormProps) => {
    const { t: translate } = useTranslation();
    const measureConfigs = useMeasureConfigs();
    return (
        <>
            <ForecastSwitch>
                <Switch
                    onChange={(event) => onChange({ ...value, isActive: event.target.checked })}
                    checked={value.isActive}
                    disabled={disabled}
                />
                {translate(translationKeys.VDLANG_DASHBOARDS_COMPLETED_EFFECTS_FORECAST_CONFIGURATION)}
                <InfoIcon title={translate(translationKeys.VDLANG_DASHBOARDS_COMPLETED_EFFECTS_FORECAST_HINT)} />
            </ForecastSwitch>
            <Grid container spacing={3}>
                {measureConfigs.map((measureConfig) => (
                    <MeasureConfigGrid key={measureConfig.id} item xs={6} disabled={!value.isActive}>
                        <MeasureConfigName variant="subtitle1">{translate(measureConfig.name)}</MeasureConfigName>
                        <Grid container rowSpacing={1} columnSpacing={2} alignItems="center">
                            {[...measureConfig.gateTaskConfigs]
                                .sort((a, b) => a.order - b.order)
                                .map((gateTaskConfig) => {
                                    return (
                                        <Fragment key={gateTaskConfig.id}>
                                            <Grid item xs={6}>
                                                <Typography align="right">{translate(gateTaskConfig.name)}</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <PercentageInput
                                                    value={value.probabilities[gateTaskConfig.id]}
                                                    disabled={disabled || !value.isActive}
                                                    onChange={(newValue: number) =>
                                                        onChange({
                                                            ...value,
                                                            probabilities: { ...value.probabilities, [gateTaskConfig.id]: newValue },
                                                        })
                                                    }
                                                />
                                            </Grid>
                                        </Fragment>
                                    );
                                })}
                        </Grid>
                    </MeasureConfigGrid>
                ))}
            </Grid>
        </>
    );
};

export default ForecastForm;
