import { useEffect, useState } from "react";

export function useMounted(delay = 300) {
    const [mounted, setMounted] = useState(false);

    useEffect(() => {
        const handle = setTimeout(() => setMounted(true), delay);
        return clearTimeout(handle);
    }, [delay]);

    return mounted;
}
