import { lighten } from "@mui/material";
import { green, grey, red } from "@mui/material/colors";
import { useEffect, useRef, useState } from "react";
import { Label, LabelProps } from "recharts";
import useCurrency from "../../../hooks/useCurrency";

interface DeltaChipProps extends LabelProps {
    value: number;
    chartWidth?: number;
    isVerticalLayout?: boolean;
}

export const AVG_POTENTIAL_CHIP_WIDTH = 95;
export const AVG_COUNT_CHIP_WIDTH = 40;
const CHIP_HEIGHT = 20;
const CHIP_BORDER_RADIUS = CHIP_HEIGHT / 2;
const CHIP_PADDING = 20;
const COLORS = { negative: red[800], zero: grey[800], positive: green[800] } as const;
const BACKGROUNDS = {
    negative: lighten(COLORS.negative, 0.8),
    zero: lighten(COLORS.zero, 0.8),
    positive: lighten(COLORS.positive, 0.8),
};

export function calculateDeltaChipColors(value: number): { textColor: string; bgColor: string } {
    let key: keyof typeof COLORS;
    if (value > 0) {
        key = "positive";
    } else if (value === 0) {
        key = "zero";
    } else {
        key = "negative";
    }
    const textColor = COLORS[key];
    const bgColor = BACKGROUNDS[key];

    return { textColor, bgColor };
}

const DeltaChip = (props: DeltaChipProps) => {
    const { formatCurrencyShortNoCode } = useCurrency();
    const labelRef = useRef<any>();
    const [rectBBox, setRectBBox] = useState<DOMRect>();

    useEffect(() => {
        if (labelRef.current) {
            setRectBBox((labelRef.current as SVGGElement).getBBox());
        }
    }, []);

    const { value, isVerticalLayout } = props;
    const { textColor, bgColor } = calculateDeltaChipColors(value);

    return (
        <g>
            {rectBBox ? (
                <rect
                    x={rectBBox.x - CHIP_PADDING / 2}
                    y={rectBBox.y - 3} // Move 3px upwards, as the y-position of the label is slightly offset
                    width={rectBBox.width + CHIP_PADDING}
                    height={CHIP_HEIGHT}
                    fill={bgColor}
                    rx={CHIP_BORDER_RADIUS}
                    ry={CHIP_BORDER_RADIUS}
                />
            ) : null}
            <g ref={labelRef}>
                <Label
                    {...props}
                    // remove this function from the props given to the label, otherwise there will be an endless loop of `renderLabel`
                    content={undefined}
                    value={formatCurrencyShortNoCode(value, "", "exceptZero")?.trim()}
                    offset={isVerticalLayout ? CHIP_HEIGHT + CHIP_PADDING / 2 : AVG_POTENTIAL_CHIP_WIDTH}
                    fill={textColor}
                />
            </g>
        </g>
    );
};

export default DeltaChip;
