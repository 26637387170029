import { Button, Grid, Stack, Typography } from "@mui/material";
import { AttributeType, EstimatesValue, IdeaFilterDefintion, IdeaListWidgetConfig, IdeaStatus, nonNullable } from "api-shared";
import { useTranslation } from "react-i18next";
import { useIdeaAttributesQuery } from "../../../../domain/ideas";
import { translationKeys } from "../../../../translations/main-translations";
import { IdeaEstimateFilter } from "../../IdeaEstimateFilter";
import IdeaFieldFilter from "../../IdeaFieldFilter";
import { IdeaStatusFilter } from "../../IdeaStatusFilter";
import IdeaUserSelectFilter from "../../IdeaUserSelectFilter";

interface IIdeaFilterFormProps {
    filter: IdeaFilterDefintion;
    onChange: (filter: IdeaFilterDefintion) => void;
    disabled?: boolean;
}

// count all selected filters
function getNumberOfFilters(filter: IdeaListWidgetConfig["filter"]): number {
    const { fields, ...otherFilters } = filter;
    return Object.values(otherFilters).filter(nonNullable).flat().length;
}

function getNumberOfFields(filter: IdeaListWidgetConfig["filter"]): number {
    const { fields = {} } = filter;
    return Object.values(fields).filter(nonNullable).flat().length;
}

const IdeaFilterForm = ({ filter, onChange, disabled }: IIdeaFilterFormProps) => {
    const ideaAttributesQuery = useIdeaAttributesQuery();
    const { t } = useTranslation();

    const handleSearchCreatorIdsChange = (value: readonly number[]) => {
        onChange({ ...filter, creatorIds: [...value] });
    };

    const handleSearchOwnerIdsChange = (value: readonly number[]) => {
        onChange({ ...filter, ownerIds: [...value] });
    };

    const handleSearchPotentialEstimateChange = (value: (EstimatesValue | null)[]) => {
        onChange({ ...filter, potentialEstimate: value });
    };

    const handleSearchTimeEstimateChange = (value: (EstimatesValue | null)[]) => {
        onChange({ ...filter, timeEstimate: value });
    };

    const handleSearchEffortEstimateChange = (value: (EstimatesValue | null)[]) => {
        onChange({ ...filter, effortEstimate: value });
    };

    const handleSearchStatusChange = (value: IdeaStatus[]) => {
        onChange({ ...filter, status: [...value] });
    };

    const handleSearchAttributeFieldChange = (key: string, value: (string | null)[]) => {
        const updatedFilter = {
            ...filter,
            fields: {
                ...filter.fields,
                [key]: value,
            },
        };

        onChange(updatedFilter);
    };

    const handleReset = () => {
        onChange({});
    };

    const numberOfFilters = getNumberOfFilters(filter);
    const numberOfFields = getNumberOfFields(filter);

    return (
        <Stack spacing={2} useFlexGap>
            <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1}>
                <Typography variant="subtitle1">
                    {t(translationKeys.VDLANG_DASHBOARDS_IDEA_WIDGET_CONFIG_FILTERS_SELECTED, { count: numberOfFilters })}
                </Typography>
                <Button onClick={handleReset} variant="text" disabled={disabled}>
                    {t(translationKeys.VDLANG_DASHBOARDS_IDEA_WIDGET_CONFIG_FILTERS_RESET)}
                </Button>
            </Stack>
            <Grid container rowSpacing={2} columnSpacing={3}>
                <Grid item xs={6}>
                    <IdeaEstimateFilter
                        variant="potential"
                        value={filter.potentialEstimate ?? []}
                        onChange={handleSearchPotentialEstimateChange}
                        disabled={disabled}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={6}>
                    <IdeaEstimateFilter
                        variant="time"
                        value={filter.timeEstimate ?? []}
                        onChange={handleSearchTimeEstimateChange}
                        disabled={disabled}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={6}>
                    <IdeaEstimateFilter
                        variant="effort"
                        value={filter.effortEstimate ?? []}
                        onChange={handleSearchEffortEstimateChange}
                        disabled={disabled}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={6}>
                    <IdeaUserSelectFilter
                        value={filter.creatorIds ?? []}
                        label={t(translationKeys.VDLANG_IDEAS_IDEA_CREATOR_LABEL)}
                        onChange={handleSearchCreatorIdsChange}
                        disabled={disabled}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={6}>
                    <IdeaUserSelectFilter
                        value={filter.ownerIds ?? []}
                        label={t(translationKeys.VDLANG_IDEAS_IDEA_RESPONSIBLE_LABEL)}
                        onChange={handleSearchOwnerIdsChange}
                        disabled={disabled}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={6}>
                    <IdeaStatusFilter value={filter.status ?? []} onChange={handleSearchStatusChange} disabled={disabled} fullWidth />
                </Grid>
            </Grid>

            {ideaAttributesQuery.data != null && ideaAttributesQuery.data.length > 0 && (
                <>
                    <Grid>
                        <Typography variant="subtitle1">
                            {t(translationKeys.VDLANG_DASHBOARDS_IDEA_WIDGET_CONFIG_FILTERS_MORE_FIELDS, { count: numberOfFields })}
                        </Typography>
                    </Grid>

                    <Grid container rowSpacing={2} columnSpacing={3}>
                        {ideaAttributesQuery.data
                            .filter((attribute) => attribute.type === AttributeType.Combobox || attribute.type === AttributeType.Tree)
                            .map((attribute) => {
                                const values = filter.fields?.[attribute.title] ?? [];
                                return (
                                    <Grid item key={attribute.title} xs={6}>
                                        <IdeaFieldFilter
                                            value={values}
                                            attribute={attribute}
                                            onChange={(value) => handleSearchAttributeFieldChange(attribute.title, value)}
                                            disabled={disabled}
                                            fullWidth
                                        />
                                    </Grid>
                                );
                            })}
                    </Grid>
                </>
            )}
        </Stack>
    );
};

export default IdeaFilterForm;
