import { styled, Tabs } from "@mui/material";

const WidgetConfigTabs = styled(Tabs)(({ theme }) => ({
    backgroundColor: theme.palette.background.default,
    borderBottom: `1px solid ${theme.palette.divider}`,
    minHeight: theme.spacing(5),
}));

WidgetConfigTabs.defaultProps = {
    variant: "fullWidth",
};

export default WidgetConfigTabs;
