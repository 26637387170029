import { Button, Stack, styled, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import IncompleteImage from "../../../static/images/widgets/white-spot-incomplete-config.svg";
import { translationKeys } from "../../../translations/main-translations";

const Text = styled(Typography)(({ theme }) => ({
    maxWidth: theme.spacing(38),
}));

const ConstrainedImage = styled("img")(({ theme }) => ({
    maxWidth: "100%",
    maxHeight: "100%",
    width: theme.spacing(56),
}));

type WidgetIncompleteConfigProps = {
    openConfigDialog?: () => void;
};

const WidgetIncompleteConfig = ({ children, openConfigDialog }: React.PropsWithChildren<WidgetIncompleteConfigProps>) => {
    const { t: translate } = useTranslation();
    const text = translate(translationKeys.VDLANG_DASHBOARDS_CHART_WIDGET_INCOMPLETE_CONFIG);

    return (
        <Stack height="100%" alignItems="center" justifyContent="center" spacing={2}>
            <ConstrainedImage src={IncompleteImage} alt={text} />
            <Text variant="body2" color="textSecondary" align="center">
                {children ?? text}
            </Text>
            {openConfigDialog !== undefined ? (
                <Button onClick={openConfigDialog}>
                    {translate(translationKeys.VDLANG_DASHBOARDS_CHART_WIDGET_INCOMPLETE_CONFIG_BUTTON_LABEL)}
                </Button>
            ) : null}
        </Stack>
    );
};

export default WidgetIncompleteConfig;
