import { styled } from "@mui/material";
import WidgetDescriptionField from "../../WidgetDescriptionField";

const WidgetConfigTabContainer = styled("div")(({ theme }) => ({
    padding: theme.spacing(3),
    minHeight: theme.spacing(25),
}));

interface WaterfallConfigTabDescriptionProps {
    label: string;
    disabled: boolean;
    description: string | null;
    onDescriptionChange: (newDescription: string | null) => void;
}

const WaterfallConfigTabDescription = ({ label, disabled, description, onDescriptionChange }: WaterfallConfigTabDescriptionProps) => {
    return (
        <WidgetConfigTabContainer>
            <WidgetDescriptionField description={description} onDescriptionChange={onDescriptionChange} label={label} disabled={disabled} />
        </WidgetConfigTabContainer>
    );
};

export default WaterfallConfigTabDescription;
