import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import { useTranslation } from "react-i18next";
import BaseDialog, { type IBaseDialogProps } from "../../../components/dialogues/BaseDialog";
import { translationKeys } from "../../../translations/main-translations";
import type { DrilldownTableProps } from "./DrilldownTable";
import DrilldownTable from "./DrilldownTable";

interface DrilldownDialogProps extends DrilldownTableProps, Pick<IBaseDialogProps, "open" | "onClose"> {}

const DrilldownDialog = ({ open, onClose, ...drilldownTableProps }: DrilldownDialogProps) => {
    const { t } = useTranslation();

    return (
        <BaseDialog
            open={open}
            onClose={onClose}
            title={t(translationKeys.VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_DRILLDOWN_TITLE)}
            menuActions={[
                <IconButton key="close" onClick={onClose}>
                    <CloseIcon />
                </IconButton>,
            ]}
            // fix Draggablecore error, disable posibility to double click measure link. https://github.com/react-grid-layout/react-draggable/blob/2c3aa84352ca8eaed42a9ac0b59a1187e7850b6a/lib/DraggableCore.js#L277
            onMouseDown={(e) => e.stopPropagation()}
            maxWidth="lg"
            disableContentPadding
        >
            <DrilldownTable {...drilldownTableProps} />
        </BaseDialog>
    );
};

export default DrilldownDialog;
