import { Popper, styled } from "@mui/material";
import { useState } from "react";
import type { TooltipProps } from "recharts";
import type { NameType, ValueType } from "recharts/types/component/DefaultTooltipContent";
import ChartTooltipContent, { ChartTooltipContentProps } from "./ChartTooltipContent";

const TooltipPopper = styled(Popper)(({ theme }) => ({
    zIndex: theme.zIndex.tooltip,
}));

interface IInvisibleReferenceElementProps {
    offset: number;
}

export interface IChartToolTipProps extends TooltipProps<ValueType, NameType>, ChartTooltipContentProps {
    chartHeight: number;
    hideEmptyBarTooltip?: boolean;
    isFlightPath?: boolean;
    showName?: boolean;
}

const InvisibleReferenceElement = styled("div", { shouldForwardProp: (name) => name !== "offset" })(
    ({ offset }: IInvisibleReferenceElementProps) => ({
        visibility: "hidden",
        // Position the center of the reference element at the cursor position
        height: offset * 2,
        width: offset * 2,
        transform: `translate(${-offset}px, ${-offset}px)`,
    }),
);

const ChartTooltip = ({
    payload,
    active,
    label,
    formatter,
    labelFormatter,
    chartHeight = 1,
    legendTypes,
    hideSummary,
    deduplicateBy,
    tooltipLayout,
    showDifferenceToTarget,
    target,
    showPercentageShares,
    hideEmptyBarTooltip,
    isFlightPath,
    showName,
}: IChartToolTipProps) => {
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    if (hideEmptyBarTooltip === true && payload?.every((p) => p.value === 0)) {
        // this is necessary because if the anchorEl ref for any tooltip is missing the tooltip component freaks out for some reason
        return <InvisibleReferenceElement offset={chartHeight} ref={setAnchorEl} />;
    }

    const forwardedProps = {
        payload,
        active,
        label,
        formatter,
        labelFormatter,
        legendTypes,
        hideSummary,
        deduplicateBy,
        tooltipLayout,
        showDifferenceToTarget,
        showPercentageShares,
        target,
        isFlightPath,
        showName,
    };

    return (
        <InvisibleReferenceElement offset={chartHeight} ref={setAnchorEl}>
            <TooltipPopper
                placement="top"
                modifiers={[
                    {
                        name: "flip",
                        enabled: true,
                    },
                    {
                        name: "preventOverflow",
                        enabled: true,
                        options: {
                            altAxis: true,
                        },
                    },
                ]}
                popperOptions={{
                    strategy: "fixed",
                }}
                anchorEl={anchorEl}
                open={Boolean(active)}
            >
                <ChartTooltipContent {...forwardedProps} />
            </TooltipPopper>
        </InvisibleReferenceElement>
    );
};

export default ChartTooltip;
