import { styled } from "@mui/material";
import { CreateWidgetRequestBodyDto, WidgetTemplateDto } from "api-shared";
import { TFunction } from "i18next";
import ActionItemDialog from "../../components/dialogues/ActionItemDialog";
import { baseDialogClasses } from "../../components/dialogues/BaseDialog";
import { translationKeys } from "../../translations/main-translations";
import WidgetTemplateDialogContent from "./WidgetTemplateDialogContent";

interface WidgetTemplateDialogProps {
    widgetTemplates: WidgetTemplateDto[];
    addDashboardWidget: (data: CreateWidgetRequestBodyDto) => void;
    open: boolean;
    onClose: () => void;
    translate: TFunction;
}

const VerticalRestrictedDialog = styled(ActionItemDialog)(({ theme }) => ({
    [`& .${baseDialogClasses.content}`]: {
        overflow: "hidden",
    },
    [`& .${baseDialogClasses.paper}`]: {
        height: "90%",
    },
}));

const WidgetTemplateDialog = ({ widgetTemplates, addDashboardWidget, open, onClose, translate }: WidgetTemplateDialogProps) => {
    return (
        <VerticalRestrictedDialog
            open={open}
            onClose={onClose}
            action="add"
            item="widget-template"
            maxWidth="md"
            translate={translate}
            secondary={translationKeys.VDLANG_BACK}
            title={translate(translationKeys.VDLANG_DASHBOARDS_ADD_WIDGET_BUTTON_LABEL)}
            disableContentPadding
        >
            <WidgetTemplateDialogContent widgetTemplates={widgetTemplates} addDashboardWidget={addDashboardWidget} translate={translate} />
        </VerticalRestrictedDialog>
    );
};

export default WidgetTemplateDialog;
