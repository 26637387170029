import {
    LiveRunUpDto,
    LiveRunUpGranularity,
    LiveRunUpMonth,
    LiveRunUpWidgetConfig,
    MeasureFieldNames,
    ScopeDto,
    validateLiveRunUpWidgetConfig,
} from "api-shared";
import { isEqual } from "lodash";
import moment from "moment";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import VerticalBarChartSkeleton from "../../../components/loading/VerticalBarChartSkeleton";
import { useLiveRunUpData, usePivotFields } from "../../../domain/reporting";
import { useFilterValidation } from "../../../hooks/useFilterValidation";
import EmptyVerticalBarChartIllustration from "../../../static/images/widgets/empty-widget-bar.svg";
import { IWidgetContentProps } from "../Widget";
import WidgetConfigDialog from "../WidgetConfigDialog";
import WidgetNoData from "../WidgetNoData";
import ChartWidgetRoot from "../reporting/ChartWidgetRoot";
import DrilldownDialog from "../reporting/DrilldownDialog";
import { Drilldown } from "../reporting/DrilldownTable";
import { useFieldOptions } from "../reporting/useFieldOptions";
import WidgetStateContainer from "../widget-states/WidgetStateContainer";
import LiveRunUpWidgetConfigForm from "./LiveRunUpWidgetConfigForm";
import RunUpChart from "./RunUpChart";
import { MomentGranularityMap, generateDomain } from "./utils";

// Data mapping here is not that nice, a better API interface could ease the mapping here
function prepareData(data: LiveRunUpDto | undefined, domain: string[]) {
    if (data == null) {
        return [];
    }

    const initialData = Object.fromEntries(
        domain.map((period) => [period, { period } as Record<string, string | number> & { period: string }]),
    );

    const dataMap = data.reduce((acc, { timeRange, value, stackValue }) => {
        const period = moment.utc({ year: timeRange.year, month: timeRange.month, day: 1 }).format(moment.HTML5_FMT.DATE);
        let existingBar = acc[period];
        if (existingBar == null) {
            existingBar = acc[period] = { period };
        }
        existingBar[stackValue] = value;
        return acc;
    }, initialData);

    return Object.values(dataMap);
}

function computeScope(end: string, granularity: LiveRunUpGranularity, accumulationStart: LiveRunUpMonth): ScopeDto {
    const momentGranularity = MomentGranularityMap[granularity];
    const startDate = moment.utc(accumulationStart).format(moment.HTML5_FMT.DATE);
    const endDate = moment.utc(end).endOf(momentGranularity).format(moment.HTML5_FMT.DATE);
    return { startDate, endDate, attributes: {} };
}

const fieldName = MeasureFieldNames.CurrentGateTaskConfigId;

export type LiveRunUpConfigFormData = (Record<string, string | number> & { period: string })[];

const LiveRunUpWidget = ({
    widget,
    isConfigDialogOpen,
    onConfigDialogClose,
    onConfigSave,
    disabled,
    readOnlyLabel,
    openConfigDialog,
    isInView,
}: IWidgetContentProps) => {
    const { t: translate } = useTranslation();

    const config = widget.config as LiveRunUpWidgetConfig;
    const { target, ...queryConfig } = config;

    const { validate } = useFilterValidation(isInView);
    const hasValidFilter = validate?.(config.filter);

    const runUpQuery = useLiveRunUpData(queryConfig, isInView);

    const [drilldown, setDrilldown] = useState<Drilldown>();
    const [drilldownScope, setDrilldownScope] = useState<ScopeDto>({ startDate: null, endDate: null, attributes: {} });

    const pivotFieldsQuery = usePivotFields(isInView);
    const stackingGroups =
        useFieldOptions({
            definitions: pivotFieldsQuery.data,
            fieldName,
            // avoid level name being suffixed with process names
            customizeField: (field) => ({ ...field, options: { ...field.options, resolveDuplicates: false } }),
        }) ?? [];

    // inverse the order of  gate task groups to display the last gate task ("completed") nearest to the 0-line of the chart
    const orderedGroups = stackingGroups.toReversed();

    const domain = generateDomain(config.start, config.end, config.xAxisGranularity);
    const mappedData = prepareData(runUpQuery.data, domain);

    const currentConfig = {
        filter: config.filter,
        start: config.start,
        end: config.end,
        xAxisGranularity: config.xAxisGranularity,
        accumulationStart: config.accumulationStart,
    };
    const currentConfigMatchesReferenceValuesConfig =
        config.referenceValues === null ? true : isEqual(currentConfig, config.referenceValues.config);

    function openDrilldown(xValue: string, stackingValue: unknown) {
        // scope: From config.accumulationStart -> xValue.endOfPeriod
        setDrilldown({ [fieldName]: Array.isArray(stackingValue) ? stackingValue : [stackingValue] });
        setDrilldownScope(computeScope(xValue, config.xAxisGranularity, config.accumulationStart ?? config.start));
    }

    const isEmpty = () => mappedData.length === 0 && config.target === null;

    return (
        <ChartWidgetRoot>
            <WidgetConfigDialog<LiveRunUpConfigFormData>
                open={isConfigDialogOpen}
                onClose={onConfigDialogClose}
                onSave={onConfigSave}
                translate={translate}
                widget={widget}
                validateConfig={validateLiveRunUpWidgetConfig}
                FormComponent={LiveRunUpWidgetConfigForm}
                noPadding
                disabled={disabled}
                readOnlyLabel={readOnlyLabel}
                data={mappedData}
            />
            <WidgetStateContainer
                widgetType={widget.type}
                // config validation is async, so explicitly check for false to avoid invalid config state being shortly shown while validation is running
                hasInvalidConfig={hasValidFilter === false}
                dataQuery={runUpQuery}
                additionalQueries={[pivotFieldsQuery]}
                renderEmpty={() => <WidgetNoData src={EmptyVerticalBarChartIllustration} />}
                renderLoading={() => <VerticalBarChartSkeleton />}
                openConfigDialog={openConfigDialog}
                isEmpty={isEmpty}
            >
                {drilldown !== undefined && (
                    <DrilldownDialog
                        open={drilldown !== undefined}
                        onClose={() => setDrilldown(undefined)}
                        dataKey={`widget${widget.id}`}
                        drilldown={drilldown}
                        filter={config.filter}
                        scope={drilldownScope}
                    />
                )}
                <RunUpChart
                    data={mappedData}
                    xAxis="period"
                    target={config.target ?? undefined}
                    groups={orderedGroups}
                    granularity={config.xAxisGranularity}
                    onOpenDrilldown={openDrilldown}
                    showSums={config.showSums}
                    axisMinValue={config.axisMinValue}
                    axisMaxValue={config.axisMaxValue}
                    showReferenceValues={currentConfigMatchesReferenceValuesConfig && config.showReferenceValues}
                    referenceValues={config.referenceValues}
                />
            </WidgetStateContainer>
        </ChartWidgetRoot>
    );
};

export default LiveRunUpWidget;
