import { useTheme } from "@mui/material";
import { blueGrey, grey } from "@mui/material/colors";
import {
    GlobalCalculationIdentifier,
    RollingForecastDataRepresentation,
    RollingForecastDto,
    RollingForecastWidgetConfig,
    nonNullable,
    type GateTaskConfigDto,
} from "api-shared";
import { countBy, mergeWith, minBy, sortBy } from "lodash";
import moment from "moment";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Bar, CartesianGrid, ComposedChart, Legend, Line, ReferenceLine, Tooltip, XAxis, YAxis } from "recharts";
import ResponsiveContainer from "../../../components/ResponsiveContainer";
import useCurrency from "../../../hooks/useCurrency";
import { stripPxSuffix } from "../../../styles/utils";
import { translationKeys } from "../../../translations/main-translations";
import ShortNoCodeCurrencyLabelList from "../charts/ShortNoCodeCurrencyLabelList";
import { useCurrencyYAxisProps, useDashboardColors, useLegendProps, useMonthXAxisProps, useTooltipProps } from "../charts/hooks";
import { getBarSegments } from "../charts/utils";
import { type FieldOption } from "../reporting/useFieldOptions";
import { generateTargetMonthId } from "./utils";

const TARGET_DATAKEY = "Target";
const SUM_DATAKEY = "Sum";
const ROLLING_FORECAST_DATAKEY = `rollingEffects.${GlobalCalculationIdentifier.Forecast}`;

interface RollingForecastChartProps {
    data: RollingForecastDto[];
    config: RollingForecastWidgetConfig;
    extendedFieldOptions: ExtendedFieldOption[];
    gateTaskConfigs: GateTaskConfigDto[];
}

type Data = RollingForecastDto & { [TARGET_DATAKEY]: number | undefined };

type ExtendedFieldOption = FieldOption & { calculationIdentifier: string | null };

const xAxisAccessor = (item: RollingForecastDto) => moment().startOf("month").month(item.month).year(item.year).format("YYYY-MM-DD");

function findUnusedColor(allColors: string[], usedColors: string[]): string {
    const unusedColor = allColors.find((color) => !usedColors.includes(color));

    if (unusedColor != null) {
        return unusedColor;
    }

    // All colors are already used -> find the least used one
    // This will rarely happen as the colors are distributed only among all calculationIdentifiers + target + rollingForecast
    const usageCount = countBy(usedColors);
    return minBy(allColors, (color) => usageCount[color]) ?? allColors[0];
}

function getColorMapping(dashboardColors: string[], fieldOptions: ExtendedFieldOption[]): Record<string, string> {
    const defaultColors: Record<string, string> = {
        [GlobalCalculationIdentifier.Plan]: dashboardColors[6],
        [GlobalCalculationIdentifier.Forecast]: dashboardColors[1],
        [GlobalCalculationIdentifier.Actual]: dashboardColors[0],
        [ROLLING_FORECAST_DATAKEY]: dashboardColors[3],
    };

    if (fieldOptions.length > dashboardColors.length) {
        // Actually getBarSegments check for this as well, but here special mapping is intended instead of the behavior in getBarSegments
        return defaultColors;
    }

    // Use reversed options because other charts stack the GateTaskConfigs in reversed order
    const reversedOptions = fieldOptions.toReversed();

    // Assign colors in reversed order, because other charts stack the GateTaskConfigs in reversed order
    // Pass empty set for keysInUse, so that barSegments will be an empty array in case there are more options than colors
    // default colors above will be used then
    const barColors = getBarSegments(reversedOptions, dashboardColors);

    const colorMapping: Record<string, string> = {};
    reversedOptions.forEach(({ value, calculationIdentifier }) => {
        if (calculationIdentifier == null || colorMapping[calculationIdentifier] != null) {
            // not relevant or already set
            return;
        }
        const assignedColor = barColors.find((bar) => bar.key === value)?.color;
        colorMapping[calculationIdentifier] = assignedColor ?? defaultColors[calculationIdentifier];
    });

    // Assign target + rollingFC colors here as well to avoid collisions
    const usedColors = Object.values(colorMapping);

    colorMapping[ROLLING_FORECAST_DATAKEY] = findUnusedColor(dashboardColors, usedColors);

    return colorMapping;
}

const RollingForecastChart = ({ data, config, extendedFieldOptions, gateTaskConfigs }: RollingForecastChartProps) => {
    const currencyYAxisProps = useCurrencyYAxisProps(undefined, config.axisMinValue, config.axisMaxValue);
    const monthXAxisProps = useMonthXAxisProps();
    const legendProps = useLegendProps();
    const dashboardColors = useDashboardColors();

    const { formatCurrency } = useCurrency();

    const { t: translate } = useTranslation();

    const { calculationDisplayModes, targetValues, targetPresentation } = config;

    const calculationIdentifiers = new Set(gateTaskConfigs.map(({ calculationIdentifier }) => calculationIdentifier).filter(nonNullable));

    const labels = Object.fromEntries(
        [...calculationIdentifiers].map((calculationIdentifier) => [
            calculationIdentifier,
            translate(translationKeys.VDLANG_DASHBOARDS_ROLLING_FORECAST_CUMULATED_LEGEND, {
                identifier: translate(`${translationKeys.VDLANG_CALCULATION_IDENTIFIER}.${calculationIdentifier}`),
            }),
        ]),
    );

    const legendTypes: Record<string, "circle" | "line" | "linedashed"> = Object.fromEntries(
        Object.entries(calculationDisplayModes).map(
            ([calculationIdentifier, mode]) =>
                [labels[calculationIdentifier], mode === RollingForecastDataRepresentation.Line ? "line" : "circle"] as const,
        ),
    );
    legendTypes[translate(translationKeys.VDLANG_DASHBOARDS_ROLLING_FORECAST_TARGET)] =
        targetPresentation === RollingForecastDataRepresentation.Line ? "linedashed" : "circle";

    const tooltipProps = useTooltipProps({ legendTypes, hideSummary: true, deduplicateBy: "name" });

    const theme = useTheme();

    const cumulatedData = useMemo(() => {
        const sorted = sortBy(data, ["year", "month"]);
        return sorted.reduce((cumulated, month, index) => {
            const id = generateTargetMonthId(month.year, month.month);
            const item = { ...month, [TARGET_DATAKEY]: targetValues[id] ?? 0 };
            if (index > 0) {
                item.effects = mergeWith({ ...month.effects }, cumulated[index - 1].effects, (a: number, b: number) => a + b);
                item.rollingEffects = mergeWith(
                    { ...month.rollingEffects },
                    cumulated[index - 1].rollingEffects,
                    (a: number, b: number) => a + b,
                );
                item.rollingEffects[SUM_DATAKEY] =
                    item.rollingEffects[GlobalCalculationIdentifier.Forecast] + item.rollingEffects[GlobalCalculationIdentifier.Actual];
            }
            cumulated.push(item);
            return cumulated;
        }, [] as Data[]);
    }, [data, targetValues]);

    const assignedColors = getColorMapping(dashboardColors, extendedFieldOptions);

    const visibleDataSeries = Object.entries(calculationDisplayModes).filter(
        ([_, mode]) => mode !== RollingForecastDataRepresentation.None,
    );

    // Order by [presentation, gatetaskconfigorder]
    const orderedDataSeries = sortBy(
        visibleDataSeries,
        // recharts uses painters algorithm, so paint Line components last for them to appear on top of all other components
        ([_, mode]) => (mode === RollingForecastDataRepresentation.Line ? 1 : 0),
        ([calculationIdentifier]) => extendedFieldOptions.findIndex((option) => option.calculationIdentifier === calculationIdentifier),
    );
    return (
        <ResponsiveContainer>
            <ComposedChart data={cumulatedData} stackOffset="sign" barGap={2}>
                <CartesianGrid strokeDasharray="4" vertical={false} />
                <XAxis
                    {...monthXAxisProps}
                    dataKey={xAxisAccessor}
                    padding={{
                        left: stripPxSuffix(theme.spacing(5)),
                        right: stripPxSuffix(theme.spacing(5)),
                    }}
                />
                <YAxis {...currencyYAxisProps} />
                <Legend {...legendProps} />
                <Tooltip
                    {...tooltipProps}
                    formatter={(value: number | string) => formatCurrency(value) ?? value}
                    labelFormatter={(label) => moment(label).format("MMM YYYY")}
                />
                {config.showCombinedACT
                    ? [
                          <defs key="defs">
                              <linearGradient id="fc_act_block_gradient" gradientTransform="rotate(90)">
                                  <stop offset="0%" stopColor={assignedColors[GlobalCalculationIdentifier.Actual]} stopOpacity={1} />
                                  <stop offset="50%" stopColor={assignedColors[GlobalCalculationIdentifier.Actual]} stopOpacity={1} />
                                  <stop offset="50%" stopColor={assignedColors[ROLLING_FORECAST_DATAKEY]} stopOpacity={1} />
                                  <stop offset="100%" stopColor={assignedColors[ROLLING_FORECAST_DATAKEY]} stopOpacity={1} />
                              </linearGradient>
                          </defs>,
                          <Bar
                              key={`bar-combined-${GlobalCalculationIdentifier.Actual}`}
                              name={labels[GlobalCalculationIdentifier.Actual]}
                              dataKey={`rollingEffects.${GlobalCalculationIdentifier.Actual}`}
                              fill={assignedColors[GlobalCalculationIdentifier.Actual]}
                              legendType="none" // do not show again in legend, there is already a data series with that name
                              stackId={-1} // use negative value so stacked bar appears right of the unstacked bars
                          >
                              {config.showSums ? (
                                  <ShortNoCodeCurrencyLabelList
                                      stackDataKeys={[
                                          `rollingEffects.${GlobalCalculationIdentifier.Actual}`,
                                          `rollingEffects.${GlobalCalculationIdentifier.Forecast}`,
                                      ]}
                                  />
                              ) : null}
                          </Bar>,
                          // Add additional data series for FC + ACT that can be shown in tooltip + legend
                          <Line
                              key={`bar-combined-${GlobalCalculationIdentifier.Forecast}-${GlobalCalculationIdentifier.Actual}`}
                              name={translate(translationKeys.VDLANG_DASHBOARDS_ROLLING_FORECAST_FC_ACT_ROLLING_FORECAST)}
                              dataKey={`rollingEffects.${SUM_DATAKEY}`}
                              strokeWidth={0} // Make line invisible but still set stroke color which is then used for legend and tooltip
                              stroke="url(#fc_act_block_gradient)" // use gradient to make circle two-colored
                              legendType="circle"
                          />,
                          <Bar
                              key={`bar-combined-${GlobalCalculationIdentifier.Forecast}`}
                              name={translate(translationKeys.VDLANG_DASHBOARDS_ROLLING_FORECAST_FC_ROLLING_FORECAST)}
                              dataKey={ROLLING_FORECAST_DATAKEY}
                              fill={assignedColors[ROLLING_FORECAST_DATAKEY]}
                              legendType="circle"
                              stackId={-1} // use negative value so stacked bar appears right of the unstacked bars
                          >
                              {config.showSums ? (
                                  <ShortNoCodeCurrencyLabelList
                                      stackDataKeys={[
                                          `rollingEffects.${GlobalCalculationIdentifier.Actual}`,
                                          `rollingEffects.${GlobalCalculationIdentifier.Forecast}`,
                                      ]}
                                  />
                              ) : null}
                          </Bar>,
                      ]
                    : null}
                {config.targetPresentation === RollingForecastDataRepresentation.Bar ? (
                    <Bar
                        name={translate(translationKeys.VDLANG_DASHBOARDS_ROLLING_FORECAST_TARGET)}
                        dataKey={TARGET_DATAKEY}
                        fill={blueGrey[800]}
                        legendType="circle"
                    >
                        {config.showSums ? <ShortNoCodeCurrencyLabelList stackDataKeys={[TARGET_DATAKEY]} /> : null}
                    </Bar>
                ) : null}
                {orderedDataSeries.map(([calculationIdentifier, mode], index) =>
                    mode === RollingForecastDataRepresentation.Line ? (
                        <Line
                            key={`line-${calculationIdentifier}`}
                            name={labels[calculationIdentifier]}
                            strokeWidth={2}
                            stroke={assignedColors[calculationIdentifier]}
                            dataKey={`effects.${calculationIdentifier}`}
                            dot={{ strokeWidth: 1, r: 4 }}
                        />
                    ) : (
                        <Bar
                            key={`bar-${calculationIdentifier}`}
                            name={labels[calculationIdentifier]}
                            dataKey={`effects.${calculationIdentifier}`}
                            fill={assignedColors[calculationIdentifier]}
                            legendType="circle"
                        >
                            {config.showSums ? <ShortNoCodeCurrencyLabelList stackDataKeys={[`effects.${calculationIdentifier}`]} /> : null}
                        </Bar>
                    ),
                )}
                {config.targetPresentation === RollingForecastDataRepresentation.Line ? (
                    <Line
                        name={translate(translationKeys.VDLANG_DASHBOARDS_ROLLING_FORECAST_TARGET)}
                        strokeWidth={2}
                        stroke={blueGrey[800]}
                        strokeDasharray="4 2"
                        dataKey={TARGET_DATAKEY}
                        dot={false}
                    />
                ) : null}
                <ReferenceLine y={0} stroke={grey[600]} />
            </ComposedChart>
        </ResponsiveContainer>
    );
};

export default RollingForecastChart;
