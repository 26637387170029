import { Grid, Stack, styled, TextField, Typography } from "@mui/material";
import {
    AttributeType,
    FieldDefinitionsDto,
    nonNullable,
    WaterfallBlockData,
    WaterfallSign,
    WaterfallWidgetConfig,
    WidgetConfig,
} from "api-shared";
import { omit } from "lodash";
import { useTranslation } from "react-i18next";
import Select from "../../../../components/input/select/Select";
import FieldTreeInput from "../../../../components/input/tree/FieldTreeInput";
import { useMeasureAttributes } from "../../../../domain/endpoint";
import { translationKeys } from "../../../../translations/main-translations";
import TreeProvider from "../../../TreeProvider";
import { useFieldOptions } from "../../reporting/useFieldOptions";
import WaterfallBlockItem from "../components/WaterfallBlockItem.tsx";
import WaterfallCreateBlockButton from "../components/WaterfallCreateBlockButton";

const WidgetConfigTabContainer = styled("div")(({ theme }) => ({
    padding: theme.spacing(3),
    minHeight: theme.spacing(25),
}));

interface WaterfallConfigTabGeneralProps {
    config: WaterfallWidgetConfig;
    onConfigChange: (newConfig: WidgetConfig, isValid?: boolean) => void;
    disabled: boolean;
    fields: FieldDefinitionsDto;
    fieldOptions: { label: string; value: string }[];
    selectedField?: { label: string; value: string };
}

const WaterfallConfigTabGeneral = ({
    config,
    onConfigChange,
    disabled,
    fields,
    fieldOptions,
    selectedField,
}: WaterfallConfigTabGeneralProps) => {
    const { t } = useTranslation();
    const measureAttributes = useMeasureAttributes();

    const selectedAttribute = measureAttributes.find(({ title }) => title === selectedField?.value)!;
    const isTreeAttribute = selectedAttribute?.type === AttributeType.Tree;
    const fieldOptionValues = useFieldOptions({ definitions: fields, fieldName: selectedField?.value, withDefaults: false }) ?? [];

    const treeField = {
        ...selectedAttribute,
        title: t(translationKeys.VDLANG_DASHBOARDS_WATERFALL_CONFIG_FIELD_VALUES),
        mandatory: false,
        value: selectedField?.value ?? "",
        options: selectedAttribute?.options ?? {},
        quantity: 0,
    };

    const updateSelectedFieldValues = (newValue: number[] | number | null) => {
        onConfigChange({
            ...config,
            fieldValues: [newValue].flat().filter(nonNullable).map(String),
        });
    };

    const selectedFieldValues = config.fieldValues
        .map((value) => fieldOptionValues.find((v) => v.value == value) ?? null)
        .filter(nonNullable);

    let selectedFieldValuesAsArray: number[] = [];
    if (Array.isArray(selectedFieldValues)) {
        selectedFieldValuesAsArray = selectedFieldValues.map(({ value }) => Number(value));
    } else if (selectedFieldValues != null) {
        selectedFieldValuesAsArray = [Number(selectedFieldValues)];
    }

    const addNewBlock = () => {
        const newBlock: WaterfallBlockData = {
            name: t(translationKeys.VDLANG_DASHBOARDS_WATERFALL_CONFIG_BLOCK_NAME_DEFAULT),
            sign: WaterfallSign.Plus,
            fieldValues: [],
            resultName: t(translationKeys.VDLANG_DASHBOARDS_WATERFALL_CONFIG_RESULT_PLACEHOLDER),
        };
        onConfigChange({
            ...config,
            blocks: [...config.blocks, newBlock],
        });
    };

    const updateBlock = (index: number, updatedBlock: WaterfallBlockData) => {
        const updatedBlocks = [...config.blocks];
        updatedBlocks[index] = updatedBlock;
        onConfigChange({ ...config, blocks: updatedBlocks });
    };

    const removeBlock = (index: number) => {
        const updatedBlocks = [...config.blocks];
        updatedBlocks.splice(index, 1);
        onConfigChange({ ...config, blocks: updatedBlocks });
    };

    return (
        <WidgetConfigTabContainer>
            <Grid spacing={2} container>
                <Grid item xs={6}>
                    <TextField
                        value={config.initialBlockName}
                        onChange={(event) => onConfigChange({ ...config, initialBlockName: event.target.value })}
                        label={t(translationKeys.VDLANG_DASHBOARDS_WATERFALL_CONFIG_INITIAL_NAME)}
                        margin="none"
                        disabled={disabled}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={6}>
                    <Select
                        value={selectedField}
                        options={fieldOptions}
                        onChange={(option) => {
                            return (
                                option != null &&
                                onConfigChange({
                                    ...config,
                                    attribute: option.value,
                                    fieldValues: [],
                                    scope: {
                                        ...config.scope,
                                        attributes: omit(config.scope.attributes, option.value),
                                    },
                                    blocks: config.blocks.map((block) => ({ ...block, fieldValues: [] })),
                                })
                            );
                        }}
                        label={t(translationKeys.VDLANG_DASHBOARDS_WATERFALL_CONFIG_ATTRIBUTE)}
                        menuPortalTarget={document.body}
                        margin="none"
                        fullWidth
                        isMulti={false}
                        isDisabled={disabled}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Stack gap={2}>
                        {isTreeAttribute ? (
                            <TreeProvider
                                field={treeField}
                                component={FieldTreeInput}
                                componentProps={{
                                    field: treeField,
                                    translate: t,
                                    updateValue: updateSelectedFieldValues,
                                    isMulti: true,
                                    value: selectedFieldValuesAsArray,
                                    label: treeField.title,
                                    disabled,
                                    narrowSelection: false,
                                }}
                            />
                        ) : (
                            <Select
                                value={selectedFieldValues}
                                options={fieldOptionValues}
                                onChange={(option) =>
                                    option != null && onConfigChange({ ...config, fieldValues: option.map((v) => v.value) })
                                }
                                label={t(translationKeys.VDLANG_DASHBOARDS_WATERFALL_CONFIG_FIELD_VALUES)}
                                menuPortalTarget={document.body}
                                margin="none"
                                fullWidth
                                isMulti
                                isSearchable
                                isDisabled={disabled}
                            />
                        )}
                        <Stack spacing={1}>
                            <Typography variant="subtitle1">{t(translationKeys.VDLANG_DASHBOARDS_WATERFALL_CONFIG_BLOCKS)}</Typography>
                            <Stack spacing={1}>
                                {config.blocks.map((block, index) => {
                                    return (
                                        <WaterfallBlockItem
                                            key={index}
                                            index={index}
                                            item={block}
                                            isTreeAttribute={isTreeAttribute}
                                            fieldOptionValues={fieldOptionValues}
                                            treeField={treeField}
                                            disabled={disabled}
                                            onItemUpdate={(index, updatedItem) => updateBlock(index, updatedItem)}
                                            onDelete={() => removeBlock(index)}
                                        />
                                    );
                                })}
                            </Stack>
                            <WaterfallCreateBlockButton disabled={disabled} onClick={addNewBlock} />
                        </Stack>
                    </Stack>
                </Grid>
            </Grid>
        </WidgetConfigTabContainer>
    );
};

export default WaterfallConfigTabGeneral;
