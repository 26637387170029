import { useTheme } from "@mui/material";
import { blueGrey, grey } from "@mui/material/colors";
import { LegendLayout, TooltipLayout } from "api-shared";
import { useTranslation } from "react-i18next";
import { Bar, CartesianGrid, ComposedChart, Label, Legend, Line, ReferenceLine, Tooltip, XAxis, YAxis } from "recharts";
import ResponsiveContainer from "../../../components/ResponsiveContainer";
import useCurrency from "../../../hooks/useCurrency";
import { translationKeys } from "../../../translations/main-translations";
import TargetChip from "../charts/TargetChip";
import { useCurrencyYAxisProps, useDashboardColors, useLegendProps, useTooltipProps, useWeekXAxisProps } from "../charts/hooks";
import { getBarSegments } from "../charts/utils";
import { ExtendableCircleLegend } from "../reporting/ExtendableCircleLegend";
import { FieldOption } from "../reporting/useFieldOptions";

export type TimelineChartWeekData = {
    week: number;
    carryOver: number;
    curves: Record<string, number>;
    values: Record<string, number>;
};

interface TimelineChartProps {
    data: TimelineChartWeekData[];
    stacks: FieldOption[];
    target: number | null;
    axisMinValue: number | null;
    axisMaxValue: number | null;
    timeFrame: number;
}

const TimelineChart = ({ data, target, stacks, axisMinValue, axisMaxValue, timeFrame }: TimelineChartProps) => {
    const theme = useTheme();
    const colors = useDashboardColors();
    const { t } = useTranslation();
    const currencyYAxisProps = useCurrencyYAxisProps(target ?? undefined, axisMinValue, axisMaxValue);
    const weekXAxisProps = useWeekXAxisProps();
    const legendProps = useLegendProps();

    const { formatCurrency, formatCurrencyShort } = useCurrency();

    const barSegments = getBarSegments(stacks, colors);
    // show only curves that have at least one data point defined so others do not appear in tooltip and legend
    const visibleCurves = barSegments
        .filter(({ key }) => data.some(({ curves }) => curves[key] != null))
        .map((curve) => ({
            ...curve,
            label: t(translationKeys.VDLANG_DASHBOARDS_TIMELINE_WIDGET_FLIGHT_PATH_LEGEND_LABEL, { level: curve.label }),
        }));

    const legendTypes: Record<string, "circle" | "line" | "linedashed"> = {};
    visibleCurves.forEach(({ label }) => {
        legendTypes[label] = "line";
    });

    const tooltipProps = useTooltipProps({
        legendTypes,
        isFlightPath: visibleCurves.length !== 0,
        showPercentageShares: true,
        tooltipLayout: TooltipLayout.Reversed,
        hideEmptyBarTooltip: true,
    });

    return (
        <ResponsiveContainer>
            <ComposedChart data={data} stackOffset="sign">
                <CartesianGrid strokeDasharray="4" vertical={false} />

                <Legend
                    {...legendProps}
                    content={
                        target != null ? (
                            <ExtendableCircleLegend legendLayout={LegendLayout.Reversed}>
                                <TargetChip
                                    size="small"
                                    label={t(translationKeys.VDLANG_DASHBOARDS_LIVE_RUN_UP_TARGET_CHIP, {
                                        value: formatCurrencyShort(target),
                                    })}
                                />
                            </ExtendableCircleLegend>
                        ) : (
                            <ExtendableCircleLegend legendLayout={LegendLayout.Reversed} />
                        )
                    }
                />

                <XAxis {...weekXAxisProps} dataKey="week">
                    <Label
                        value={t(translationKeys.VDLANG_DASHBOARDS_TIMELINE_WIDGET_WEEK_AXIS_LABEL, { timeFrame })}
                        position="insideBottom"
                        fill="currentColor"
                        fontFamily={theme.typography.fontFamily}
                        fontSize={theme.typography.caption.fontSize}
                        offset={-5}
                    />
                </XAxis>

                <YAxis {...currencyYAxisProps} />

                <Tooltip
                    {...tooltipProps}
                    formatter={(value: number | string) => formatCurrency(value) ?? value}
                    labelFormatter={(label) => {
                        return `${t(translationKeys.VDLANG_DASHBOARDS_TIMELINE_WIDGET_TOOLTIP_WEEK)} ${label}`;
                    }}
                />

                <Bar
                    name={t(translationKeys.VDLANG_DASHBOARDS_TIMELINE_WIDGET_LEGEND_CARRY_OVER)}
                    key="carryOver"
                    dataKey="carryOver"
                    stackId="0" // all bars should be stacked
                    fill={grey[600]}
                    legendType="circle"
                />
                {barSegments.map(({ label, key, color }) => (
                    <Bar
                        name={label}
                        key={`bar_${key}`}
                        dataKey={`values.${key}`}
                        stackId="0" // all bars should be stacked
                        fill={color}
                        legendType="circle"
                    />
                ))}

                {visibleCurves.map(({ label, key, color }) => (
                    <Line
                        key={`curve_${key}`}
                        name={label}
                        // monotone interpolation seems to be good here, see https://d3js.org/d3-shape/curve
                        type="monotone"
                        dataKey={`curves.${key}`}
                        stroke={color}
                        strokeWidth={2}
                        dot={false}
                        activeDot={false}
                    />
                ))}

                {target != null && (
                    <>
                        <ReferenceLine y={target} stroke={blueGrey[800]} strokeDasharray="4" strokeWidth={2} />
                        <Line name={t(translationKeys.VDLANG_DASHBOARDS_TIMELINE_WIDGET_LEGEND_TARGET)} stroke={blueGrey[800]} />
                    </>
                )}

                <ReferenceLine y={0} stroke={grey[600]} />
            </ComposedChart>
        </ResponsiveContainer>
    );
};

export default TimelineChart;
