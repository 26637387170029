import { useTheme } from "@mui/material";

export const OUTLINE_FILTER_ID = "dynamic-outline-filter";

const OutlineSvgFilter = () => {
    const theme = useTheme();

    return (
        <defs>
            <filter id={OUTLINE_FILTER_ID}>
                <feMorphology in="SourceAlpha" result="DILATED" operator="dilate" radius="0" />

                <feFlood floodColor={theme.palette.common.white} floodOpacity="1" result="WHITE" />
                <feComposite in="WHITE" in2="DILATED" operator="in" result="OUTLINE" />

                <feMerge>
                    <feMergeNode in="OUTLINE" />
                    <feMergeNode in="SourceGraphic" />
                </feMerge>
            </filter>
        </defs>
    );
};

OutlineSvgFilter.displayName = "Customized";

export default OutlineSvgFilter;
