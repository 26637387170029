import { QueryMeta, useQuery } from "@tanstack/react-query";
import { MeasurePotentialSums, MeasuresPotentialRequestBody } from "api-shared";
import { ApiRequestOptions, apiPost } from "../../lib/api";

const MEASURES_PATH = "measures";

export const MeasureListKeys = {
    all: [MEASURES_PATH, "list"] as const,
    potential: () => [...MeasureListKeys.all, "potential"] as const,
};

function getPotentialSum({ filter, filterId, measureConfigIds, scope }: MeasuresPotentialRequestBody, options: ApiRequestOptions) {
    const data = {
        measureConfigIds,
        filter,
        scope,
        filterId,
    };

    const url = `${MEASURES_PATH}/potential`;
    return apiPost<MeasurePotentialSums>(url, data, options);
}

export const useMeasuresPotential = (key: string, options: MeasuresPotentialRequestBody, enabled?: boolean, metaOptions?: QueryMeta) => {
    return useQuery({
        queryKey: [...MeasureListKeys.potential(), key, options] as const,
        queryFn: ({ queryKey, signal }) => getPotentialSum(queryKey[4], { signal }),
        keepPreviousData: true,
        enabled,
        meta: metaOptions,
    });
};
