import { Skeleton, Stack, Typography, alpha, styled } from "@mui/material";
import { AggregationMethod } from "api-shared";
import { times } from "lodash";
import useCurrency from "../../../hooks/useCurrency";
import { useLanguage } from "../../../hooks/useLanguage";
import { formatRangeCompact } from "../../../lib/formatters";
import { Language } from "../../../translations/main-translations";
import { scaleStep } from "./utils";

const LegendStepSymbol = styled("div")<{ color: string; opacity: number }>(({ theme, color, opacity }) => ({
    backgroundColor: alpha(color, opacity),
    border: `1px solid ${theme.palette.divider}`,
    height: theme.spacing(),
}));

const legendStepSymbolClass = "legend-step-symbol";
const Root = styled(Stack)(({ theme }) => ({
    [`& > :first-of-type .${legendStepSymbolClass}`]: {
        borderTopLeftRadius: theme.shape.borderRadius,
        borderBottomLeftRadius: theme.shape.borderRadius,
    },
    [`& > :last-of-type .${legendStepSymbolClass}`]: {
        borderTopRightRadius: theme.shape.borderRadius,
        borderBottomRightRadius: theme.shape.borderRadius,
    },
}));

type WhiteSpotLegendProps = {
    stepCount: number;
    color: string;
    aggregation?: AggregationMethod;
    maxReferenceValue?: number;
};

const WhiteSpotLegend = ({ stepCount: inputStepCount, color, maxReferenceValue, aggregation }: WhiteSpotLegendProps) => {
    const language = useLanguage();
    const { currencyIsoCode } = useCurrency();

    const target = Math.max(maxReferenceValue ?? 0, 0);

    let stepCount = inputStepCount;
    if (aggregation === AggregationMethod.Count && target <= inputStepCount - 2) {
        // Avoid fractional step boundaries for small targets in count mode by reducing the number of steps
        stepCount = Math.max(target + 2, 1);
    } else if (aggregation === AggregationMethod.Sum && target === 0) {
        // Show only a single step with 0
        stepCount = 1;
    }

    // Generate only stepCount - 1 steps here, as the last step is handled with out-of-bounds index and should not be included in stepSize
    // calculation
    // saneReference should be the intervalEnd of the second last step -> use stepCount - 1
    const valueStepCount = Math.max(stepCount - 1, 1);
    const intervalEnds = times(valueStepCount).map((i) => {
        const scaledStep = scaleStep(valueStepCount - 1, target, i, valueStepCount);

        // For the count we want only integer values
        return aggregation === AggregationMethod.Sum ? scaledStep : Math.floor(scaledStep);
    });

    function intervalFormatter(index: number) {
        if (maxReferenceValue == null) {
            return <Skeleton width={50} />;
        }

        const formattedRange = formatRangeCompact(intervalEnds, index);

        if (aggregation === AggregationMethod.Count) {
            return formattedRange;
        }

        // poor mans currency formatter, as long as we cannot use Int.NumberFormat.formatRange
        return language === Language.EN ? `${currencyIsoCode} ${formattedRange}` : `${formattedRange} ${currencyIsoCode}`;
    }

    return (
        <Root direction="row" spacing={0.5}>
            {times(stepCount).map((i) => (
                <Stack spacing={0.5} key={i} flexGrow={1}>
                    <LegendStepSymbol
                        key={i}
                        className={legendStepSymbolClass}
                        color={color}
                        opacity={scaleStep(stepCount - 1, 1, i, stepCount)}
                    />
                    <Typography variant="caption" color="textSecondary" alignSelf="center">
                        {intervalFormatter(i)}
                    </Typography>
                </Stack>
            ))}
        </Root>
    );
};

export default WhiteSpotLegend;
