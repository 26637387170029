import { Stack, TextField, styled } from "@mui/material";
import { FilterDefinition, validateBasicMeasureFilterAndScopeConfig } from "api-shared";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Form from "../../components/Form";
import { useClientFiscalYear, useCurrentClient } from "../../domain/client";
import { useMeasureAttributes } from "../../domain/endpoint";
import { useMeasureConfigs } from "../../domain/measure-config";
import { useNonDeletedUsers } from "../../domain/users";
import { translationKeys } from "../../translations/main-translations";
import ScopeSelect from "../measures/preferences/ScopeSelect";
import FilterForm from "../measures/preferences/filter-configuration/FilterForm";
import { IWidgetConfigFormProps } from "./WidgetConfigDialog";
import WidgetConfigTab from "./WidgetConfigTab";
import WidgetConfigTabs from "./WidgetConfigTabs";
import WidgetDescriptionField from "./WidgetDescriptionField";

const WidgetConfigTabContainer = styled("div")(({ theme }) => ({
    padding: theme.spacing(3),
    minHeight: theme.spacing(66), // fix height across all tabs, the maximum height is dicated by the ColumnConfigForm component
}));

const PotentialSumWidgetConfigForm = ({
    config,
    name,
    description,
    onNameChange,
    onDescriptionChange,
    onConfigChange,
    onSubmit,
    disabled,
}: IWidgetConfigFormProps) => {
    const [openTab, setOpenTab] = useState(0);
    const { t: translate } = useTranslation();

    const users = useNonDeletedUsers();
    const measureAttributes = useMeasureAttributes();
    const measureConfigs = useMeasureConfigs();
    const [isValidFilter, setIsValidFilter] = useState(true);

    const client = useCurrentClient();
    const fiscalYear = useClientFiscalYear();

    if (!validateBasicMeasureFilterAndScopeConfig(config)) {
        return null;
    }

    const handleFilterChange = (newFilter: FilterDefinition, isValid: boolean) => {
        setIsValidFilter(isValid);
        onConfigChange({ ...config, filter: newFilter }, isValid);
    };

    return (
        <Form onSubmit={onSubmit}>
            <WidgetConfigTabs value={openTab} onChange={(event, newValue) => setOpenTab(newValue)}>
                <WidgetConfigTab label={translate(translationKeys.VDLANG_DASHBOARDS_POTENTIAL_KPI_CONFIG_TAB_GENERAL)} />
                <WidgetConfigTab label={translate(translationKeys.VDLANG_DASHBOARDS_POTENTIAL_KPI_CONFIG_TAB_DESCRIPTION)} />
                <WidgetConfigTab label={translate(translationKeys.VDLANG_DASHBOARDS_POTENTIAL_KPI_CONFIG_TAB_SCOPE)} />
                <WidgetConfigTab label={translate(translationKeys.VDLANG_DASHBOARDS_POTENTIAL_KPI_CONFIG_TAB_FILTER)} />
            </WidgetConfigTabs>
            {openTab === 0 ? (
                <WidgetConfigTabContainer>
                    <TextField
                        fullWidth
                        value={name}
                        onChange={(e) => onNameChange(e.target.value)}
                        label={translate(translationKeys.VDLANG_DASHBOARDS_POTENTIAL_KPI_CONFIG_NAME)}
                        disabled={disabled}
                    />
                </WidgetConfigTabContainer>
            ) : null}
            {openTab === 1 ? (
                <WidgetConfigTabContainer>
                    <WidgetDescriptionField
                        description={description}
                        onDescriptionChange={onDescriptionChange}
                        label={translate(translationKeys.VDLANG_DASHBOARDS_POTENTIAL_KPI_CONFIG_TAB_DESCRIPTION)}
                        disabled={disabled}
                    />
                </WidgetConfigTabContainer>
            ) : null}
            {openTab === 2 ? (
                <WidgetConfigTabContainer>
                    <Stack>
                        <Stack width="50%">
                            <ScopeSelect
                                value={config.scope}
                                onChange={(scope) => onConfigChange({ ...config, scope }, isValidFilter)}
                                measureConfigs={measureConfigs}
                                measureConfigIds={measureConfigs.map((mc: any) => mc.id)}
                                financialMonth={fiscalYear}
                                yearsBefore={client.fiscalYearRangePast}
                                yearsAfter={client.fiscalYearRangeFuture}
                                translate={translate}
                                disabled={disabled}
                                menuPortalTarget={document.body}
                            />
                        </Stack>
                    </Stack>
                </WidgetConfigTabContainer>
            ) : null}
            {openTab === 3 ? (
                <WidgetConfigTabContainer>
                    <FilterForm
                        filterDefinition={config.filter}
                        onChange={handleFilterChange}
                        measureAttributes={measureAttributes}
                        users={users}
                        translate={translate}
                        disabled={disabled}
                    />
                </WidgetConfigTabContainer>
            ) : null}
        </Form>
    );
};

export default PotentialSumWidgetConfigForm;
