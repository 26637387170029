import { Skeleton, Stack } from "@mui/material";

const BarHeights = ["100%", "20%", "80%", "20%", "60%", "30%", "90%"];
const BarOffsets = ["0%", "80%", "0%", "60%", "0%", "60%", "0%"];
const BottomLabelOffset = "80px";

const WaterfallChartSkeleton = () => {
    return (
        <Stack height={`calc(100% - ${BottomLabelOffset})`} direction="row" flexWrap="nowrap">
            <Stack width="100%" direction="column" display="flex" marginRight={2}>
                <Stack height="100%" direction="row" display="flex" justifyContent="space-between" alignItems="flex-end" marginTop={2.5}>
                    {BarHeights.map((height, i) => (
                        <Skeleton
                            key={`skeleton_${i}`}
                            height={height}
                            sx={{ bottom: BarOffsets[i], position: "relative" }}
                            width="12.5%"
                            variant="rectangular"
                        />
                    ))}
                </Stack>
                <Stack display="flex" height={20} justifyContent="flex-end" alignItems="flex-end" paddingBottom={1}>
                    <Skeleton key="skeleton_axis" width="100%" height={2} variant="rectangular" />
                </Stack>
            </Stack>
        </Stack>
    );
};
export default WaterfallChartSkeleton;
