import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import { ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import { nonNullable, DashboardDto } from "api-shared";
import { useTranslation } from "react-i18next";
import MoreActionsMenu from "../../components/MoreActionsMenu";
import Tooltip from "../../components/Tooltip";
import DeleteDialog from "../../components/dialogues/DeleteDialog";
import { useDeleteDashboardMutation } from "../../domain/dashboards";
import useDialog from "../../hooks/useDialog";
import { tryTranslate } from "../../lib/translate";
import { translationKeys } from "../../translations/main-translations";
import UpdateDashboardDialog from "./UpdateDashboardDialog";
import DuplicateDashboardDialog from "./select/DuplicateDashboardDialog";

interface DashboardContextMenuProps {
    dashboard: DashboardDto;
    isDashboardOwner: boolean;
    disabledDelete?: boolean;
    onDashboardDuplicated: (dashboard: DashboardDto) => void;
    onDashboardDelete: () => void;
}

const DashboardContextMenu = ({
    dashboard,
    isDashboardOwner,
    disabledDelete = false,
    onDashboardDuplicated,
    onDashboardDelete,
}: DashboardContextMenuProps) => {
    const { t: translate } = useTranslation();

    const deleteDashboardMutation = useDeleteDashboardMutation();

    const updateDashboardDialog = useDialog();
    const deleteDashboardDialog = useDialog();
    const duplicateDashboardDialog = useDialog();

    const menuActions = [
        isDashboardOwner ? (
            <MenuItem key="update" onClick={updateDashboardDialog.open}>
                <ListItemIcon>
                    <EditIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary={translate(translationKeys.VDLANG_DASHBOARDS_CONTEXT_UPDATE)} />
            </MenuItem>
        ) : null,
        <MenuItem key="duplicate" onClick={duplicateDashboardDialog.open} divider={isDashboardOwner}>
            <ListItemIcon>
                <FileCopyIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary={translate(translationKeys.VDLANG_DASHBOARDS_DUPLICATE)} primaryTypographyProps={{ noWrap: true }} />
        </MenuItem>,
        isDashboardOwner ? (
            <Tooltip key="delete" title={disabledDelete ? translate(translationKeys.VDLANG_DASHBOARDS_CONTEXT_DELETE_DISABLED_HINT) : ""}>
                {/* Tooltip cannot be displayed on disabled elements so we need a wrapper */}
                <div>
                    <MenuItem onClick={deleteDashboardDialog.open} disabled={disabledDelete}>
                        <ListItemIcon>
                            <DeleteIcon color="error" fontSize="small" />
                        </ListItemIcon>
                        <ListItemText
                            primary={translate(translationKeys.VDLANG_DASHBOARDS_CONTEXT_DELETE)}
                            primaryTypographyProps={{ color: "error" }}
                        />
                    </MenuItem>
                </div>
            </Tooltip>
        ) : null,
    ].filter(nonNullable);

    return (
        <>
            <DuplicateDashboardDialog
                open={duplicateDashboardDialog.isOpen}
                onClose={duplicateDashboardDialog.close}
                onDashboardDuplicated={onDashboardDuplicated}
                dashboard={dashboard}
                key={`duplicate_${dashboard.id}`}
            />
            <UpdateDashboardDialog
                dashboard={dashboard}
                open={updateDashboardDialog.isOpen}
                onClose={updateDashboardDialog.close}
                key={`update_${dashboard.id}`}
            />
            <DeleteDialog
                open={deleteDashboardDialog.isOpen}
                onClose={deleteDashboardDialog.close}
                onDelete={() => deleteDashboardMutation.mutate({ dashboardId: dashboard.id }, { onSuccess: onDashboardDelete })}
                translate={translate}
                title={translate(translationKeys.VDLANG_DASHBOARDS_DELETE_TITLE, {
                    dashboard: tryTranslate(translate, dashboard.name),
                })}
                hideDescription
            />
            <MoreActionsMenu
                buttonProps={{ "aria-label": translate(translationKeys.VDLANG_DASHBOARDS_CONTEXT_MENU), size: "small" }}
                actions={menuActions}
            />
        </>
    );
};

export default DashboardContextMenu;
