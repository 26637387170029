import { FormLabel, Grid, styled } from "@mui/material";
import { RollingForecastWidgetConfig } from "api-shared";
import { Moment } from "moment";
import { SimpleCurrencyInput } from "../../../components/input/SimpleCurrencyInput";
import { generateTargetMonthId } from "./utils";

export const TargetLabel = styled(FormLabel)(({ theme }) => ({
    ...theme.typography.body2,
    color: theme.palette.text.primary,
    textAlign: "right",
}));

type TargetConfigurationColumnProps = {
    months: Moment[];
    targetValues: RollingForecastWidgetConfig["targetValues"];
    onChange: (id: string, newValue: number) => void;
    disabled?: boolean;
};

function TargetConfigurationColumn({ months, targetValues, onChange, disabled }: Readonly<TargetConfigurationColumnProps>) {
    return (
        <Grid container rowSpacing={1} columnSpacing={2} alignItems="center">
            {months
                .map((month) => [month, month.format("MMMM YYYY")] as const)
                .map(([month, label]) => [
                    <Grid item xs={6} key={`${label}_label`} component={TargetLabel} htmlFor={label}>
                        {label}
                    </Grid>,
                    <Grid item xs={6} key={`${label}_input`}>
                        <SimpleCurrencyInput
                            value={targetValues[generateTargetMonthId(month)] ?? null}
                            onChange={(newValue) => newValue !== null && onChange(generateTargetMonthId(month), newValue)}
                            inputProps={{ id: label }}
                            disabled={disabled}
                        />
                    </Grid>,
                ])}
        </Grid>
    );
}

export default TargetConfigurationColumn;
