import AddIcon from "@mui/icons-material/Add";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import {
    Button,
    Divider,
    ListItemText,
    ListSubheader,
    MenuItem,
    MenuList as MuiMenuList,
    Popover,
    Stack,
    styled,
    Typography,
} from "@mui/material";
import { LatestViewedDashboardsDto, nonNullable, DashboardDto } from "api-shared";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import SearchInput from "../../components/input/SearchInput";
import { UnstyledLink } from "../../components/Link";
import Tooltip from "../../components/Tooltip";
import { useCurrentUser } from "../../domain/users";
import useDialog from "../../hooks/useDialog";
import useMenu from "../../hooks/useMenu";
import { RouteFor } from "../../lib/routes";
import { tryTranslate } from "../../lib/translate";
import { translationKeys } from "../../translations/main-translations";
import CreateDashboardDialog from "./select/CreateDashboardDialog";

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
    "&.Mui-selected": {
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.primary.main,
        "&:hover": {
            backgroundColor: theme.palette.primary.dark,
        },
    },
}));

const MenuList = styled(MuiMenuList)(({ theme }) => ({
    minWidth: theme.spacing(40),
    maxWidth: theme.spacing(60),
    paddingTop: 0,
    paddingBottom: 0,
    maxHeight: `calc(100vh - ${theme.spacing(18)})`,
    overflowY: "auto",
    overflowX: "hidden",
}));

const Subheader = styled(ListSubheader)(({ theme }) => ({
    ...theme.typography.overline,
    paddingTop: theme.spacing(1),
}));

const AlignedButton = styled(Button)(() => ({
    justifyContent: "flex-start",
    alignItems: "center",
    textTransform: "none",
}));

const MenuListItemText = styled(ListItemText)(() => ({
    overflow: "hidden",
    textOverflow: "ellipsis",
}));

interface DashboardSelectProps {
    value?: DashboardDto;
    onChange: (dashboard: DashboardDto) => void;
    dashboards: DashboardDto[];
    dashboardViews: LatestViewedDashboardsDto;
}

interface DashboardMenuItemProps {
    dashboard: DashboardDto;
    isSelected: boolean;
    onClick: (dashboard: DashboardDto) => void;
}

const DashboardMenuItem = ({ dashboard, isSelected, onClick }: DashboardMenuItemProps) => {
    const { t } = useTranslation();

    return (
        <StyledMenuItem key={dashboard.id} value={dashboard.id} onClick={() => onClick(dashboard)} selected={isSelected} dense>
            <Tooltip title={tryTranslate(t, dashboard.name)} onlyOverflowing>
                <UnstyledLink key={dashboard.id} to={RouteFor.dashboard.forId(dashboard.id)} overflow="hidden">
                    <MenuListItemText
                        disableTypography
                        primary={tryTranslate(t, dashboard.name)}
                        primaryTypographyProps={{ noWrap: true }}
                        sx={{ pl: 3 }}
                    />
                </UnstyledLink>
            </Tooltip>
        </StyledMenuItem>
    );
};

const DashboardSelect = ({ value, onChange, dashboards, dashboardViews }: DashboardSelectProps) => {
    const { t: translate } = useTranslation();

    const [searchKey, setSearchKey] = useState("");

    const dashboardMenu = useMenu();
    const currentUser = useCurrentUser();
    const createDialog = useDialog();

    let title = translate(translationKeys.VDLANG_DASHBOARDS_NO_DASHBOARD);
    if (value !== undefined) {
        title = translate(value.name, { defaultValue: null }) ?? value.name;
    }

    const filteredDashboards = dashboards.filter(({ name }) => {
        return name.toLowerCase().indexOf(searchKey.toLowerCase()) >= 0;
    });

    const handleClose = () => {
        dashboardMenu.close();
        setSearchKey("");
    };

    const handleDashboardChange = (dashboard: DashboardDto) => {
        onChange(dashboard);
        dashboardMenu.close();
        setSearchKey("");
    };

    const handleCreateDashboardDialogOpen = () => {
        createDialog.open();
        dashboardMenu.close();
        setSearchKey("");
    };

    const currentUserDashboards = filteredDashboards.filter((db) => db.userId === currentUser?.id);

    const sharedDashboards = filteredDashboards.filter((db) => db.userId !== currentUser?.id);

    const lastViewedDashboards = dashboardViews
        .map((view) => filteredDashboards.find((dashboard) => dashboard.id == view.dashboardId))
        .filter(nonNullable);

    return (
        <>
            <Popover {...dashboardMenu.menuProps} onClose={handleClose}>
                <Stack direction="column">
                    <SearchInput translate={translate} onChange={setSearchKey} searchKey={searchKey} autoFocus sx={{ p: 1 }} />
                    <Divider />
                    <MenuList>
                        {lastViewedDashboards.length > 0 ? (
                            <>
                                <Subheader>
                                    <Typography fontWeight="medium" color="GrayText" variant="overline">
                                        {translate(translationKeys.VDLANG_DASHBOARDS_LAST_VIEWED)}
                                    </Typography>
                                </Subheader>
                                {lastViewedDashboards.map((dashboard) => (
                                    <DashboardMenuItem
                                        dashboard={dashboard}
                                        onClick={handleDashboardChange}
                                        isSelected={false}
                                        key={`last_${dashboard.id}`}
                                    />
                                ))}
                            </>
                        ) : null}

                        {currentUserDashboards.length > 0 ? (
                            <Subheader>
                                <Typography fontWeight="medium" color="GrayText" variant="overline">
                                    {translate(translationKeys.VDLANG_DASHBOARDS_MY_CREATED)}
                                </Typography>
                            </Subheader>
                        ) : null}
                        {currentUserDashboards.map((dashboard) => (
                            <DashboardMenuItem
                                dashboard={dashboard}
                                key={dashboard.id}
                                isSelected={value?.id === dashboard.id}
                                onClick={handleDashboardChange}
                            />
                        ))}
                        {sharedDashboards.length > 0 ? (
                            <Subheader>
                                <Typography fontWeight="medium" color="GrayText" variant="overline">
                                    {translate(translationKeys.VDLANG_DASHBOARDS_OTHERS)}
                                </Typography>
                            </Subheader>
                        ) : null}
                        {sharedDashboards.map((dashboard) => (
                            <DashboardMenuItem
                                dashboard={dashboard}
                                key={dashboard.id}
                                isSelected={value?.id === dashboard.id}
                                onClick={handleDashboardChange}
                            />
                        ))}
                        {currentUserDashboards.length === 0 && sharedDashboards.length === 0 ? (
                            <Typography color="GrayText" p={2}>
                                {translate(translationKeys.VDLANG_DASHBOARDS_NO_DASHBOARD_FOUND)}
                            </Typography>
                        ) : null}
                    </MenuList>
                    <Divider />
                    <AlignedButton
                        fullWidth
                        variant="text"
                        color="inherit"
                        startIcon={<AddIcon fontSize="small" color="action" sx={{ m: 1 }} />}
                        onClick={handleCreateDashboardDialogOpen}
                    >
                        <Typography variant="subtitle2" noWrap fontWeight={(theme) => theme.typography.fontWeightRegular}>
                            {translate(translationKeys.VDLANG_DASHBOARDS_CREATE)}
                        </Typography>
                    </AlignedButton>
                </Stack>
            </Popover>
            <CreateDashboardDialog open={createDialog.isOpen} onClose={createDialog.close} onSuccess={onChange} />
            <Button
                fullWidth
                variant="text"
                color="inherit"
                onClick={dashboardMenu.open}
                endIcon={<KeyboardArrowDownRoundedIcon color="inherit" />}
            >
                <Typography variant="h5" noWrap>
                    {title}
                </Typography>
            </Button>
        </>
    );
};

export default DashboardSelect;
