import {
    Button,
    buttonBaseClasses,
    Divider,
    formControlClasses,
    Grid,
    ListItemText,
    MenuItem,
    MenuList,
    Popover,
    styled,
    Typography,
} from "@mui/material";
import { FieldDefinitionsDto, MultiFieldValueTargets } from "api-shared";
import { TFunction } from "i18next";
import { Fragment, useState } from "react";
import DeleteIcon from "../../../../components/icons/DeleteIcon";
import Select from "../../../../components/input/select/Select";
import { Option } from "../../../../components/input/select/types";
import { SimpleCurrencyInput } from "../../../../components/input/SimpleCurrencyInput";
import SmallIconButton from "../../../../components/SmallIconButton";
import { StaticBadge } from "../../../../components/StaticBadge";
import { useMeasureFieldDefinitionsQuery } from "../../../../domain/filters";
import useMenu from "../../../../hooks/useMenu";
import { translationKeys } from "../../../../translations/main-translations";
import { ArrowDropDownIcon } from "../../../ideas/IdeaModalSingleUserButton";
import { useFieldOptions } from "../useFieldOptions";
import SearchInput from "../../../../components/input/SearchInput";

const SelectFieldButton = styled(Button)({
    padding: 0,
    [`&.${buttonBaseClasses.root}:hover`]: {
        backgroundColor: "transparent",
    },
});

const FieldsMenuPopoverContainer = styled("div")(({ theme }) => ({
    width: theme.spacing(35),
    [`& .${formControlClasses.root}`]: {
        padding: theme.spacing(),
        borderBottom: `1px solid ${theme.palette.divider}`,
    },
}));

const TargetsGrid = styled("div")(({ theme }) => ({
    display: "grid",
    gap: theme.spacing(1),
    //fixed value for fieldselect to stop overflow
    gridTemplateColumns: "1fr 1fr auto",
    alignItems: "center",
}));

const NoTargetsContainer = styled("div")(({ theme }) => ({
    padding: theme.spacing(1, 0),
}));

type TargetsFormProps = {
    fieldName: string;
    targets: MultiFieldValueTargets;
    targetFieldsOptions: Option<string>[];
    fieldDefinitions?: FieldDefinitionsDto;
    onChange: (targets: MultiFieldValueTargets) => void;
    translate: TFunction;
    disabled?: boolean;
};

const TargetsForm = ({
    fieldName,
    targets,
    targetFieldsOptions,
    fieldDefinitions = {},
    onChange,
    translate,
    disabled,
}: TargetsFormProps) => {
    const fieldsMenu = useMenu();
    const [selectedField, setSelectedField] = useState(fieldName);
    const [searchKey, setSearchKey] = useState("");

    // Set query's enabled flag to false if fieldDefinitions are defined in props
    const fieldDefinitionsQuery = useMeasureFieldDefinitionsQuery(fieldDefinitions == null);
    const firstPivotFieldOptions =
        useFieldOptions({ definitions: fieldDefinitionsQuery.data, fieldName: selectedField, withDefaults: false }) ?? [];

    const selectedFieldOption = targetFieldsOptions.find((o) => o.value === selectedField);

    const isTargetAddedForEveryFieldValue =
        targets[selectedField] !== undefined && firstPivotFieldOptions.length === Object.keys(targets[selectedField]).length;

    const filteredTargetsFieldOptions = targetFieldsOptions.filter(({ label }) => {
        return label.toLowerCase().indexOf(searchKey.toLowerCase()) >= 0;
    });

    function handleUpdateSelectedField(fieldName: string): void {
        setSelectedField(fieldName);
        fieldsMenu.close();
    }

    function handleAddTarget(): void {
        const newTargetFields = { ...targets };
        if (newTargetFields[selectedField] === undefined) {
            newTargetFields[selectedField] = {};
        }
        const [fieldValue] = firstPivotFieldOptions.filter((o) => !Object.keys(newTargetFields[selectedField]).includes(o.value));
        newTargetFields[selectedField] = { ...newTargetFields[selectedField], [fieldValue.value]: null };
        onChange(newTargetFields);
    }

    function handleUpdateFieldValueOption(option: Option<string> | null, field: string, fieldValue: string, target: number | null): void {
        if (option == null) {
            // Ignore attempts to clear
            return;
        }
        const newFieldValue = option.value;
        const newTargetFields = { ...targets };
        delete newTargetFields[field][fieldValue];
        newTargetFields[field][newFieldValue] = target;
        onChange(newTargetFields);
    }

    function handleUpdateTarget(fieldValue: string, target: number | null): void {
        const newTargetFields = { ...targets };
        newTargetFields[selectedField][fieldValue] = target;
        onChange(newTargetFields);
    }

    function handleRemoveTarget(field: string, fieldValue: string): void {
        const newTargetFields = { ...targets };
        delete newTargetFields[field][fieldValue];
        if (Object.keys(newTargetFields[field]).length === 0) {
            delete newTargetFields[field];
        }
        onChange(newTargetFields);
    }

    if (selectedFieldOption === undefined) {
        return null;
    }

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Popover
                    {...fieldsMenu.menuProps}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                    }}
                >
                    <FieldsMenuPopoverContainer>
                        <SearchInput translate={translate} onChange={setSearchKey} searchKey={searchKey} autoFocus sx={{ p: 1 }} />
                        <Divider />
                        <MenuList dense>
                            {filteredTargetsFieldOptions.map((option) => (
                                <MenuItem
                                    key={option.value}
                                    value={option.value}
                                    onClick={() => handleUpdateSelectedField(option.value)}
                                    selected={selectedFieldOption.value === option.value}
                                >
                                    <ListItemText primary={option.label} primaryTypographyProps={{ noWrap: true }} />
                                    {targets[option.value] !== undefined && Object.keys(targets[option.value]).length > 0 && (
                                        <StaticBadge badgeContent={Object.keys(targets[option.value]).length} color="primary" />
                                    )}
                                </MenuItem>
                            ))}
                        </MenuList>
                    </FieldsMenuPopoverContainer>
                </Popover>
                <SelectFieldButton variant="text" color="inherit" onClick={fieldsMenu.open} disableRipple>
                    <Typography variant="body1" component="legend" fontWeight="medium">
                        {selectedFieldOption.label}
                    </Typography>
                    <ArrowDropDownIcon />
                </SelectFieldButton>
            </Grid>
            <Grid item xs={12}>
                {targets[selectedField] !== undefined && Object.keys(targets[selectedField]).length !== 0 ? (
                    <TargetsGrid>
                        {Object.entries(targets[selectedField]).map(([fieldValue, target]) => (
                            <Fragment key={fieldValue}>
                                <Select
                                    value={firstPivotFieldOptions.find((o) => o.value === fieldValue)}
                                    options={firstPivotFieldOptions.filter((o) => !Object.keys(targets[selectedField]).includes(o.value))}
                                    onChange={(option) => handleUpdateFieldValueOption(option, selectedField, fieldValue, target)}
                                    isSearchable
                                    noOptionsMessage={() => translate(translationKeys.VDLANG_SELECT_NO_RESULTS)}
                                    // make sure menu is outside of modal to fix scrolling
                                    menuPortalTarget={document.body}
                                    margin="none"
                                    isDisabled={disabled}
                                />
                                <SimpleCurrencyInput
                                    value={target}
                                    onChange={(target) => handleUpdateTarget(fieldValue, target)}
                                    margin="none"
                                    disabled={disabled}
                                    fullWidth
                                />
                                <SmallIconButton onClick={() => handleRemoveTarget(selectedField, fieldValue)} disabled={disabled}>
                                    <DeleteIcon />
                                </SmallIconButton>
                            </Fragment>
                        ))}
                    </TargetsGrid>
                ) : (
                    <NoTargetsContainer>
                        <Typography color="textSecondary">{translate(translationKeys.VDLANG_TARGETS_FORM_NO_TARGETS)}</Typography>
                    </NoTargetsContainer>
                )}
            </Grid>
            {!disabled ? (
                <Grid item xs={12}>
                    <Button data-testid="add_target" onClick={handleAddTarget} disabled={isTargetAddedForEveryFieldValue}>
                        {translate(translationKeys.VDLANG_TARGETS_FORM_ADD_TARGET)}
                    </Button>
                </Grid>
            ) : null}
        </Grid>
    );
};

export default TargetsForm;
