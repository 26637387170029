import WarningIcon from "@mui/icons-material/WarningRounded";
import { Stack } from "@mui/material";
import { ProjectProgressWidgetConfig, validateProjectProgressWidgetConfig } from "api-shared";
import { isEqual } from "lodash";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Form from "../../../components/Form";
import LoadingAnimation from "../../../components/loading/LoadingAnimation";
import { useMeasureFieldDefinitionsQuery } from "../../../domain/filters";
import { useProjectProgressFields } from "../../../domain/reporting";
import { translationKeys } from "../../../translations/main-translations";
import { IWidgetConfigFormProps } from "../WidgetConfigDialog";
import WidgetConfigTab from "../WidgetConfigTab";
import WidgetConfigTabs from "../WidgetConfigTabs";
import { ProjectProgressConfigFormData } from "./ProjectProgressWidget";
import { useAlphanumericStringSort } from "../../../hooks/useAlphanumericStringSort";
import ProjectProgressConfigTabGeneral from "./tabs/ProjectProgressConfigTabGeneral";
import ProjectProgressConfigTabDescription from "./tabs/ProjectProgressConfigTabDescription";
import ProjectProgressConfigTabScope from "./tabs/ProjectProgressConfigTabScope";
import ProjectProgressConfigTabFilterConfiguration from "./tabs/ProjectProgressConfigTabFilterConfiguration";
import ProjectProgressConfigTabTargets from "./tabs/ProjectProgressConfigTabTargets";
import ProjectProgressConfigTabReferenceValues from "./tabs/ProjectProgressConfigTabReferenceValues";

const ProjectProgressWidgetConfigForm = ({
    config,
    disabled,
    name,
    description,
    onConfigChange,
    onDescriptionChange,
    onNameChange,
    onSubmit,
    data,
}: IWidgetConfigFormProps<ProjectProgressConfigFormData>) => {
    const [openTab, setOpenTab] = useState(0);
    const { t: translate } = useTranslation();
    const { compare } = useAlphanumericStringSort();

    const fieldsQuery = useProjectProgressFields();
    const fieldDefinitionsQuery = useMeasureFieldDefinitionsQuery();

    const [isValidFilter, setIsValidFilter] = useState(true);

    const fields = fieldsQuery.data ?? {};
    const fieldOptions = Object.values(fields)
        .map(({ name }) => ({
            label: translate(name),
            value: name,
        }))
        .toSorted((a, b) => compare(a.label, b.label));

    const selectedField = fieldOptions.find(({ value }) => value === config.recurringAttribute);
    const skipFields = selectedField ? [selectedField.value] : [];

    const updateConfig = (newConfig: ProjectProgressWidgetConfig, overrideIsValidFilter = isValidFilter) => {
        onConfigChange(newConfig, overrideIsValidFilter);
    };

    const referenceValuesFromData = data?.reduce<Record<string, number>>((acc, item) => {
        acc[item.period] = Object.values(item)
            .filter((value): value is number => typeof value === "number")
            .reduce((sum, num) => sum + num, 0);
        return acc;
    }, {});

    if (!fieldsQuery.isSuccess) {
        return <LoadingAnimation />;
    }

    if (!validateProjectProgressWidgetConfig(config)) {
        return <LoadingAnimation />;
    }

    const onReferenceValuesDelete = () => {
        updateConfig({ ...config, referenceValues: null });
    };

    const onReferenceValuesSave = () => {
        updateConfig({
            ...config,
            referenceValues: {
                timestamp: new Date().toISOString(),
                data: referenceValuesFromData ?? {},
                config: {
                    filter: config.filter,
                    start: config.start,
                    end: config.end,
                    scope: config.scope,
                    recurringAttribute: config.recurringAttribute,
                    recurringFieldValues: config.recurringFieldValues,
                    oneTimeFieldValues: config.oneTimeFieldValues,
                },
            },
        });
    };
    const currentConfig = {
        filter: config.filter,
        start: config.start,
        end: config.end,
        scope: config.scope,
        recurringAttribute: config.recurringAttribute,
        recurringFieldValues: config.recurringFieldValues,
        oneTimeFieldValues: config.oneTimeFieldValues,
    };

    const currentConfigMatchesReferenceValuesConfig =
        config.referenceValues === null ? true : isEqual(currentConfig, config.referenceValues.config);

    return (
        <Form onSubmit={onSubmit}>
            <WidgetConfigTabs value={openTab} onChange={(_, newValue) => setOpenTab(newValue)}>
                <WidgetConfigTab label={translate(translationKeys.VDLANG_DASHBOARDS_PROJECT_PROGRESS_CONFIG_TAB_GENERAL)} />
                <WidgetConfigTab label={translate(translationKeys.VDLANG_DASHBOARDS_PROJECT_PROGRESS_CONFIG_TAB_DESCRIPTION)} />
                <WidgetConfigTab label={translate(translationKeys.VDLANG_DASHBOARDS_PROJECT_PROGRESS_CONFIG_TAB_SCOPE)} />
                <WidgetConfigTab label={translate(translationKeys.VDLANG_DASHBOARDS_PROJECT_PROGRESS_CONFIG_TAB_FILTER_CONFIGURATION)} />
                <WidgetConfigTab label={translate(translationKeys.VDLANG_DASHBOARDS_PROJECT_PROGRESS_CONFIG_TAB_TARGETS)} />
                <WidgetConfigTab
                    label={
                        <Stack direction="row" spacing={1} alignItems="center">
                            <span>{translate(translationKeys.VDLANG_DASHBOARDS_PROJECT_PROGRESS_CONFIG_TAB_REFERENCE_VALUES)}</span>
                            {!currentConfigMatchesReferenceValuesConfig ? <WarningIcon fontSize="inherit" /> : null}
                        </Stack>
                    }
                />
            </WidgetConfigTabs>
            {openTab === 0 ? (
                <ProjectProgressConfigTabGeneral
                    config={config}
                    name={name}
                    onNameChange={onNameChange}
                    onConfigChange={onConfigChange}
                    disabled={disabled}
                    isValidFilter={isValidFilter}
                    fields={fieldsQuery?.data ?? {}}
                    fieldOptions={fieldOptions}
                />
            ) : null}
            {openTab === 1 ? (
                <ProjectProgressConfigTabDescription
                    description={description}
                    onDescriptionChange={onDescriptionChange}
                    disabled={disabled}
                    label={translate(translationKeys.VDLANG_DASHBOARDS_PROJECT_PROGRESS_CONFIG_TAB_DESCRIPTION)}
                />
            ) : null}
            {openTab === 2 ? (
                <ProjectProgressConfigTabScope
                    config={config}
                    onConfigChange={onConfigChange}
                    isValidFilter={isValidFilter}
                    disabled={disabled}
                    skipFields={skipFields}
                />
            ) : null}
            {openTab === 3 && fieldDefinitionsQuery.isSuccess ? (
                <ProjectProgressConfigTabFilterConfiguration
                    config={config}
                    disabled={disabled}
                    updateConfig={updateConfig}
                    setIsValidFilter={setIsValidFilter}
                    fieldDefinitions={fieldDefinitionsQuery.data}
                />
            ) : null}
            {openTab === 4 ? (
                <ProjectProgressConfigTabTargets
                    targets={config.targets ?? {}}
                    onChange={(targets) => updateConfig({ ...config, targets })}
                    disabled={disabled}
                    translate={translate}
                />
            ) : null}
            {openTab === 5 ? (
                <ProjectProgressConfigTabReferenceValues
                    start={config.referenceValues?.config?.start ?? config.start}
                    end={config.referenceValues?.config?.end ?? config.end}
                    referenceValues={config.referenceValues}
                    disabled={disabled}
                    onSave={onReferenceValuesSave}
                    onDelete={onReferenceValuesDelete}
                />
            ) : null}
        </Form>
    );
};

export default ProjectProgressWidgetConfigForm;
