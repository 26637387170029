import { Grid, Stack, Switch, TextField, Typography } from "@mui/material";
import { GlobalCalculationIdentifier, RollingForecastDataRepresentation, RollingForecastWidgetConfig } from "api-shared";
import { useTranslation } from "react-i18next";
import InfoIcon from "../../../components/icons/InfoIcon";
import Select from "../../../components/input/select/Select";
import { Option } from "../../../components/input/select/types";
import { SimpleCurrencyInput } from "../../../components/input/SimpleCurrencyInput.tsx";
import { getOrderedCalculationIdentifiers, useGateTaskConfigs } from "../../../domain/measure-config";
import { translationKeys } from "../../../translations/main-translations";

const PresentationModeLabels = {
    [RollingForecastDataRepresentation.None]: translationKeys.VDLANG_DASHBOARDS_ROLLING_FORECAST_PRESENTATION_NONE,
    [RollingForecastDataRepresentation.Line]: translationKeys.VDLANG_DASHBOARDS_ROLLING_FORECAST_PRESENTATION_LINE,
    [RollingForecastDataRepresentation.Bar]: translationKeys.VDLANG_DASHBOARDS_ROLLING_FORECAST_PRESENTATION_BAR,
};

interface IRollingForecastGeneralConfigurationProps {
    name: string;
    onNameChange: (newName: string) => void;
    config: RollingForecastWidgetConfig;
    onConfigChange: (newConfig: RollingForecastWidgetConfig) => void;
    disabled: boolean;
}

const RollingForecastGeneralConfiguration = ({
    name,
    onNameChange,
    onConfigChange,
    config,
    disabled,
}: IRollingForecastGeneralConfigurationProps) => {
    const { t: translate } = useTranslation();

    const gateTaskConfigs = useGateTaskConfigs();
    const uniqueCalculationIdentifiers = getOrderedCalculationIdentifiers(gateTaskConfigs);

    const hasActual = uniqueCalculationIdentifiers.includes(GlobalCalculationIdentifier.Actual);

    const modeOptions = Object.values(RollingForecastDataRepresentation).map((mode) => ({
        value: mode,
        label: translate(PresentationModeLabels[mode]),
    }));

    const handleModeChange = (calculationIdentifier: string) => (option: Option<RollingForecastDataRepresentation> | null) => {
        if (option == null) {
            return;
        }

        onConfigChange({
            ...config,
            calculationDisplayModes: {
                ...config.calculationDisplayModes,
                [calculationIdentifier]: option.value,
            },
        });
    };

    // Combined FC+ACT only has Bar selectable as display mode
    const combinedACTOptions = modeOptions.filter((o) => o.value !== RollingForecastDataRepresentation.Line);
    const selectedCombinedACTOption = modeOptions.find(
        (o) => o.value === (config.showCombinedACT ? RollingForecastDataRepresentation.Bar : RollingForecastDataRepresentation.None),
    );

    return (
        <div>
            <TextField
                sx={{ pb: 2 }}
                value={name}
                onChange={(event) => onNameChange(event.target.value)}
                label={translate(translationKeys.VDLANG_DASHBOARDS_ROLLING_FORECAST_CONFIG_NAME)}
                disabled={disabled}
                fullWidth
            />
            <Grid container spacing={3}>
                <Grid item xs={6}>
                    <SimpleCurrencyInput
                        value={config.axisMinValue}
                        onChange={(axisMinValue) => onConfigChange({ ...config, axisMinValue })}
                        label={translate(translationKeys.VDLANG_DASHBOARDS_ROLLING_FORECAST_AXIS_MIN_VALUE)}
                        margin="none"
                        disabled={disabled}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={6}>
                    <SimpleCurrencyInput
                        value={config.axisMaxValue}
                        onChange={(axisMaxValue) => onConfigChange({ ...config, axisMaxValue })}
                        label={translate(translationKeys.VDLANG_DASHBOARDS_ROLLING_FORECAST_AXIS_MAX_VALUE)}
                        margin="none"
                        disabled={disabled}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={6}>
                    <Stack direction="column" spacing={2}>
                        {uniqueCalculationIdentifiers.map((calculationIdentifier) => (
                            <Select
                                key={calculationIdentifier}
                                options={modeOptions}
                                value={modeOptions.find(
                                    (o) =>
                                        o.value ===
                                        (config.calculationDisplayModes[calculationIdentifier] ?? RollingForecastDataRepresentation.None),
                                )}
                                label={translate(`${translationKeys.VDLANG_CALCULATION_IDENTIFIER}.${calculationIdentifier}`)}
                                menuPortalTarget={document.body}
                                onChange={handleModeChange(calculationIdentifier)}
                                margin="none"
                                isDisabled={disabled}
                            />
                        ))}
                    </Stack>
                </Grid>
                <Grid item xs={6}>
                    <Stack direction="column" spacing={2}>
                        <Select
                            options={modeOptions}
                            value={modeOptions.find((o) => o.value === config.targetPresentation)}
                            label={translate(translationKeys.VDLANG_DASHBOARDS_ROLLING_FORECAST_TARGET)}
                            menuPortalTarget={document.body}
                            onChange={(option) => option != null && onConfigChange({ ...config, targetPresentation: option.value })}
                            margin="none"
                            isDisabled={disabled}
                        />
                        {hasActual ? (
                            <Select
                                options={combinedACTOptions}
                                value={selectedCombinedACTOption}
                                label={
                                    <>
                                        {translate(translationKeys.VDLANG_DASHBOARDS_ROLLING_FORECAST_FC_ACT_ROLLING_FORECAST)}
                                        <InfoIcon
                                            title={translate(
                                                translationKeys.VDLANG_DASHBOARDS_ROLLING_FORECAST_FC_ACT_ROLLING_FORECAST_HINT,
                                            )}
                                            sx={{ ml: 0.5 }}
                                        />
                                    </>
                                }
                                menuPortalTarget={document.body}
                                onChange={(option) =>
                                    option != null &&
                                    onConfigChange({ ...config, showCombinedACT: option.value === RollingForecastDataRepresentation.Bar })
                                }
                                margin="none"
                                isDisabled={disabled}
                            />
                        ) : null}
                    </Stack>
                </Grid>
                <Grid direction="row" flexWrap="nowrap" display="flex" alignItems="center" item xs={12}>
                    <Switch
                        size="small"
                        edge="start"
                        checked={config.showSums}
                        onChange={(e, checked) => onConfigChange({ ...config, showSums: checked })}
                        disabled={disabled}
                    />
                    <Typography>
                        {translate(translationKeys.VDLANG_DASHBOARDS_ROLLING_FORECAST_FC_ACT_ROLLING_FORECAST_SHOW_SUMS)}
                    </Typography>
                </Grid>
            </Grid>
        </div>
    );
};

export default RollingForecastGeneralConfiguration;
