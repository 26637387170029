import { Alert } from "@mui/material";
import { groupBy } from "lodash";
import { useTranslation } from "react-i18next";
import { useLanguage } from "../../../hooks/useLanguage";
import { translationKeys } from "../../../translations/main-translations";
import { type FieldOption } from "../reporting/useFieldOptions";

export type FormulaEvaluationError = {
    id: number;
    week: number;
};

type TimelineWidgetFormulaErrorHintProps = Readonly<{
    options: FieldOption[];
    errors: FormulaEvaluationError[];
    invalidFormulas: Set<string>;
}>;

const MAX_DISPLAYED_ERROR_WEEKS = 4;

function TimelineWidgetFormulaErrorHint({ errors, invalidFormulas, options }: TimelineWidgetFormulaErrorHintProps) {
    const { t: translate } = useTranslation();
    const language = useLanguage();

    if (errors.length === 0) {
        return null;
    }

    function resolveGroup(id: string) {
        return options.find((group) => group.value === id)?.label ?? id;
    }

    const listFormatter = new Intl.ListFormat(language);

    const errorsByLevel = groupBy(errors, "id");

    const formulaErrors = [...invalidFormulas].map(resolveGroup);

    const weekErrors = Object.entries(errorsByLevel).map(([id, errors]) => {
        const level = resolveGroup(id);
        if (errors.length <= MAX_DISPLAYED_ERROR_WEEKS) {
            // show week numbers themselves only a few errors
            const errorList = listFormatter.format(errors.map(({ week }) => String(week)));
            return translate(translationKeys.VDLANG_DASHBOARDS_TIMELINE_WIDGET_FLIGHT_PATH_WEEK_ERROR, {
                count: errors.length,
                weeks: errorList,
                level: level,
            });
        } else {
            // just show the number of bad weeks
            return translate(translationKeys.VDLANG_DASHBOARDS_TIMELINE_WIDGET_FLIGHT_PATH_N_WEEKS_ERROR, { level, count: errors.length });
        }
    });

    const formattedErrors = listFormatter.format([...formulaErrors, ...weekErrors]);

    return (
        <Alert severity="warning">
            {translate(translationKeys.VDLANG_DASHBOARDS_TIMELINE_WIDGET_FLIGHT_PATH_WEEK_ERROR_LIST, { errors: formattedErrors })}
        </Alert>
    );
}

export default TimelineWidgetFormulaErrorHint;
