import { UseQueryResult } from "@tanstack/react-query";
import { WidgetType } from "api-shared";
import { PropsWithChildren } from "react";
import WidgetError from "../WidgetError";
import WidgetInvalidConfig from "../WidgetInvalidConfig";
import WidgetIncompleteConfig from "./WidgetIncompleteConfig";

function defaultIsEmpty(dataQuery: UseQueryResult) {
    return Array.isArray(dataQuery.data) && dataQuery.data.length === 0;
}

type WidgetStateContainerProps = {
    hasIncompleteConfig?: boolean;
    hasInvalidConfig: boolean;
    additionalQueries?: UseQueryResult[];
    dataQuery: UseQueryResult;
    renderEmpty: () => React.JSX.Element;
    renderLoading: () => React.JSX.Element;
    renderError?: () => React.JSX.Element;
    renderIncomplete?: () => React.JSX.Element;
    renderInvalid?: () => React.JSX.Element;
    // Not used yet, but may be passed to generic noData/error/loading/invalidConfig components to allow custom messages and illustrations
    widgetType: WidgetType;
    openConfigDialog?: () => void;
    isEmpty?: (dataQuery: UseQueryResult) => boolean;
};

function WidgetStateContainer({
    hasIncompleteConfig,
    hasInvalidConfig,
    additionalQueries = [],
    dataQuery,
    children,
    renderEmpty,
    renderLoading,
    renderIncomplete,
    renderInvalid,
    renderError,
    openConfigDialog,
    isEmpty = defaultIsEmpty,
}: PropsWithChildren<WidgetStateContainerProps>) {
    const queries = [dataQuery, ...additionalQueries];
    switch (true) {
        case hasIncompleteConfig === true:
            return renderIncomplete?.() ?? <WidgetIncompleteConfig openConfigDialog={openConfigDialog} />;
        case hasInvalidConfig:
            return renderInvalid?.() ?? <WidgetInvalidConfig />;
        case queries.some(({ isError }) => isError):
            return renderError?.() ?? <WidgetError />;
        case queries.some(({ isSuccess }) => !isSuccess):
            return renderLoading();
        case isEmpty(dataQuery):
            return renderEmpty();
        default:
            return children;
    }
}

export default WidgetStateContainer;
