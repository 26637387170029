import { TextField } from "@mui/material";
import { ChangeEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { translationKeys } from "../../translations/main-translations";

interface IPercentageInputProps {
    value?: number;
    onChange: (newValue: number) => void;
    disabled?: boolean;
}

// Integer between 0-100
const numberValidationRegEx = /^$|^([1-9]?\d|100)$/;

const PercentageInput = ({ value, disabled, onChange }: IPercentageInputProps) => {
    const [input, setInput] = useState<string>(`${value !== undefined ? Math.round(value * 100) : 100} %`);
    const [isLastChangeInvalid, setIsLastChangeInvalid] = useState(false);
    const { t: translate } = useTranslation();

    const handleFocus = () => {
        const parsedInput = parseInt(input);
        setInput(parsedInput.toString());
    };

    const handleBlur = () => {
        if (input === "") {
            // If field is empty on blur restore old value
            setInput(`${value !== undefined ? Math.round(value * 100) : 100} %`);
            return;
        }
        setInput(`${input} %`);
        onChange(parseInt(input) / 100);
    };

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const change = event.target.value;
        if (!numberValidationRegEx.test(change)) {
            setIsLastChangeInvalid(true);
            return;
        }
        setIsLastChangeInvalid(false);
        setInput(change);
    };

    return (
        <TextField
            disabled={disabled}
            value={input}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onChange={handleChange}
            error={isLastChangeInvalid}
            helperText={isLastChangeInvalid && translate(translationKeys.VDLANG_INTEGER_PERCENTAGE_INPUT_ONLY)}
        />
    );
};

export default PercentageInput;
