import { ButtonProps, Popover } from "@mui/material";
import { MaxYear, MeasureCalculationGranularity, MinYear } from "api-shared";
import { TFunction } from "i18next";
import moment, { Moment } from "moment";
import React from "react";
import useMenu from "../../../hooks/useMenu";
import { calendarToFiscal, formatFiscalUnit } from "../../../lib/fiscal-units";
import { translationKeys } from "../../../translations/main-translations";
import SelectButton from "../select/SelectButton";
import CalculationTimerangeDialog from "./CalculationTimerangeDialog";

export interface CalculationTimeSelectProps extends Omit<ButtonProps, "translate" | "value" | "onChange"> {
    granularity: MeasureCalculationGranularity;
    fiscalYearStart: number;
    value: Moment | null;
    onChange?: (newValue: Moment) => void;
    translate: TFunction;
    max?: Moment;
    min?: Moment;
    dense?: boolean;
}

const MAX_MOMENT = moment(MaxYear - 1, "YYYY");
const MIN_MOMENT = moment(MinYear, "YYYY");

const CalculationTimeSelect = React.forwardRef<HTMLButtonElement, CalculationTimeSelectProps>(
    ({ granularity, fiscalYearStart, value, onChange, translate, min, max, ...buttonProps }, ref) => {
        const menu = useMenu();

        // Use the same labels as placeholder as shown in the granularity selector
        const placeholder = translate(`${translationKeys.VDLANG_MEASURE_CALCULATION_GRANULARITY_PLACEHOLDER}.${granularity}`);
        const label =
            value != null ? formatFiscalUnit(calendarToFiscal(value, fiscalYearStart, granularity), granularity, translate) : placeholder;

        const handleDateChange = (newValue: Moment) => {
            onChange?.(newValue);
            menu.close();
        };

        return (
            <>
                <Popover
                    {...menu.menuProps}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "center",
                    }}
                >
                    <CalculationTimerangeDialog
                        defaultValue={value}
                        granularity={granularity}
                        updateValue={handleDateChange}
                        min={min ?? MIN_MOMENT}
                        max={max ?? MAX_MOMENT}
                        fiscalYearStart={fiscalYearStart}
                        translate={translate}
                    />
                </Popover>
                <SelectButton ref={ref} label={label} onClick={menu.open} {...buttonProps} />
            </>
        );
    },
);

export default CalculationTimeSelect;
