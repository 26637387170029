import { Stack, styled } from "@mui/material";
import { IdeaMatrixWidgetConfig, IdeaXAxis, validateIdeaMatrixWidgetConfig } from "api-shared";
import Alert from "../../components/Alert";
import { useIdeaSearchQuery } from "../../domain/ideas";
import { ApiError } from "../../lib/api";
import { getTranslatedErrorMessage } from "../../lib/error";
import { translationKeys } from "../../translations/main-translations";
import { useIdeaMatrixFilter } from "../ideas/hooks/useIdeaMatrixFilter";
import { IdeaMatrix } from "../ideas/matrix/IdeaMatrix";
import IdeaMatrixWidgetConfigForm from "./IdeaMatrixWidgetConfigForm";
import { IWidgetContentProps } from "./Widget";
import WidgetConfigDialog from "./WidgetConfigDialog";
import WidgetError from "./WidgetError";

const FullSizeIdeaMatrix = styled(IdeaMatrix)(({ theme }) => ({
    flexGrow: 1,
    margin: theme.spacing(3),
    marginTop: 0,
    minHeight: 0,
    overflow: "hidden",
}));

const IdeaMatrixWidget = ({
    widget,
    isConfigDialogOpen,
    onConfigDialogClose,
    onConfigSave,
    translate,
    disabled = false,
    readOnlyLabel,
    isInView,
}: IWidgetContentProps) => {
    const ideasQuery = useIdeaSearchQuery((widget.config as IdeaMatrixWidgetConfig).filter, { ignoreErrors: true, enabled: isInView });
    const xAxis = (widget.config as IdeaMatrixWidgetConfig).xAxis;

    const { visibleIdeas, numberOfHiddenIdeas } = useIdeaMatrixFilter(ideasQuery.data ?? [], xAxis);

    if (!validateIdeaMatrixWidgetConfig(widget.config)) {
        return null;
    }

    return (
        <>
            <WidgetConfigDialog
                open={isConfigDialogOpen}
                onClose={onConfigDialogClose}
                onSave={onConfigSave}
                translate={translate}
                widget={widget}
                validateConfig={validateIdeaMatrixWidgetConfig}
                FormComponent={IdeaMatrixWidgetConfigForm}
                noPadding
                disabled={disabled}
                readOnlyLabel={readOnlyLabel}
            />

            {ideasQuery.isError && ideasQuery.error instanceof ApiError ? (
                <WidgetError>{getTranslatedErrorMessage(ideasQuery.error, translate)}</WidgetError>
            ) : (
                <Stack height="100%">
                    {numberOfHiddenIdeas > 0 ? (
                        <Alert severity="info" sx={{ mx: 3, mb: 2 }}>
                            {translate(translationKeys.VDLANG_IDEAS_MATRIX_HIDDEN_IDEAS_HINT, {
                                count: numberOfHiddenIdeas,
                                xAxisLabel: translate(
                                    xAxis === IdeaXAxis.TIME
                                        ? translationKeys.VDLANG_IDEAS_MATRIX_TIME_ESTIMATE
                                        : translationKeys.VDLANG_IDEAS_MATRIX_EFFORT_ESTIMATE,
                                ),
                                yAxisLabel: translate(translationKeys.VDLANG_IDEAS_MATRIX_POTENTIAL_ESTIMATE),
                            })}
                        </Alert>
                    ) : null}
                    <FullSizeIdeaMatrix ideas={visibleIdeas} isFetching={ideasQuery.isFetching} xAxis={widget.config.xAxis} />
                </Stack>
            )}
        </>
    );
};

export default IdeaMatrixWidget;
