import { Button, Paper, styled, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { CreateWidgetRequestBodyDto, WidgetTemplateDto } from "api-shared";
import { useTranslation } from "react-i18next";
import WidgetTemplateDepiction from "./WidgetTemplateDepiction";

const widgetTemplateClasses = {
    depiction: "VdWidgetTemplate-depiction",
    description: "VdWidgetTemplate-description",
};

const Root = styled(Paper)(({ theme }) => ({
    position: "relative", // reference to absolutely positioned FooterOverlay
    overflow: "hidden", // round corners should cover child elements
    "&:hover": {
        [`& .${widgetTemplateClasses.depiction}`]: {
            filter: "blur(8px)",
        },
        [`& .${widgetTemplateClasses.description}`]: {
            maxHeight: 400,
            opacity: 1,
            overflow: "visible",
        },
    },
}));

const DepictionContainer = styled("div")(({ theme }) => ({
    aspectRatio: "4 / 3",
    backgroundColor: grey[50],
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    filter: "blur(0px)",
    transition: theme.transitions.create("filter"),
}));

const FooterOverlay = styled("footer")(({ theme }) => ({
    borderTop: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(2),
    position: "absolute", // avoid shifting downwards when description becomes visible on hover
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: theme.palette.background.paper,
}));

const DescriptionContainer = styled("div")(({ theme }) => ({
    maxHeight: 0,
    opacity: 0,
    overflow: "hidden",
    transition: theme.transitions.create(["max-height", "opacity"]),
}));

const AddWidgetButton = styled(Button)(({ theme }) => ({
    marginTop: theme.spacing(1),
}));

// This component is used to reserve the same space as the actual footer (without description) which is absolutely positioned and does not
// occupy any space
const ReservedFooterSpace = styled(FooterOverlay)(({ theme }) => ({
    position: "static",
}));

export interface WidgetTemplateProps {
    widgetTemplate: WidgetTemplateDto;
    addWidget: (data: CreateWidgetRequestBodyDto) => void;
}

const WidgetTemplate = ({ widgetTemplate, addWidget }: WidgetTemplateProps) => {
    const { t: translate } = useTranslation();

    // try to translate the name and gracefully fall back to the name if not translateable
    const title = translate(widgetTemplate.name, { defaultValue: null }) ?? widgetTemplate.name;
    const description = translate(widgetTemplate.description, { defaultValue: null }) ?? widgetTemplate.description;

    return (
        <Root>
            <DepictionContainer className={widgetTemplateClasses.depiction}>
                <WidgetTemplateDepiction type={widgetTemplate.type} />
            </DepictionContainer>
            <ReservedFooterSpace>
                <Typography aria-hidden="true" variant="subtitle1">
                    {title}
                </Typography>
            </ReservedFooterSpace>
            <FooterOverlay>
                <Typography variant="subtitle1">{title}</Typography>
                <DescriptionContainer className={widgetTemplateClasses.description}>
                    <Typography component="div" variant="body2" color="textSecondary">
                        {description}
                    </Typography>
                    <AddWidgetButton
                        variant="contained"
                        onClick={() =>
                            addWidget({
                                name: widgetTemplate.name,
                                description: widgetTemplate.description,
                                type: widgetTemplate.type,
                                config: widgetTemplate.config,
                            })
                        }
                    >
                        {translate("Add")}
                    </AddWidgetButton>
                </DescriptionContainer>
            </FooterOverlay>
        </Root>
    );
};

export default WidgetTemplate;
