import { Skeleton, Stack } from "@mui/material";
import { times } from "lodash";

const cellDimensions = ["100%", "80%", "55%", "40%", "35%"];

const FunnelChartSkeleton = () => {
    return (
        <>
            <Stack height="90%" direction="row" flexWrap="nowrap" marginBottom={2}>
                <Stack width="100%" direction="column" display="flex" alignItems="center" margin={1}>
                    {cellDimensions.map((dimension) => (
                        <Skeleton key={dimension} height={dimension} width={dimension} variant="rectangular" />
                    ))}
                </Stack>
            </Stack>

            <Stack direction="row" gap={2}>
                {times(3, (i) => (
                    <Skeleton key={`skeleton_legend_${i}`} width="20%" />
                ))}
            </Stack>
        </>
    );
};
export default FunnelChartSkeleton;
